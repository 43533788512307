import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progres-bar',
  templateUrl: './progres-bar.component.html',
  styleUrls: ['./progres-bar.component.css']
})
export class ProgresBarComponent implements OnInit {

  @Input() title: string = 'Progress Bar';
  @Input() _progressPercentage: number = 0;
  @Input() progressColor: 'bg-success' | 'bg-danger' = 'bg-success';


  @Input() set progressPercentageValue(val: number) {
    this._progressPercentage = val;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
