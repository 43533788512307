/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CloneServiceSearchFilter { 
    offset?: number;
    limit?: number;
    order?: CloneServiceSearchFilter.OrderEnum;
    orderColumn?: string;
    startDate?: string;
    endDate?: string;
    serviceType?: CloneServiceSearchFilter.ServiceTypeEnum;
    identifierType?: CloneServiceSearchFilter.IdentifierTypeEnum;
    transactionPhaseType?: CloneServiceSearchFilter.TransactionPhaseTypeEnum;
    reasonForRequest?: string;
}
export namespace CloneServiceSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
    export type ServiceTypeEnum = 'READ_ATTRIBUTE' | 'VERIFY_ID';
    export const ServiceTypeEnum = {
        ReadAttribute: 'READ_ATTRIBUTE' as ServiceTypeEnum,
        VerifyId: 'VERIFY_ID' as ServiceTypeEnum
    };
    export type IdentifierTypeEnum = 'UIN' | 'TOKEN';
    export const IdentifierTypeEnum = {
        Uin: 'UIN' as IdentifierTypeEnum,
        Token: 'TOKEN' as IdentifierTypeEnum
    };
    export type TransactionPhaseTypeEnum = 'QUEUED' | 'PROCESSED' | 'ERROR';
    export const TransactionPhaseTypeEnum = {
        Queued: 'QUEUED' as TransactionPhaseTypeEnum,
        Processed: 'PROCESSED' as TransactionPhaseTypeEnum,
        Error: 'ERROR' as TransactionPhaseTypeEnum
    };
}


