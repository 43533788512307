import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { LottieModule } from 'ngx-lottie';

import {UnderscoreToSpacePipe} from './pipes/underscore-to-space.pipe';
import {TimeOnlyPipe} from './pipes/time-only.pipe';
import {DateOnlyPipe} from './pipes/date-only.pipe';
import {DatetimePipe} from './pipes/datetime.pipe';
import {RemoveUnderscoresPipe} from './pipes/remove-underscores.pipe';
import {NumberStringPipe} from './pipes/number-string.pipe';
import {TruncatePipe} from './pipes/truncate.pipe';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {FullPageLoaderComponent} from './components/loaders/full-page-loader/full-page-loader.component';
import {AmountPipe} from "./pipes/amount.pipe";
import {FullDateTimePipe} from './pipes/full-date-time.pipe';
import {LoaderComponent} from './components/loaders/loader/loader.component';
import {ImageCropperModule} from "ngx-image-cropper";
import { SkeletonLoaderComponent } from './components/loaders/skeleton-loader/skeleton-loader.component';
import { SuccessDialogComponent } from './components/dialogs/success-dialog/success-dialog.component';
import { ReasonForDisapprovalDialogComponent } from './components/dialogs/reason-for-disapproval-dialog/reason-for-disapproval-dialog.component';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import {ShortestDatePipe} from "./pipes/shortest-date.pipe";
import {DashToSpacePipe} from "./pipes/dash-to-space.pipe";
import {PageSizeComponent} from "./page-size/page-size.component";
import { ProgresBarComponent } from './components/visual/progres-bar/progres-bar.component';
import {TotalRecordsComponent} from "./components/total-records/total-records.component";
import {FingerprintAuthenticationModule} from "./fingerprint-authentication/fingerprint-authentication.module";
import {EmComponent} from "./components/em/em.component";

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

@NgModule({
    declarations: [
        UnderscoreToSpacePipe,
        DashToSpacePipe,
        TimeOnlyPipe,
        DateOnlyPipe,
        AmountPipe,
        DatetimePipe,
        ShortestDatePipe,
        RemoveUnderscoresPipe,
        NumberStringPipe,
        LoaderComponent,
        FullPageLoaderComponent,
        TruncatePipe,
        FullDateTimePipe,
        SkeletonLoaderComponent,
        SuccessDialogComponent,
        ReasonForDisapprovalDialogComponent,
        ConfirmationDialogComponent,
        ToggleSwitchComponent,
        PageSizeComponent,
        ProgresBarComponent,
        TotalRecordsComponent,
      EmComponent
    ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ImageCropperModule,
    LottieModule.forRoot({player: playerFactory}),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        ProgressbarModule,
        TooltipModule,
        PaginationModule,
        BsDatepickerModule,
        UnderscoreToSpacePipe,
        DashToSpacePipe,
        TimeOnlyPipe,
        ShortestDatePipe,
        DateOnlyPipe,
        DatetimePipe,
        RemoveUnderscoresPipe,
        NumberStringPipe,
        TruncatePipe,
        BsDropdownModule,
        AmountPipe,
        FullDateTimePipe,
        LoaderComponent,
        FullPageLoaderComponent,
        SkeletonLoaderComponent,
        ToggleSwitchComponent,
        PageSizeComponent,
        ProgresBarComponent,
        TotalRecordsComponent,
      EmComponent
    ],schemas:[ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule {
}
