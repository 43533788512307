import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FingerprintPluginHandler} from "../../handlers/fingerprint-plugin-handler";
import {FingerprintScanConfigService} from "../../services/fingerprint-scan-config.service";
import {FingerprintScanService} from "../../services/fingerprint-scan.service";
import {
  AlertType,
  AUTH_STATUS,
  FINGERPRINT_AUTH_CONFIG,
  FINGERPRINT_PLUGIN_HANDLER,
  FingerTypeEnum
} from "../../variables";
import {timer} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'lib-scan-single-fingerprint',
  templateUrl: './scan-single-fingerprint.component.html',
  styleUrls: ['./scan-single-fingerprint.component.css']
})
export class ScanSingleFingerprintComponent implements OnInit {

  @Output()
  fingerprintEvent = new EventEmitter<any>()//new EventEmitter<FingerData>();

  @Output()
  fingerprint = new EventEmitter<any>()//<FingerprintDto[]>();

  @Output()
  shuffle = new EventEmitter<any>();

  authStatus: AUTH_STATUS;

  constructor(@Inject(FINGERPRINT_PLUGIN_HANDLER) private pluginHandler: FingerprintPluginHandler,
              public fingerprintScanService: FingerprintScanService,
              private fingerprintScanConfigService: FingerprintScanConfigService,
              @Inject(FINGERPRINT_AUTH_CONFIG) protected config: FINGERPRINT_AUTH_CONFIG) {

  }

  fingerprints: any[]/*: FingerprintDto[]*/ = [];

  captureStarted = false;

  scanResult: any;//FingerData;

  deviceId: string;

  completedScan: boolean = false;

  maxQueryAttempts: number = 100;

  queryAttempts: number = 0;

  queryIntervalInMs: number = 1000;

  nfiQuality: number = 1;

  hasError: boolean = false;

  verifying: any = false;

  fingerToScan: number | undefined;

  showVerificationFailure = false;

  showScanButton = true;

  capturedFinger: Array<number> = []

  fpConnected = false;

  pluginDetected = false;

  ngOnInit(): void {
    this.initListeners();
  }

  initListeners(): void {
    this.fingerprintScanService.fingerprintToScanObservable
      .subscribe(finger => {

        if (finger) {
          this.setFingerToScan(finger);
        }
      })

    this.fingerprintScanService.actionAsObservable
      .subscribe(finger => {

        if (finger) {
          this.showScanButton = finger == 'SCAN';
        }
      })

    this.fingerprintScanService.clearCounter$.asObservable()
      .subscribe(action => {
        this.fingerprints = [];
        this.capturedFinger = []
      })

    this.fingerprintScanService.startScanAsObservable
      .subscribe(() => {

        if (!this.scanButtonDisabled) {
          this.startScan();
        }
      })

    this.fingerprintScanService.pluginDetectionStatusAsObservable
      .pipe(distinctUntilChanged())
      .subscribe(status => {

        this.pluginDetected = status && status == true;
      })

    timer(0, 1000).subscribe(x => {
      this.updateStatus();
      this.getInstruction();
    });

    this.fingerprintScanService.fpConnectionStatusAsObservable
      .pipe(distinctUntilChanged())
      .subscribe(status => {

        this.fpConnected = status && status == true;
      })
  }

  setFingerToScan(finger: number): void {
    this.clearAndPrepareForNextCapture();
    this.fingerToScan = finger;
  }

  startScan(): void {

    this.startScanning();
    const finger: FingerTypeEnum = Object.values(FingerTypeEnum)[Number(this.fingerToScan) - 1]
    this.pluginHandler?.startScan({finger: finger})
      .subscribe({
        next: (acqRes: any) => {
          if (acqRes?.nextAction == 'PLACE_FINGER') {
            this.getScanResult(acqRes?.sessionId);
          }
        },

        complete: () => {
          // this.stopScanning();
        },
        error: (event) => {
          this.stopScanning();
        },
      })
  }

  getScanResult(sessionId: string | undefined): void {
    this.incrementGetScanResultAttempts();

    this.pluginHandler?.getScanResult({sessionId: sessionId})
      .subscribe({
        next: (scanResult: any) => {

          if (scanResult.captureSuccessful == false) {
            this.sendAcknowledgementFailure(sessionId);
            return;
          }

          if (!scanResult.sessionExpired && scanResult.fingers?.length == 1) {
            this.scanResult = scanResult.fingers[0];
            this.deviceId = scanResult.deviceId;
            this.sendAcknowledgementSuccess(sessionId);

          } else {

            if (this.queryAttempts < this.maxQueryAttempts) {
              setTimeout(() => this.getScanResult(sessionId), this.queryIntervalInMs);

            } else {
              //display time out error
              this.sendAcknowledgementFailure(sessionId);
            }
          }
        },
        complete: () => {
        },
        error: (event) => {
        }
      })
  }

  incrementGetScanResultAttempts(): void {
    this.queryAttempts++;
  }

  scanResultIsUpToQuality(): boolean {
    return this.scanResult?.quality >= this.nfiQuality;
  }

  sendAcknowledgementSuccess(sessionId: string | undefined): void {
    this.pluginHandler?.sendAcknowledgementSuccess({sessionId: sessionId})
      .subscribe({
        next: (acqRes: any) => {

          const isQuality = this.scanResultIsUpToQuality();

          if (!isQuality) {
            this.completeScan();
            this.rejectScanResult();
            return;
          }
          this.completeScan();
          this.acceptScanResult();
        },
        error: (event) => {
          //handle error;
        },
      })
  }

  sendAcknowledgementFailure(sessionId: string | undefined): void {
    this.pluginHandler?.sendAcknowledgementSuccess({sessionId: sessionId})
      .subscribe({
        next: (acqRes: any) => {
          this.completeScan();
          this.rejectScanResult();
        },
        error: (event) => {
          //handle error
        },
      })
  }

  acceptScanResult() {

    if (this.hasError) {
      return;
    }

    let data: any = {
      deviceId: this.deviceId,
      encryptedData: this.scanResult?.encryptedData,
      fingerType: this.scanResult?.fingerType,
      quality: this.scanResult?.quality,
      status: 'OKAY'
    }

    this.capturedFinger = this.capturedFinger.concat(this.fingerToScan);

    this.fingerprints.push(data);

    // this.fingerprint.emit(data);
    this.fingerprintEvent.emit(data);
    this.clearAndPrepareForNextCapture();
  }

  clearAndPrepareForNextCapture(): void {
    this.stopScanning();
    this.completeScan();
  }

  rejectScanResult() {
    this.stopScanning();
    // this.showErrorMessage("An error has occurred. Please re start capture");
  }

  startScanning(): void {
    this.captureStarted = true;
  }

  stopScanning() {
    this.hasError = false;
    this.captureStarted = false;
  }

  completeScan(): void {
    this.completedScan = true;
    this.captureStarted = false;
    this.fingerToScan = undefined;

    setTimeout(() => {
      this.scanResult = undefined;
    }, 1000)
  }

  get scanButtonDisabled(): boolean {
    return this.captureStarted || !this.fingerToScan || !this.pluginDetected || !this.fpConnected;
  }

  getFingerTypeFromIndex(fingerToScan: number): FingerTypeEnum {
    return this.fingerprintScanService.getFingerTypeFromIndex(fingerToScan);
  }

  get toScan(): number {
    return this.fingerToScan;
  }

  get capturedFingers(): Array<number> {
    return this.capturedFinger;
  }

  get percent(): number {

    if (this.updateStatus() == 'NO_PLUGIN_DETECTED') {
      return 100;
    }

    if (this.updateStatus() == 'NO_SCANNER_DETECTED') {
      return 100;
    }

    if (this.fingerprints?.length == 0) {
      return 0;

    } else {
      return 100;
    }
  }

  get progressColor(): string {

    if (this.updateStatus() == 'NO_PLUGIN_DETECTED') {
      return 'rgba(0, 0, 0, 0.03)';
    }

    if (this.updateStatus() == 'NO_SCANNER_DETECTED') {
      return 'rgba(255, 68, 45, 0.10)';
    }

    return '#01873A'

  }

  updateStatus(): AUTH_STATUS {

    if (!this.pluginDetected) {
      this.authStatus = AUTH_STATUS.NO_PLUGIN_DETECTED
      return this.authStatus;
    }

    if (!this.fpConnected) {
      this.authStatus = AUTH_STATUS.NO_SCANNER_DETECTED
      return this.authStatus;
    }

    if (!this.captureStarted
      && !this.scanResult
      && !this.showVerificationFailure
      && !this.verifying
      && this.fingerToScan) {
      this.authStatus = 'READY_TO_SCAN';
      return this.authStatus;
    }

    if (this.showVerificationFailure) {
      this.authStatus = 'VERIFICATION_FAILURE';
      return this.authStatus;
    }

    if (this.verifying) {
      this.authStatus = 'VERIFYING';
      return this.authStatus;
    }

    if (this.captureStarted
      && !this.scanResult
      && !this.showVerificationFailure
      && !this.verifying) {
      this.authStatus = 'SCANNING';
      return this.authStatus;
    }

    if (!this.captureStarted
      && this.scanResult
      && !this.verifying) {
      this.authStatus = 'SCANNING_SUCCESS';
      return this.authStatus;
    }

    return this.authStatus
  }

  getInstruction(): string {
    const status = this.updateStatus()
    switch (status) {
      case 'NO_PLUGIN_DETECTED':
        this.showAlertMessage('No Plugin Detected. Start the plugin and try again', AlertType.error);
        return this.getAlertMessage();
      case "NO_SCANNER_DETECTED":
        this.showAlertMessage('Fingerprint sensor not detected. Please check the sensor and try again', AlertType.error);
        return this.getAlertMessage();
      case "SCANNING":
        this.showAlertMessage(`Place your ${(this.getFingerTypeFromIndex(this.fingerToScan) || '-')?.replace(/_/g, ' ')?.toLowerCase()}  on the scanner`, AlertType.info);
        return this.getAlertMessage();
      case "READY_TO_LOGIN":
        this.showAlertMessage('Fingerprint capture completed. Click verify to proceed', AlertType.info);
        return this.getAlertMessage();
      case "READY_TO_SCAN":
        this.showAlertMessage(`Place your ${(this.getFingerTypeFromIndex(this.fingerToScan) || '-')?.replace(/_/g, ' ')?.toLowerCase()}  on the scanner`, AlertType.info);
        return this.getAlertMessage();
      case "SCANNING_FAILURE":
        this.showAlertMessage('Capture failed. Try again', AlertType.error);
        return this.getAlertMessage();
      case "SCANNING_SUCCESS":
        this.showAlertMessage('Capture Successful', AlertType.success);
        return this.getAlertMessage();
      case "VERIFYING":
        this.showAlertMessage('Verifying, please wait...', AlertType.info);
        return this.getAlertMessage();
      case "VERIFICATION_FAILURE":
        this.showAlertMessage('Verification returned failed. Try again', AlertType.error);
        return this.getAlertMessage();
      default:
        this.showAlertMessage('Okay', AlertType.primary);
        return this.getAlertMessage();
    }
  }

  private alertMessage: { msg: string; type: AlertType } = {
    msg: '',
    type: AlertType.info
  }

  getAlertMessage(): string {
    return this.alertMessage.msg;
  }

  getMessageType(): AlertType {
    return this.alertMessage.type;
  }

  showAlertMessage(msg: any, type: AlertType): void {
    this.alertMessage.msg = msg;
    this.alertMessage.type = type;
  }

  protected readonly AlertType = AlertType;

  shuffleFinger(): void {

    if (this.updateStatus() == AUTH_STATUS.READY_TO_SCAN) {
      this.shuffle.emit();
    }
  }
}
