<div class="container pt-4 pt-md-3 pb-4 mb-2">
  <div class="welcome-wrapper flex-wrap flex-md-nowrap">
    <!-- <div *ngIf="routeData?.showWelcome">
      <h4 class="fw-bold fs-26 mt-4">Welcome to the VRPS setup wizard!</h4>
      <p class="fs-15 mb-4">
        Kindly carefully configure the form below and click on the Next
        button.
      </p>
    </div> -->
    <!-- <div class="d-flex card-bg p-3 mb-3 col-12 col-md-4 col-lg-3 col-xl-2" style="min-width: 230px;">
      <div class="me-2">
        <span class="iconify fs-22 text-header" data-icon="ant-design:user-outlined"></span>
      </div>
      <div>
        <p class="fs-14 fw-bold text-header mb-0 text-truncate">{{user?.firstName}} {{user?.lastName}}</p>
        <p class="fs-14 fw-light mb-0">{{currentAccount?.roles[0]}}</p>
      </div>
    </div> -->
  </div>

  <div class="dash-menu" *ngIf="!(routeData?.showWelcome)">
    <div class="dash-container mw-100">
      <div class="menu-toggle-btn-wrapper">
        <button class="menu-toggle-btn" data-hvas-target="#dash-navlist" data-hvas-toggle-class="show">
          <span class="iconify icon" data-icon="heroicons-solid:menu"></span>
          Menu
        </button>
      </div>

      <ul id="dash-navlist" class="dash-menu-navlist justify-content-center mb-3">
        <li><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/dashboard']" data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Dashboard</a></li>
        <!-- <li><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/analytics']" data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Analytics</a></li> -->
        <li><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/activations']" data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Activations</a></li>
        <li *ngIf="canViewUser()"><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/access-manager']" data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Users</a></li>
        <li *ngIf="canManageClone()" ><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/clone-management']" data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Clone Management</a></li>
        <li *ngIf="canViewService()"><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/services']" data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Services</a></li>
        <li *ngIf="isAdmin()"><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/audit-trail']" data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Audit Trail</a></li>
        <li *ngIf="isAdmin()"><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/clone-settings']" data-hvas-target="#dash-navlist" data-hvas-remove-class="show">Settings</a></li>
        <!-- <li><a class="nav-item" [routerLinkActive]="'active'" [routerLink]="['/developer-guide']" data-hvas-target="#dash-navlist" data-hvas-remove-class="show">API Guide</a></li> -->
      </ul>
    </div>
  </div>
</div>
