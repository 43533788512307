/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MembershipPojo { 
    accountName?: string;
    accountCode?: string;
    accountType?: MembershipPojo.AccountTypeEnum;
    roles?: Array<string>;
}
export namespace MembershipPojo {
    export type AccountTypeEnum = 'HNODE' | 'SYSTEM_SUPPORT' | 'AUTHENTICATION_SERVICE_AGENT';
    export const AccountTypeEnum = {
        Hnode: 'HNODE' as AccountTypeEnum,
        SystemSupport: 'SYSTEM_SUPPORT' as AccountTypeEnum,
        AuthenticationServiceAgent: 'AUTHENTICATION_SERVICE_AGENT' as AccountTypeEnum
    };
}


