import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-reason-for-disapproval-dialog',
  templateUrl: './reason-for-disapproval-dialog.component.html',
  styleUrls: ['./reason-for-disapproval-dialog.component.css']
})
export class ReasonForDisapprovalDialogComponent implements OnInit {

  @Input() infoMessage:string = 'Provide a reason for this action';
  @Input() inputLabel:string = 'Enter reason for deactivation';
  @Input() modalHeader:string = 'Deactivate';
  @Input() buttonLabel:string = 'Deactivate';

  @Input() onDisapprove: () => void;

  @Output() onSuccessEmitter = new EventEmitter();

  constructor(
    private bsModalRef: BsModalRef
  ) {
  }

  ngOnInit(): void {
  }

  close(): void {
    this.onSuccessEmitter.emit();
    this.bsModalRef.hide();
  }

  disapprove(){
    this.onDisapprove();
  }

}
