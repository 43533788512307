/**
 * Jerry Service API
 * The Jerry RESTful service using springdoc and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NodeSettingDto { 
    fpScannerType?: NodeSettingDto.FpScannerTypeEnum;
    docScannerType?: string;
    webcamType?: string;
    sigPadType?: string;
}
export namespace NodeSettingDto {
    export type FpScannerTypeEnum = 'OTHER_SUPPORTED' | 'SUPREMA' | 'GREENBIT' | 'INTEGRATED_BIOMETRICS' | 'CHAMELEON';
    export const FpScannerTypeEnum = {
        OTHER_SUPPORTED: 'OTHER_SUPPORTED' as FpScannerTypeEnum,
        SUPREMA: 'SUPREMA' as FpScannerTypeEnum,
        GREENBIT: 'GREENBIT' as FpScannerTypeEnum,
        INTEGRATED_BIOMETRICS: 'INTEGRATED_BIOMETRICS' as FpScannerTypeEnum,
        CHAMELEON: 'CHAMELEON' as FpScannerTypeEnum
    };
}


