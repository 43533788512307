<form [formGroup]="form">
  <div class="card customer-info-card border-0">
    <div class="card-body">
      <p class="text-center fw-bold fs-18">
        Enter Customer Information
      </p>
      <div class="row row-gap-3">
        <div class="col-md-6">
          <div class="form-group">
            <label for="firstName" class="required">First Name</label>
            <input id="firstName" type="text" class="form-control" placeholder="Enter First Name"
                   formControlName="firstName">
            <error-message
              [controlName]="'firstName'"
              [singularError]="true"
              [form]="form"
              [customErrorMessages]="[
                {
                  type: 'consecutiveLetters',
                  message: 'Name cannot contain more than 3 consecutive same letters'
                }
              ]"
            ></error-message>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="surname" class="required">Surname</label>
            <input id="surname" type="text" class="form-control" placeholder="Enter Surname"
                   formControlName="surname">
            <error-message
              [controlName]="'surname'"
              [singularError]="true"
              [form]="form"
              [customErrorMessages]="[
                {
                  type: 'consecutiveLetters',
                  message: 'Name cannot contain more than 3 consecutive same letters'
                }
              ]"
            ></error-message>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="nin" class="required">NIN</label>
            <input id="nin" type="text" class="form-control" placeholder="Enter NIN"
                   formControlName="nin">
            <error-message
              [controlName]="'nin'"
              [singularError]="true"
              [form]="form"
              [customErrorMessages]="[
                {
                  type: 'cannotStartWithZero',
                  message: 'NIN cannot start with zero'
                }
              ]"
            ></error-message>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="dateOfBirth" class="required">Date of Birth</label>
            <input id="dateOfBirth" #dp="bsDatepicker" bsDatepicker type="text" class="form-control"
                   placeholder="DD-MM-YYY"
                   formControlName="dateOfBirth" [maxDate]="minAge" [minDate]="maxAge"
                   [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }">
            <error-message
              [controlName]="'dateOfBirth'"
              [singularError]="true"
              [form]="form"
            ></error-message>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="phoneNumber" class="optional form-label">Phone Number</label>
            <br>
            <ngx-intl-tel-input
              id="phoneNumber"
              [cssClass]="'custom w-100 form-control'"
              style="width: 100%; border: 0px;"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                        SearchCountryField.Iso2,
                        SearchCountryField.Name
                      ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="selectedCountryISO"
              [separateDialCode]="separateDialCode"
              [numberFormat]="PhoneNumberFormat.National"
              [maxLength]="15"
              [phoneValidation]="true"
              [formControlName]="'phoneNumber'"
              [customPlaceholder]="'Enter Phone Number'"
            ></ngx-intl-tel-input>
            <br>
            <error-message
              [controlName]="'phoneNumber'"
              [singularError]="true"
              [form]="form"
            ></error-message>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="emailAddress" class="optional">Email Address</label>
            <input id="emailAddress" type="text" class="form-control" placeholder="Enter Email Address"
                   formControlName="emailAddress">
            <error-message
              [controlName]="'emailAddress'"
              [singularError]="true"
              [form]="form"
            ></error-message>
          </div>
        </div>
      </div>

      <button [disabled]="loading" class="mt-5 btn btn-primary float-end" (click)="proceed()">
        <i class="iconify icon" data-icon="healthicons:fingerprint-outline"></i>
        Process to Biometric Verification
      </button>
    </div>
  </div>
</form>
