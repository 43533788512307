/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * any attribute in this Object may be empty, depending on attributeNames sent
 */
export interface PersonResponseSchema { 
    /**
     * firstName or {         \"message\": \"Unknown attribute\",         \"code\": 1023     }
     */
    firstName?: string;
    /**
     * lastName or{         \"message\": \"Unknown attribute\",         \"code\": 1023     }
     */
    lastName?: string;
    /**
     * dateOfBirth or{         \"message\": \"Unknown attribute\",         \"code\": 1023     }
     */
    dateOfBirth?: string;
    /**
     * gender or{         \"message\": \"Unknown attribute\",         \"code\": 1023     }
     */
    gender?: string;
}

