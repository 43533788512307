<!-- <app-full-page-loader [customText]="'Launching the MTN HVAS Clone...'"></app-full-page-loader> -->

  <div class="main ">
    <section class="hero-bg py-5" style="height: 80vh">
      <div class="container py-2 mt-md-5 ">
        <div class="row py-5 mt-2  align-items-center">
          <div class="col-lg-7">
            <div class="mx-auto" style="max-width: 660px;">
              <h1 class="mb-4 fw-bold" style="font-size: calc(2rem + 1.8vw)!important;">Welcome to the <br><span class="white-space-nowrap">HA-NVS</span> Node!</h1>

              <p class="mb-3">
                This is the unit of distribution for the verification services which are geographically dispersed across authorized integration partners' data centers.
              </p>

            </div>
          </div>
          <div class="col-lg-5">
            <div class="hero-img-wrapper text-center">
              <img src="/assets/img/etlIcon.svg" class="img-fluid" alt="" />
              <!-- <div class="text-header">
                Extraction Transformation and <strong> LOAD </strong>Tool
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>



