export * from './audit-trail-controller.service';
import { AuditTrailControllerService } from './audit-trail-controller.service';
export * from './clone-controller.service';
import { CloneControllerService } from './clone-controller.service';
export * from './clone-management-controller.service';
import { CloneManagementControllerService } from './clone-management-controller.service';
export * from './clone-services-search-controller.service';
import { CloneServicesSearchControllerService } from './clone-services-search-controller.service';
export * from './dashboard-controller.service';
import { DashboardControllerService } from './dashboard-controller.service';
export * from './gallery-controller.service';
import { GalleryControllerService } from './gallery-controller.service';
export * from './one-time-password-controller.service';
import { OneTimePasswordControllerService } from './one-time-password-controller.service';
export * from './person.service';
import { PersonService } from './person.service';
export * from './portal-account-controller.service';
import { PortalAccountControllerService } from './portal-account-controller.service';
export * from './portal-account-type-role-controller.service';
import { PortalAccountTypeRoleControllerService } from './portal-account-type-role-controller.service';
export * from './setting-controller.service';
import { SettingControllerService } from './setting-controller.service';
export * from './user-controller.service';
import { UserControllerService } from './user-controller.service';
export * from './user-management-controller.service';
import { UserManagementControllerService } from './user-management-controller.service';
export * from './verification-reason-controller.service';
import { VerificationReasonControllerService } from './verification-reason-controller.service';
export const APIS = [AuditTrailControllerService, CloneControllerService, CloneManagementControllerService, CloneServicesSearchControllerService, DashboardControllerService, GalleryControllerService, OneTimePasswordControllerService, PersonService, PortalAccountControllerService, PortalAccountTypeRoleControllerService, SettingControllerService, UserControllerService, UserManagementControllerService, VerificationReasonControllerService];
