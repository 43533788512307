import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {ForbiddenComponent} from "./modules/extranet/error-pages/forbidden/forbidden.component";
import {NotFoundComponent} from './modules/extranet/error-pages/not-found/not-found.component';
import {LandingPageComponent} from './modules/extranet/landing-page/landing-page.component';
import {LicenseUploadComponent} from "./modules/extranet/license-upload/license-upload.component";
import {ActivatedCloneGuard} from "./shared/guard/activated-clone.guard";
import {TwoFactorComponent} from "./modules/extranet/two-factor/two-factor.component";
import {LoggedInGuard} from "./shared/guard/logged-in.guard";
import {MultiFactorAuthenticationGuard} from "./shared/guard/multi-factor-authentication-guard.service";
import { ApiGuideComponent } from './modules/extranet/api-guide/api-guide.component';


const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    pathMatch: 'full',
    canActivate: [ActivatedCloneGuard],
    data: {
      isExtranet: true,
      // fixedHeader: true
    },
  },

  // {
  //   path: 'authentication',
  //   component: TwoFactorComponent,
  //   pathMatch: 'full',
  //   canActivate: [ActivatedCloneGuard, LoggedInGuard],
  //   data: {
  //     isExtranet: true,
  //     fixedHeader: false,
  //     hideLayout: true,
  //   },
  // },

  {
    path: 'developer-guide',
    component: ApiGuideComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true
    },
  },

  {
    path: 'license-upload',
    component: LicenseUploadComponent,
    pathMatch: 'full',
    data: {
      isExtranet: true,
      fixedHeader: true
    },
  },
  {
    path: '',
    loadChildren: () => import('./modules/intranet/intranet.module').then(m => m.IntranetModule),
    canActivate: [ActivatedCloneGuard, MultiFactorAuthenticationGuard],
    // canActivate: [LoggedInGuard],
    data: {
      isExtranet: false
    },
  },
  {
    path: '2fa/fingerprint',
    data: {
      isExtranet: true,
      hideLayout: true,
      hideFooter: true,
    },
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./shared/fingerprint-authentication/fingerprint-authentication-routing.module').then(m => m.FingerprintAuthenticationRoutingModule),
  },
  {
    path: 'download-plugin',
    data: {
      isExtranet: true,
      hideLayout: true
    },
    canActivate: [],
    loadChildren: () => import('./modules/extranet/biometric-auth-plugin/biometric-auth-plugin.module').then(m => m.BiometricAuthPluginModule),
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent,
    data: {
      isExtranet: true
    },
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      isExtranet: false
    },
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
