<ng-template #loading>
  <app-full-page-loader [customText]="'Uploading license file...'"></app-full-page-loader>
</ng-template>
<section *ngIf="!uploadingFile;else loading" class="py-5 mb-5">
  <div class="container py-5">
    <div class="mx-auto w-100 " style="max-width: 1000px;">
      <h1 class="mb-4 fw-bold" style="font-size: calc(2rem + 1.8vw)!important; text-align: center;">Welcome to the
        NIMC HA-NVS Node Application</h1>

      <p class="fs-18 m-0 mb-4" style="text-align: center;">
        Upload your node startup license to proceed.
      </p>

      <div class="d-flex align-items-center justify-content-center flex-wrap pb-2" style="gap: 8px;">
        <alert *ngIf="errorMessages" [type]="'danger'" dismissOnTimeout="10000">{{errorMessages}}</alert>
        <alert *ngIf="successMessage" [type]="'success'" dismissOnTimeout="10000">{{successMessage}}</alert>
      </div>

      <form (submit)="submitFile()" *ngIf="!fileSelected" [formGroup]="form" id="form">
        <div class="d-flex align-items-center justify-content-center flex-wrap pb-2"
             style="gap: 8px;">
          <div class=" w-100 border py-2 flex-nowrap"
               style="  max-width: 250px; border-color: 0.890265px solid rgba(150, 160, 170, 0.22); border-radius: 4px;">

            <label class="container">
              <p class="m-0" style="color: rgba(100, 110, 120, 0.33);">Click here to select license file</p>
              <input (change)="onFileSelected($event)" accept=".txt" capture="user" class="d-none flex-nowrap"
                     formControlName="file" type="file"/>
            </label>
          </div>


          <div class="flex-nowrap">
            <button (click)="showSelectError()" class="btn btn-dark" type="button">Submit</button>
          </div>
        </div>
      </form>

      <div *ngIf="fileSelected" class="d-flex align-items-center justify-content-center flex-wrap pb-2"
           style="gap: 8px;">
        <div class=" w-100 border py-2 flex-nowrap"
             style="max-width: 250px; border-color: 0.890265px solid rgba(150, 160, 170, 0.22); border-radius: 4px;">
          <div class="d-flex justify-content-between px-2">
            <div class="fs-12 m-0 m" style="overflow-wrap: break-word;">
              {{fileName}}<br> {{fileSize}}
            </div>

            <div (click)="clearFileData()" style="cursor: pointer">
              <span class="iconify text-danger" data-icon="fluent:dismiss-20-regular"></span>
            </div>

          </div>
        </div>

        <div class="flex-nowrap">
          <button (click)="submitFile()" [disabled]="uploadingFile" class="btn btn-dark">Submit</button>
        </div>
      </div>


      <div class="fs-14 d-flex align-items-center justify-content-center mt-4" style="color: rgba(87, 87, 87, 0.8);">
        <div>
          <p class="m-0">No License?</p>
        </div>
        <div>
          <p class="ms-1 m-0">Contact support</p>
        </div>
      </div>

      <div class="fs-14 d-flex align-items-center justify-content-center mt-2 flex-wrap" style="gap: 8px;">
        <div class="d-flex align-items-center flex-nowrap">
          <div>
            <span class="iconify" data-icon="fluent:call-24-filled" style="color: #1551AA;"></span>
          </div>
          <div>
            <a class="text-dark" href="tel:+2348157691145">+234 815 769 1145</a>
          </div>
        </div>
      </div>
    </div>
  </div>


</section>
