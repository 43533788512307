/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BiometricData { 
    biometricType?: BiometricData.BiometricTypeEnum;
    biometricSubType?: BiometricData.BiometricSubTypeEnum;
    image?: string;
    imageRef?: string;
    compression?: BiometricData.CompressionEnum;
    metadata?: string;
    quality?: number;
    qualityFormat?: string;
}
export namespace BiometricData {
    export type BiometricTypeEnum = 'FACE' | 'FINGER' | 'IRIS' | 'SIGNATURE' | 'UNKNOWN';
    export const BiometricTypeEnum = {
        Face: 'FACE' as BiometricTypeEnum,
        Finger: 'FINGER' as BiometricTypeEnum,
        Iris: 'IRIS' as BiometricTypeEnum,
        Signature: 'SIGNATURE' as BiometricTypeEnum,
        Unknown: 'UNKNOWN' as BiometricTypeEnum
    };
    export type BiometricSubTypeEnum = 'UNKNOWN' | 'RIGHT_THUMB' | 'RIGHT_INDEX' | 'RIGHT_MIDDLE' | 'RIGHT_RING' | 'RIGHT_LITTLE' | 'LEFT_THUMB' | 'LEFT_INDEX' | 'LEFT_MIDDLE' | 'LEFT_RING' | 'LEFT_LITTLE' | 'PLAIN_RIGHT_FOUR_FINGERS' | 'PLAIN_LEFT_FOUR_FINGERS' | 'PLAIN_THUMBS' | 'UNKNOWN_PALM' | 'RIGHT_FULL_PALM' | 'RIGHT_WRITERS_PALM' | 'LEFT_FULL_PALM' | 'LEFT_WRITERS_PALM' | 'RIGHT_LOWER_PALM' | 'RIGHT_UPPER_PALM' | 'LEFT_LOWER_PALM' | 'LEFT_UPPER_PALM' | 'RIGHT_OTHER' | 'LEFT_OTHER' | 'RIGHT_INTERDIGITAL' | 'RIGHT_THENAR' | 'LEFT_INTERDIGITAL' | 'LEFT_THENAR' | 'LEFT_HYPOTHENAR' | 'RIGHT_INDEX_AND_MIDDLE' | 'RIGHT_MIDDLE_AND_RING' | 'RIGHT_RING_AND_LITTLE' | 'LEFT_INDEX_AND_MIDDLE' | 'LEFT_MIDDLE_AND_RING' | 'LEFT_RING_AND_LITTLE' | 'RIGHT_INDEX_AND_LEFT_INDEX' | 'RIGHT_INDEX_AND_MIDDLE_AND_RING' | 'RIGHT_MIDDLE_AND_RING_AND_LITTLE' | 'LEFT_INDEX_AND_MIDDLE_AND_RING' | 'LEFT_MIDDLE_AND_RING_AND_LITTLE' | 'EYE_UNDEF' | 'EYE_RIGHT' | 'EYE_LEFT' | 'PORTRAIT' | 'LEFT_PROFILE' | 'RIGHT_PROFILE';
    export const BiometricSubTypeEnum = {
        Unknown: 'UNKNOWN' as BiometricSubTypeEnum,
        RightThumb: 'RIGHT_THUMB' as BiometricSubTypeEnum,
        RightIndex: 'RIGHT_INDEX' as BiometricSubTypeEnum,
        RightMiddle: 'RIGHT_MIDDLE' as BiometricSubTypeEnum,
        RightRing: 'RIGHT_RING' as BiometricSubTypeEnum,
        RightLittle: 'RIGHT_LITTLE' as BiometricSubTypeEnum,
        LeftThumb: 'LEFT_THUMB' as BiometricSubTypeEnum,
        LeftIndex: 'LEFT_INDEX' as BiometricSubTypeEnum,
        LeftMiddle: 'LEFT_MIDDLE' as BiometricSubTypeEnum,
        LeftRing: 'LEFT_RING' as BiometricSubTypeEnum,
        LeftLittle: 'LEFT_LITTLE' as BiometricSubTypeEnum,
        PlainRightFourFingers: 'PLAIN_RIGHT_FOUR_FINGERS' as BiometricSubTypeEnum,
        PlainLeftFourFingers: 'PLAIN_LEFT_FOUR_FINGERS' as BiometricSubTypeEnum,
        PlainThumbs: 'PLAIN_THUMBS' as BiometricSubTypeEnum,
        UnknownPalm: 'UNKNOWN_PALM' as BiometricSubTypeEnum,
        RightFullPalm: 'RIGHT_FULL_PALM' as BiometricSubTypeEnum,
        RightWritersPalm: 'RIGHT_WRITERS_PALM' as BiometricSubTypeEnum,
        LeftFullPalm: 'LEFT_FULL_PALM' as BiometricSubTypeEnum,
        LeftWritersPalm: 'LEFT_WRITERS_PALM' as BiometricSubTypeEnum,
        RightLowerPalm: 'RIGHT_LOWER_PALM' as BiometricSubTypeEnum,
        RightUpperPalm: 'RIGHT_UPPER_PALM' as BiometricSubTypeEnum,
        LeftLowerPalm: 'LEFT_LOWER_PALM' as BiometricSubTypeEnum,
        LeftUpperPalm: 'LEFT_UPPER_PALM' as BiometricSubTypeEnum,
        RightOther: 'RIGHT_OTHER' as BiometricSubTypeEnum,
        LeftOther: 'LEFT_OTHER' as BiometricSubTypeEnum,
        RightInterdigital: 'RIGHT_INTERDIGITAL' as BiometricSubTypeEnum,
        RightThenar: 'RIGHT_THENAR' as BiometricSubTypeEnum,
        LeftInterdigital: 'LEFT_INTERDIGITAL' as BiometricSubTypeEnum,
        LeftThenar: 'LEFT_THENAR' as BiometricSubTypeEnum,
        LeftHypothenar: 'LEFT_HYPOTHENAR' as BiometricSubTypeEnum,
        RightIndexAndMiddle: 'RIGHT_INDEX_AND_MIDDLE' as BiometricSubTypeEnum,
        RightMiddleAndRing: 'RIGHT_MIDDLE_AND_RING' as BiometricSubTypeEnum,
        RightRingAndLittle: 'RIGHT_RING_AND_LITTLE' as BiometricSubTypeEnum,
        LeftIndexAndMiddle: 'LEFT_INDEX_AND_MIDDLE' as BiometricSubTypeEnum,
        LeftMiddleAndRing: 'LEFT_MIDDLE_AND_RING' as BiometricSubTypeEnum,
        LeftRingAndLittle: 'LEFT_RING_AND_LITTLE' as BiometricSubTypeEnum,
        RightIndexAndLeftIndex: 'RIGHT_INDEX_AND_LEFT_INDEX' as BiometricSubTypeEnum,
        RightIndexAndMiddleAndRing: 'RIGHT_INDEX_AND_MIDDLE_AND_RING' as BiometricSubTypeEnum,
        RightMiddleAndRingAndLittle: 'RIGHT_MIDDLE_AND_RING_AND_LITTLE' as BiometricSubTypeEnum,
        LeftIndexAndMiddleAndRing: 'LEFT_INDEX_AND_MIDDLE_AND_RING' as BiometricSubTypeEnum,
        LeftMiddleAndRingAndLittle: 'LEFT_MIDDLE_AND_RING_AND_LITTLE' as BiometricSubTypeEnum,
        EyeUndef: 'EYE_UNDEF' as BiometricSubTypeEnum,
        EyeRight: 'EYE_RIGHT' as BiometricSubTypeEnum,
        EyeLeft: 'EYE_LEFT' as BiometricSubTypeEnum,
        Portrait: 'PORTRAIT' as BiometricSubTypeEnum,
        LeftProfile: 'LEFT_PROFILE' as BiometricSubTypeEnum,
        RightProfile: 'RIGHT_PROFILE' as BiometricSubTypeEnum
    };
    export type CompressionEnum = 'NONE' | 'WSQ' | 'JPEG' | 'JPEG2000' | 'PNG';
    export const CompressionEnum = {
        None: 'NONE' as CompressionEnum,
        Wsq: 'WSQ' as CompressionEnum,
        Jpeg: 'JPEG' as CompressionEnum,
        Jpeg2000: 'JPEG2000' as CompressionEnum,
        Png: 'PNG' as CompressionEnum
    };
}


