import {EventEmitter, Injectable} from '@angular/core';
import {FingerprintScanConfigService} from "./fingerprint-scan-config.service";
import {Observable, Subject} from "rxjs";
import {AlertType, FingerTypeEnum} from "../variables";

@Injectable({
  providedIn: 'root'
})
export class FingerprintScanService {

  private finger$: Subject<number> = new Subject<number>();

  private action$: Subject<'SCAN' | 'LOGIN' | 'RETRY'> = new Subject<'SCAN' | 'LOGIN' | 'RETRY'>();

  private action: 'SCAN' | 'LOGIN' | 'RETRY';

  private trialAttempt$: Subject<number> = new Subject<number>();

  private trialAttemptLeft$: Subject<number> = new Subject<number>();

  public clearCounter$: Subject<any> = new Subject<any>();

  fpConnectionStatus$: Subject<boolean> = new Subject<boolean>();

  pluginDetectionStatus$: Subject<boolean> = new Subject<boolean>();

  private startScan$: Subject<any> = new Subject<number>();

  public showAlertMessage: EventEmitter<{ msg: string; type: AlertType; persist?: boolean }> = new EventEmitter<any>();

  constructor(private fingerprintConfigService: FingerprintScanConfigService) {
  }

  get fingerprintToScanObservable(): Observable<number> {
    return this.finger$.asObservable();
  }

  get actionAsObservable(): Observable<'SCAN' | 'LOGIN' | 'RETRY'> {
    return this.action$.asObservable();
  }

  set fingerprintToScan(finger: number) {
    if (finger) {
      this.finger$.next(finger);
    }
  }

  set setAction(action: 'SCAN' | 'LOGIN' | 'RETRY') {
    if (action) {
      this.action = action;
      this.action$.next(action);
    }
  }

  get getAction(): 'SCAN' | 'LOGIN' | 'RETRY' {
    return this.action;
  }

  getFingerTypeFromIndex(index: number): FingerTypeEnum {
    return Object.values(FingerTypeEnum)[Number(index) - 1];
  }

  async init(): Promise<void> {
    // await this.fingerprintConfigService.loadConfig();
    // this.apiEndpoint = this.biometricConfigService.getApiEndpoint();
    // this.pollingInterval = this.biometricConfigService.getPollingInterval();
    // this.maxAttempts = this.biometricConfigService.getMaxAttempts();
  }

  clearCounter(): void {
    this.clearCounter$.next(undefined)
  }

  set trialAttempt(attempt: number) {
    this.trialAttempt$.next(attempt);
  }

  get trialAttemptAsObservable(): Observable<number> {
    return this.trialAttempt$.asObservable();
  }

  set trialAttemptLeft(attempt: number) {
    this.trialAttemptLeft$.next(attempt);
  }

  get trialAttemptLeftAsObservable(): Observable<number> {
    return this.trialAttemptLeft$.asObservable();
  }

  set fpConnectionStatus(online: boolean) {
    this.fpConnectionStatus$.next(undefined);
  }

  get fpConnectionStatusAsObservable(): Observable<boolean> {
    return this.fpConnectionStatus$.asObservable();
  }

  set pluginDetectionStatus(online: boolean) {
    this.pluginDetectionStatus$.next(undefined);
  }

  get pluginDetectionStatusAsObservable(): Observable<boolean> {
    return this.pluginDetectionStatus$.asObservable();
  }

  startScan(): void {
    this.startScan$.next(undefined);
  }

  get startScanAsObservable(): Observable<any> {
    return this.startScan$.asObservable();
  }
}
