<div class="card customer-info-card border-0">
  <div class="card-body">
    <div class="float-end">
      <div (click)="goBack()" class="d-flex align-items-center pointer">
        <div class="px-1 me-2" style="background: #1551AA; border-radius: 4px">
          <span class="iconify text-white" data-icon="ep:back"></span>
        </div>
        <p class="mb-0 fs-14">Go back</p>
      </div>
    </div>
    <div class="fw-bold fs-3 text-center mb-5" style="color: #00386D">
      {{ title }}
    </div>

    <app-fingerprint-auth
      (fingerprintEvent)="getFingerCaptureEvent($event)"
      (loginEvent)="login()"
      (bypassEvent)="bypass()"
      (retryEvent)="retry()"
      (shuffleEvent)="shuffle()"
      [loginButtonDisabled$]="loginButtonDisabled"
    ></app-fingerprint-auth>
  </div>
</div>

