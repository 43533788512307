import {Component, OnInit} from '@angular/core';
import {CloneControllerService} from "../../../../../sdk/hclone-api-sdk";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-license-upload',
  templateUrl: './license-upload.component.html',
  styleUrls: ['./license-upload.component.css']
})
export class LicenseUploadComponent implements OnInit {

  fileSelected = false;
  fileName: string = "";
  fileSize: string = "";
  errorMessages = "";
  fileData: any;
  successMessage = "";
  uploadingFile = false;
  form: FormGroup;

  constructor(
    private cloneControllerService: CloneControllerService,
    private router: Router,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
  ) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({'file': ['', [Validators.required]]})
  }

  onFileSelected(event: any) {
    let file = event.target.files[0];
    if (file) {
      if (file.size > (1024 * 1024)) {
        this.errorMessages = "File too large, can only accept files smaller than 1MB!";
        return;
      }
      const filesAmount = event.target.files.length;

      if (filesAmount > 1) {
        this.errorMessages = "Too many selected files, can only accept 1!";
        return;
      }
      let fileType = event.target.files[0].type;
      let fileName = event.target.files[0].name;
      let fileSize = this.formatFileSize(event.target.files[0].size, 2);

      if (fileType != "text/plain") {
        this.errorMessages = "Invalid file format, can only accept .txt files!";
        return;
      }

      this.fileName = fileName;
      this.fileSize = fileSize;
      this.fileData = file;
      this.fileSelected = true;
    }
    // console.log("File selected");
    // this.errorMessages = "";
    // if (event.target.files && event.target.files[0]) {
    //
    //
    //   const reader = new FileReader();
    //
    //   reader.onload = (event: any) => {
    //     console.log(typeof event.target.result);
    //     console.log(event.target.result);
    //     this.fileData = event.target.result.toString();
    //     this.fileName = fileName;
    //     this.fileSize = fileSize;
    //     console.log(this.fileData);
    //     console.log(this.fileName);
    //     console.log(this.fileSize);
    //     this.fileSelected = true;
    //   }
    //   console.log(typeof (reader.readAsDataURL(event.target.files[0])));
    //
    // }
    // event.target.value = null;
  }


  private formatFileSize(byte: number, decimalPoints: number) {
    if (byte == 0) {
      return "0 Bytes";
    }
    var k = 1024;
    let dm = decimalPoints || 2;
    let sizes = ['Bytes', 'KB', 'MB']
    let i = Math.floor(Math.log(byte) / Math.log(k));
    return parseFloat((byte / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  clearFileData() {
    this.fileData = "";
    this.fileName = "";
    this.fileSize = "";
    this.errorMessages = "";
    this.fileSelected = false;
    this.form.reset();
  }

  submitFile() {
    this.uploadingFile = true;
    const formData = new FormData()
    console.log(this.fileData)
    formData.append('file', this.fileData, 'licenseFile')
    this.httpClient.post<any>(`${environment.apiBaseUrl}/clones/upload-license`, formData, {
      reportProgress: true,
      observe: 'events'
    }).subscribe(
      (data) => {
        this.uploadingFile = false;
        window.location.href = '/dashboard'
      }, error => {
        this.uploadingFile = false;
        if (error.error && typeof error.error == 'object' && error.error.message) {
          this.errorMessages = error.error.message;
          return;
        }
      });
    // this.cloneControllerService.uploadLicense({body: this.fileData}).subscribe(
    //   (data) => {
    //     this.uploadingFile = false;
    //     this.router.navigateByUrl('/dashboard')
    //   }, error => {
    //     this.uploadingFile = false;
    //     if (error.error && typeof error.error == 'object' && error.error.message) {
    //       this.errorMessages = error.error.message;
    //       return;
    //     }
    //   });
  }

  showSelectError() {
    this.errorMessages = "Please select a file!";
    return
  }
}
