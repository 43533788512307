/**
 * Jerry Service API
 * The Jerry RESTful service using springdoc and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeviceConnectionResponse { 
    connected?: boolean;
    deviceInfo?: string;
    statusMessage?: string;
    timeOfCheck?: string;
    deviceType?: DeviceConnectionResponse.DeviceTypeEnum;
}
export namespace DeviceConnectionResponse {
    export type DeviceTypeEnum = 'FINGERPRINT_SCANNER' | 'IRIS_SCANNER' | 'GPS_READER' | 'CAMERA' | 'VPN';
    export const DeviceTypeEnum = {
        FINGERPRINT_SCANNER: 'FINGERPRINT_SCANNER' as DeviceTypeEnum,
        IRIS_SCANNER: 'IRIS_SCANNER' as DeviceTypeEnum,
        GPS_READER: 'GPS_READER' as DeviceTypeEnum,
        CAMERA: 'CAMERA' as DeviceTypeEnum,
        VPN: 'VPN' as DeviceTypeEnum
    };
}


