/**
 * Jerry Service API
 * The Jerry RESTful service using springdoc and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GpsCoordinate } from './gps-coordinate';


export interface GpsCoordinateResponse { 
    coordinate?: GpsCoordinate;
    inRange?: boolean;
    gpsConnected?: boolean;
}

