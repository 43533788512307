/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The names of the attributes to return. At least one must be provided
 */
export interface BiographicData { 
    title?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    otherName?: string;
    maidenName?: string;
    previousFirstName?: string;
    previousMiddleName?: string;
    previousLastName?: string;
    dateOfBirth?: string;
    gender?: BiographicData.GenderEnum;
    birthCountry?: string;
    birthState?: string;
    birthLga?: string;
    maritalStatus?: BiographicData.MaritalStatusEnum;
    height?: string;
    residenceStatus?: BiographicData.ResidenceStatusEnum;
    religion?: string;
    nativeSpokenLanguage?: string;
    otherSpokenLanguage?: string;
    readWriteLanguage?: string;
    educationLevel?: string;
    profession?: string;
    employmentStatus?: string;
    nationality?: string;
    residenceLga?: string;
    residenceState?: string;
    residenceCountry?: string;
    originLga?: string;
    originState?: string;
    originPlace?: string;
    physicalStatus?: BiographicData.PhysicalStatusEnum;
    deathVerification?: BiographicData.DeathVerificationEnum;
    originCountry?: string;
    fatherOriginLga?: string;
    fatherOriginState?: string;
    fatherOriginPlace?: string;
    fatherOriginCountry?: string;
    motherOriginLga?: string;
    motherOriginState?: string;
    motherOriginPlace?: string;
    motherOriginCountry?: string;
    residenceAddressLine1?: string;
    residenceAddressLine2?: string;
    residenceTown?: string;
    nextOfKinAddressLine1?: string;
    nextOfKinAddressLine2?: string;
    nextOfKinPostCode?: string;
    nextOfKinTown?: string;
    nextOfKinLga?: string;
    nextOfKinRelation?: string;
    nextOfKinCountry?: string;
}
export namespace BiographicData {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'M' | 'F' | 'UNSPECIFIED' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        M: 'M' as GenderEnum,
        F: 'F' as GenderEnum,
        Unspecified: 'UNSPECIFIED' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
    export type MaritalStatusEnum = 'married' | 'single' | 'separated' | 'divorced' | 'widowed';
    export const MaritalStatusEnum = {
        Married: 'married' as MaritalStatusEnum,
        Single: 'single' as MaritalStatusEnum,
        Separated: 'separated' as MaritalStatusEnum,
        Divorced: 'divorced' as MaritalStatusEnum,
        Widowed: 'widowed' as MaritalStatusEnum
    };
    export type ResidenceStatusEnum = 'naturalisation' | 'birth' | 'registration' | 'legalresidence';
    export const ResidenceStatusEnum = {
        Naturalisation: 'naturalisation' as ResidenceStatusEnum,
        Birth: 'birth' as ResidenceStatusEnum,
        Registration: 'registration' as ResidenceStatusEnum,
        Legalresidence: 'legalresidence' as ResidenceStatusEnum
    };
    export type PhysicalStatusEnum = 'ALIVE' | 'DEAD';
    export const PhysicalStatusEnum = {
        Alive: 'ALIVE' as PhysicalStatusEnum,
        Dead: 'DEAD' as PhysicalStatusEnum
    };
    export type DeathVerificationEnum = 'DERIVED' | 'REPORTED' | 'VERIFIED';
    export const DeathVerificationEnum = {
        Derived: 'DERIVED' as DeathVerificationEnum,
        Reported: 'REPORTED' as DeathVerificationEnum,
        Verified: 'VERIFIED' as DeathVerificationEnum
    };
}


