/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { NewPortalUserDto } from '../model/models';
import { PortalUserSearchResponsePojo } from '../model/models';
import { QueryResultsPortalUserSearchResponsePojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface ActivateUserRequestParams {
    userId: string;
}

export interface AddRoleToAccountRequestParams {
    userId: string;
    accountCode: string;
    role: string;
}

export interface CreateUserRequestParams {
    newPortalUserDto: NewPortalUserDto;
}

export interface DeactivateUserRequestParams {
    userId: string;
}

export interface GetUserDetailsByUserIdRequestParams {
    userId: string;
}

export interface RemoveRoleFromAccountRequestParams {
    userId: string;
    accountCode: string;
    role: string;
}

export interface SearchPortalUsersRequestParams {
    lastName?: string;
    gender?: 'MALE' | 'FEMALE' | 'UNSPECIFIED' | 'OTHER';
    generatedPassword?: string;
    lastUpdatedAt?: string;
    displayName?: string;
    deactivatedBy?: string;
    portalAccountMemberships?: string;
    createdAt?: string;
    nin?: string;
    preferredName?: string;
    id?: number;
    email?: string;
    nationalIdentificationNumber?: string;
    lastUpdatedBy?: string;
    setupComplete?: boolean;
    dateOfBirth?: string;
    userId?: string;
    firstName?: string;
    emailVerified?: boolean;
    lastLoginTime?: string;
    otherNames?: string;
    phoneNumber?: string;
    authenticationMethod?: 'PASSWORD' | 'FINGERPRINT';
    createdBy?: string;
    dateDeactivated?: string;
    username?: string;
    status?: 'ACTIVE' | 'INACTIVE';
    accountCode?: string;
    offset?: number;
    accountType?: 'HNODE' | 'SYSTEM_SUPPORT';
    limit?: number;
    createdBefore?: string;
    createdAfter?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}


@Injectable({
  providedIn: 'root'
})
export class UserManagementControllerService {

    protected basePath = 'http://hnode.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateUser(requestParameters: ActivateUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public activateUser(requestParameters: ActivateUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public activateUser(requestParameters: ActivateUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public activateUser(requestParameters: ActivateUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const userId = requestParameters.userId;
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling activateUser.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/users/${encodeURIComponent(String(userId))}/activate`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addRoleToAccount(requestParameters: AddRoleToAccountRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public addRoleToAccount(requestParameters: AddRoleToAccountRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public addRoleToAccount(requestParameters: AddRoleToAccountRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public addRoleToAccount(requestParameters: AddRoleToAccountRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const userId = requestParameters.userId;
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling addRoleToAccount.');
        }
        const accountCode = requestParameters.accountCode;
        if (accountCode === null || accountCode === undefined) {
            throw new Error('Required parameter accountCode was null or undefined when calling addRoleToAccount.');
        }
        const role = requestParameters.role;
        if (role === null || role === undefined) {
            throw new Error('Required parameter role was null or undefined when calling addRoleToAccount.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (accountCode !== undefined && accountCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>accountCode, 'accountCode');
        }
        if (role !== undefined && role !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>role, 'role');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/users/${encodeURIComponent(String(userId))}/add-role`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUser(requestParameters: CreateUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PortalUserSearchResponsePojo>;
    public createUser(requestParameters: CreateUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PortalUserSearchResponsePojo>>;
    public createUser(requestParameters: CreateUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PortalUserSearchResponsePojo>>;
    public createUser(requestParameters: CreateUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const newPortalUserDto = requestParameters.newPortalUserDto;
        if (newPortalUserDto === null || newPortalUserDto === undefined) {
            throw new Error('Required parameter newPortalUserDto was null or undefined when calling createUser.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<PortalUserSearchResponsePojo>(`${this.configuration.basePath}/users`,
            newPortalUserDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deactivateUser(requestParameters: DeactivateUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public deactivateUser(requestParameters: DeactivateUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public deactivateUser(requestParameters: DeactivateUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public deactivateUser(requestParameters: DeactivateUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const userId = requestParameters.userId;
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deactivateUser.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/users/${encodeURIComponent(String(userId))}/deactivate`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDetailsByUserId(requestParameters: GetUserDetailsByUserIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PortalUserSearchResponsePojo>;
    public getUserDetailsByUserId(requestParameters: GetUserDetailsByUserIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PortalUserSearchResponsePojo>>;
    public getUserDetailsByUserId(requestParameters: GetUserDetailsByUserIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PortalUserSearchResponsePojo>>;
    public getUserDetailsByUserId(requestParameters: GetUserDetailsByUserIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const userId = requestParameters.userId;
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserDetailsByUserId.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PortalUserSearchResponsePojo>(`${this.configuration.basePath}/users/${encodeURIComponent(String(userId))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeRoleFromAccount(requestParameters: RemoveRoleFromAccountRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public removeRoleFromAccount(requestParameters: RemoveRoleFromAccountRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public removeRoleFromAccount(requestParameters: RemoveRoleFromAccountRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public removeRoleFromAccount(requestParameters: RemoveRoleFromAccountRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const userId = requestParameters.userId;
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling removeRoleFromAccount.');
        }
        const accountCode = requestParameters.accountCode;
        if (accountCode === null || accountCode === undefined) {
            throw new Error('Required parameter accountCode was null or undefined when calling removeRoleFromAccount.');
        }
        const role = requestParameters.role;
        if (role === null || role === undefined) {
            throw new Error('Required parameter role was null or undefined when calling removeRoleFromAccount.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (accountCode !== undefined && accountCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>accountCode, 'accountCode');
        }
        if (role !== undefined && role !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>role, 'role');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/users/${encodeURIComponent(String(userId))}/remove-role`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPortalUsers(requestParameters: SearchPortalUsersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsPortalUserSearchResponsePojo>;
    public searchPortalUsers(requestParameters: SearchPortalUsersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsPortalUserSearchResponsePojo>>;
    public searchPortalUsers(requestParameters: SearchPortalUsersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsPortalUserSearchResponsePojo>>;
    public searchPortalUsers(requestParameters: SearchPortalUsersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const lastName = requestParameters.lastName;
        const gender = requestParameters.gender;
        const generatedPassword = requestParameters.generatedPassword;
        const lastUpdatedAt = requestParameters.lastUpdatedAt;
        const displayName = requestParameters.displayName;
        const deactivatedBy = requestParameters.deactivatedBy;
        const portalAccountMemberships = requestParameters.portalAccountMemberships;
        const createdAt = requestParameters.createdAt;
        const nin = requestParameters.nin;
        const preferredName = requestParameters.preferredName;
        const id = requestParameters.id;
        const email = requestParameters.email;
        const nationalIdentificationNumber = requestParameters.nationalIdentificationNumber;
        const lastUpdatedBy = requestParameters.lastUpdatedBy;
        const setupComplete = requestParameters.setupComplete;
        const dateOfBirth = requestParameters.dateOfBirth;
        const userId = requestParameters.userId;
        const firstName = requestParameters.firstName;
        const emailVerified = requestParameters.emailVerified;
        const lastLoginTime = requestParameters.lastLoginTime;
        const otherNames = requestParameters.otherNames;
        const phoneNumber = requestParameters.phoneNumber;
        const authenticationMethod = requestParameters.authenticationMethod;
        const createdBy = requestParameters.createdBy;
        const dateDeactivated = requestParameters.dateDeactivated;
        const username = requestParameters.username;
        const status = requestParameters.status;
        const accountCode = requestParameters.accountCode;
        const offset = requestParameters.offset;
        const accountType = requestParameters.accountType;
        const limit = requestParameters.limit;
        const createdBefore = requestParameters.createdBefore;
        const createdAfter = requestParameters.createdAfter;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (lastName !== undefined && lastName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastName, 'lastName');
        }
        if (gender !== undefined && gender !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gender, 'gender');
        }
        if (generatedPassword !== undefined && generatedPassword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>generatedPassword, 'generatedPassword');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (displayName !== undefined && displayName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>displayName, 'displayName');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (portalAccountMemberships !== undefined && portalAccountMemberships !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccountMemberships, 'portalAccountMemberships');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (nin !== undefined && nin !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>nin, 'nin');
        }
        if (preferredName !== undefined && preferredName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>preferredName, 'preferredName');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (email !== undefined && email !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>email, 'email');
        }
        if (nationalIdentificationNumber !== undefined && nationalIdentificationNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>nationalIdentificationNumber, 'nationalIdentificationNumber');
        }
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (setupComplete !== undefined && setupComplete !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>setupComplete, 'setupComplete');
        }
        if (dateOfBirth !== undefined && dateOfBirth !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateOfBirth, 'dateOfBirth');
        }
        if (userId !== undefined && userId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userId, 'userId');
        }
        if (firstName !== undefined && firstName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>firstName, 'firstName');
        }
        if (emailVerified !== undefined && emailVerified !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>emailVerified, 'emailVerified');
        }
        if (lastLoginTime !== undefined && lastLoginTime !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastLoginTime, 'lastLoginTime');
        }
        if (otherNames !== undefined && otherNames !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>otherNames, 'otherNames');
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>phoneNumber, 'phoneNumber');
        }
        if (authenticationMethod !== undefined && authenticationMethod !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>authenticationMethod, 'authenticationMethod');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (username !== undefined && username !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>username, 'username');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (accountCode !== undefined && accountCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>accountCode, 'accountCode');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (accountType !== undefined && accountType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>accountType, 'accountType');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsPortalUserSearchResponsePojo>(`${this.configuration.basePath}/users/search`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
