import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";
import {Router} from "@angular/router";
import {Constant} from "../../../shared/models/constants";
import {
  OneTimePasswordControllerService,
  OtpCreationResponse,
  OtpRequestDto,
  OtpVerifyDto
} from "../../../../../sdk/hclone-api-sdk";

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.css']
})
export class TwoFactorComponent implements OnInit {

  disableResendButton: boolean = false;
  errorMessage: string = '';
  successMessage: string = '';
  infoMessage: string = '';
  verifyingOtp = false;
  form: FormGroup;
  optCountDown: number = 30;
  otpLength = 6;
  smsActive: boolean = false;
  deliveryMode: OtpVerifyDto.DeliveryModeEnum;


  constructor(private formBuilder: FormBuilder,
              private oneTimePasswordControllerService: OneTimePasswordControllerService,
              private authenticationService: AuthenticationService,
              private router: Router) {
  }


  ngOnInit(): void {
    if (sessionStorage.getItem(Constant.Auth.OTP_CONSTANT)) {
      this.router.navigate(['/dashboard']);
    }

    this.form = this.formBuilder.group({
      otp: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(this.otpLength), Validators.maxLength(this.otpLength)])
    });

    if (!sessionStorage.getItem(Constant.Auth.OTP_REQUESTED)) {
      this.resendOTP(OtpVerifyDto.DeliveryModeEnum.Email);
    } else {
      this.getAllFromSessionStorage();
    }

    this.form.valueChanges.subscribe(
      () => {
        this.resetMessages();
      });
  }

  verifyOTP() {
    this.verifyingOtp = true;

    let payload: OtpVerifyDto = {
      pin: this.form.get('otp')!.value,
      type: OtpVerifyDto.TypeEnum.General,
      deliveryMode: this.deliveryMode
    }

    this.oneTimePasswordControllerService.verifyOtp({otpVerifyDto: payload}).subscribe(
      (response) => {
        sessionStorage.setItem(Constant.Auth.OTP_CONSTANT, 'true');
        window.location.href = '/dashboard';
        this.router.navigate(['/dashboard']);
        window.location.reload();

      },
      (error) => {
        if (error.error && typeof error.error === 'object' && error.error.message) {
          this.errorMessage = error.error.message;
        } else {
          this.errorMessage = 'An error occurred while verifying OTP. Please try again later.';
        }
      }
    ).add(() => {
      this.verifyingOtp = false;
    });
  }

  resendOTP(deliveryMode: OtpRequestDto.DeliveryModeEnum) {

    this.resetMessages();

    if (this.disableResendButton) {
      return;
    }
    if (deliveryMode == OtpRequestDto.DeliveryModeEnum.Sms && !this.smsActive) {
      this.errorMessage = 'SMS OTP is not activated. Please use email to receive OTP.';
      return;
    }

    let payload: OtpRequestDto = {
      type: OtpVerifyDto.TypeEnum.General,
      deliveryMode: deliveryMode
    }

    this.oneTimePasswordControllerService.requestOneTimePassword({otpRequestDto: payload}).subscribe(
      (response) => {
        this.infoMessage = response.message!;
        this.otpLength = response.otpLength!;
        this.smsActive = response.smsactive!;
        this.deliveryMode = deliveryMode;
        this.setAllInSessionStorage(response);
        this.resetOtpTimer();
      },
      (error) => {
        if (error.error && typeof error.error === 'object' && error.error.message) {
          if (error.error.code == 401 || error.error.code == 403) {
            this.router.navigate(['/dashboard']);
          }
          this.errorMessage = error.error.message;
        } else {
          this.errorMessage = 'An error occurred while requesting OTP. Please try again later.';
        }
      })
  }

  resetMessages() {
    this.errorMessage = '';
    this.successMessage = '';
    // this.infoMessage = '';
  }

  preventForeignKeys($event: KeyboardEvent) {
    if ($event.key == 'e' || $event.key == 'E' || $event.key == '-' || $event.key == '.') {
      $event.preventDefault();
    }
  }

  resetOtpTimer() {
    this.optCountDown = 30;
    this.disableResendButton = true;
    const counter = setInterval(
      () => {
        if (this.optCountDown > 0) {
          this.optCountDown--;
        } else {
          this.disableResendButton = false;
          clearInterval(counter);
        }
      }, 1000
    );
  }

  setAllInSessionStorage(response: OtpCreationResponse) {
    sessionStorage.setItem(Constant.Auth.OTP_REQUESTED, 'true');
    sessionStorage.setItem(Constant.Auth.OTP_MESSAGE, response.message!);
    sessionStorage.setItem(Constant.Auth.OTP_SMS_ACTIVE, String(response.smsactive));
    sessionStorage.setItem(Constant.Auth.OTP_LENGTH, String(response.otpLength));
    if (this.deliveryMode == OtpVerifyDto.DeliveryModeEnum.Email) {
      sessionStorage.setItem(Constant.Auth.OTP_DELIVERY_MODE, Constant.Auth.OTP_DELIVERY_MODE_EMAIL);
    } else if (this.deliveryMode == OtpVerifyDto.DeliveryModeEnum.Sms) {
      sessionStorage.setItem(Constant.Auth.OTP_DELIVERY_MODE, Constant.Auth.OTP_DELIVERY_MODE_SMS);
    }
  }

  getAllFromSessionStorage() {
    this.infoMessage = sessionStorage.getItem(Constant.Auth.OTP_MESSAGE)!;
    this.smsActive = Boolean(sessionStorage.getItem(Constant.Auth.OTP_SMS_ACTIVE));
    this.otpLength = Number(sessionStorage.getItem(Constant.Auth.OTP_LENGTH));
    if (sessionStorage.getItem(Constant.Auth.OTP_DELIVERY_MODE) == Constant.Auth.OTP_DELIVERY_MODE_EMAIL) {
      this.deliveryMode = OtpVerifyDto.DeliveryModeEnum.Email;
    } else if (sessionStorage.getItem(Constant.Auth.OTP_DELIVERY_MODE) == Constant.Auth.OTP_DELIVERY_MODE_SMS) {
      this.deliveryMode = OtpVerifyDto.DeliveryModeEnum.Sms;
    }
  }

  switchDeliveryMode(deliveryMode: OtpVerifyDto.DeliveryModeEnum) {
    if (deliveryMode == OtpRequestDto.DeliveryModeEnum.Sms && !this.smsActive) {
      this.errorMessage = 'SMS OTP is not activated. Please use email to receive OTP.';
      return;
    }
    this.deliveryMode = deliveryMode;
    this.resendOTP(deliveryMode);
  }

}
