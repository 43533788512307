<div>
  <div class="container mt-2">
    <div>
      <div class="card border-0 mx-auto col-12 col-md-8 scan-background"
           style="box-shadow: 2.381px 2.381px 11.905px 11.905px rgba(0, 0, 0, 0.03); border-radius: 29.956px; ">
        <div class="card-body">
          <div class="d-flex justify-content-center my-2">
            <div class="fw-bold fs-3 text-center" style="color: #00386D">
              {{ config?.title }}
            </div>
          </div>

          <div
            *ngIf="showAlertMessageTrigger"
            [ngClass]="{ anim: showAlertMessageTrigger }"
            [class.alert-success]="getMessageType() === AlertType.success"
            [class.alert-danger]="getMessageType() === AlertType.error"
            [class.alert-primary]="getMessageType() === AlertType.primary"
            [class.alert-info]="getMessageType() === AlertType.info"
            class="alert border-0 rounded-2 fs-14 text-center fw-bold"
          >
            {{ getAlertMessage() }}
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="mt-3">
                <lib-scan-help-section></lib-scan-help-section>
              </div>
            </div>

            <div class="col-md-6">
              <div>
                <lib-scan-single-fingerprint (fingerprintEvent)="getFingerCaptureEvent($event)"
                                             (shuffle)="shuffle()"
                                             #singleFingerprint></lib-scan-single-fingerprint>

                <div class="d-flex justify-content-between gap-3">
                  <button *ngIf="false" class="btn py-3 w-100 btn-secondary mt-3">
                    Cancel
                  </button>

                  <button [disabled]="_disableLoginButton" (click)="login()" *ngIf="showLoginButton"
                          class="btn py-3 w-100 btn-primary mt-3"
                          style="background: #1551AA;">
                    Verify and Generate Code
                  </button>

                  <button (click)="retry()" *ngIf="showRetryButton"
                          class="btn py-3 w-100 btn-primary mt-3"
                          style="background: #1551AA;">
                    Try again
                  </button>
                </div>


              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>


