/**
 * Jerry Service API
 * The Jerry RESTful service using springdoc and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PayloadCipherPojo } from './payload-cipher-pojo';


export interface FingerData { 
    position?: number;
    quality?: number;
    fingerType?: FingerData.FingerTypeEnum;
    encryptedData?: PayloadCipherPojo;
}
export namespace FingerData {
    export type FingerTypeEnum = 'RIGHT_THUMB' | 'RIGHT_INDEX' | 'RIGHT_MIDDLE' | 'RIGHT_RING' | 'RIGHT_LITTLE' | 'LEFT_THUMB' | 'LEFT_INDEX' | 'LEFT_MIDDLE' | 'LEFT_RING' | 'LEFT_LITTLE' | 'LEFT_HAND' | 'RIGHT_HAND' | 'THUMBS';
    export const FingerTypeEnum = {
        RIGHT_THUMB: 'RIGHT_THUMB' as FingerTypeEnum,
        RIGHT_INDEX: 'RIGHT_INDEX' as FingerTypeEnum,
        RIGHT_MIDDLE: 'RIGHT_MIDDLE' as FingerTypeEnum,
        RIGHT_RING: 'RIGHT_RING' as FingerTypeEnum,
        RIGHT_LITTLE: 'RIGHT_LITTLE' as FingerTypeEnum,
        LEFT_THUMB: 'LEFT_THUMB' as FingerTypeEnum,
        LEFT_INDEX: 'LEFT_INDEX' as FingerTypeEnum,
        LEFT_MIDDLE: 'LEFT_MIDDLE' as FingerTypeEnum,
        LEFT_RING: 'LEFT_RING' as FingerTypeEnum,
        LEFT_LITTLE: 'LEFT_LITTLE' as FingerTypeEnum,
        LEFT_HAND: 'LEFT_HAND' as FingerTypeEnum,
        RIGHT_HAND: 'RIGHT_HAND' as FingerTypeEnum,
        THUMBS: 'THUMBS' as FingerTypeEnum
    };
}


