/**
 * Jerry Auth Provider Service API
 * The Jerry Auth Provider RESTFUL service using springdoc and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: devops@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PayloadCipherPojo } from './payload-cipher-pojo';


export interface FingerPrintDataDto { 
    payloadCipher?: PayloadCipherPojo;
    biometricSubtype?: FingerPrintDataDto.BiometricSubtypeEnum;
}
export namespace FingerPrintDataDto {
    export type BiometricSubtypeEnum = 'RIGHT_THUMB' | 'RIGHT_INDEX' | 'RIGHT_MIDDLE' | 'RIGHT_RING' | 'RIGHT_LITTLE' | 'LEFT_THUMB' | 'LEFT_INDEX' | 'LEFT_MIDDLE' | 'LEFT_RING' | 'LEFT_LITTLE';
    export const BiometricSubtypeEnum = {
        RightThumb: 'RIGHT_THUMB' as BiometricSubtypeEnum,
        RightIndex: 'RIGHT_INDEX' as BiometricSubtypeEnum,
        RightMiddle: 'RIGHT_MIDDLE' as BiometricSubtypeEnum,
        RightRing: 'RIGHT_RING' as BiometricSubtypeEnum,
        RightLittle: 'RIGHT_LITTLE' as BiometricSubtypeEnum,
        LeftThumb: 'LEFT_THUMB' as BiometricSubtypeEnum,
        LeftIndex: 'LEFT_INDEX' as BiometricSubtypeEnum,
        LeftMiddle: 'LEFT_MIDDLE' as BiometricSubtypeEnum,
        LeftRing: 'LEFT_RING' as BiometricSubtypeEnum,
        LeftLittle: 'LEFT_LITTLE' as BiometricSubtypeEnum
    };
}


