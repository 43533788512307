import {APP_INITIALIZER, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {KeycloakAngularModule, KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {LottieModule} from 'ngx-lottie';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PopoverModule} from 'ngx-bootstrap/popover';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ForbiddenComponent} from "./modules/extranet/error-pages/forbidden/forbidden.component";
import {NotFoundComponent} from './modules/extranet/error-pages/not-found/not-found.component';
import {SiteHeaderComponent} from './modules/layout/site-header/site-header.component';
import {SiteFooterComponent} from './modules/layout/site-footer/site-footer.component';
import {DatePipe} from "@angular/common";
import {LandingPageComponent} from './modules/extranet/landing-page/landing-page.component';
import {SharedModule} from './shared/shared.module';
import {IntranetSideHeaderComponent} from "./modules/layout/intranet-site-header/intranet-side-header.component";
import {environment} from "../environments/environment";
import {AuditTrailControllerService, BASE_PATH} from "../../sdk/hclone-api-sdk";
import {AuthenticationService} from "./shared/authentication/authentication-service.service";
import {HttpInterceptorService} from "./shared/authentication/http-interceptor.service";
import {CUSTOM_ERROR_MESSAGES, ErrorMessage, NgBootstrapFormValidationModule} from "ng-bootstrap-form-validation";
import {LicenseUploadComponent} from './modules/extranet/license-upload/license-upload.component';
import {AlertModule} from "ngx-bootstrap/alert";
import {TwoFactorComponent} from './modules/extranet/two-factor/two-factor.component';
import {FingerprintAuthModule as fAuth1} from "./modules/extranet/fingerprint-auth/fingerprint-auth.module";
import {FingerprintAuthModule as fAuth2} from "./modules/intranet/consent-code-generation/fingerprint-auth/fingerprint-auth.module";
import { ApiGuideComponent } from './modules/extranet/api-guide/api-guide.component';
import { FaceApiDocComponent } from './modules/extranet/api-doc/face-api-doc/face-api-doc.component';
import { FingerprintApiDocComponent } from './modules/extranet/api-doc/fingerprint-api-doc/fingerprint-api-doc.component';
import {ConsentCodeGenerationModule} from "./modules/intranet/consent-code-generation/consent-code-generation.module";

function initializeKeycloak(keycloak: KeycloakService,
                            authenticationService: AuthenticationService,
                            loggerService: AuditTrailControllerService) {
  keycloak.keycloakEvents$.subscribe(async value => {
    if (value.type === KeycloakEventType.OnReady) {

    } else if (value.type === KeycloakEventType.OnAuthSuccess) {
      await authenticationService.fetchUser().toPromise();
    }
  });
  return () =>
    keycloak.init({
      config: environment.keycloakConfig,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      }
    });
}
export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

export const CUSTOM_ERRORS: ErrorMessage[] = [
  {
    error: 'dataExists',
    format: label => `${label} already exists.`
  },
  {
    error: 'nigerianNumber',
    format: label => `${label} not a valid nigerian number.`
  },
  {
    error: 'whitespace',
    format: label => `${label} cannot be an empty space`
  },
  {
    error: 'invalidEmail',
    format: label => `This ${label} does not exist`
  },
  {
    error: 'networkError',
    format: label => `${label} cannot validated at the moment. Check Network`
  },
  {
    error: 'emailExists',
    format: label => `${label} already exists.`
  },
  {
    error: 'phoneNumberExists',
    format: label => `${label} already exists.`
  },
  {
    error: 'nameExists',
    format: label => `The combination of Company Name and Branch Name already exists.`
  },
];

@NgModule({
  declarations: [AppComponent, ForbiddenComponent, SiteHeaderComponent, SiteFooterComponent, IntranetSideHeaderComponent, NotFoundComponent, LandingPageComponent, LicenseUploadComponent, TwoFactorComponent, ApiGuideComponent, FaceApiDocComponent, FingerprintApiDocComponent],
  imports: [
    AppRoutingModule, BrowserModule,
    KeycloakAngularModule, HttpClientModule, FormsModule, ReactiveFormsModule,
    BrowserAnimationsModule,
    NgBootstrapFormValidationModule.forRoot(),
    SharedModule,
    LottieModule.forRoot({player: playerFactory}),
    PopoverModule.forRoot(),
    TooltipModule.forRoot(), AlertModule,
    fAuth1.forRoot(),
    fAuth2.forRoot({title:''})
  ],
  providers: [
    DatePipe,
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
    {
      provide: CUSTOM_ERROR_MESSAGES,
      useValue: CUSTOM_ERRORS,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthenticationService, AuditTrailControllerService]
    },
    {
      provide: BASE_PATH,
      useValue: environment.apiBaseUrl
    }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
