/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccountMembership } from './portal-account-membership';
import { PortalUser } from './portal-user';


export interface PortalAccount { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: PortalAccount.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    name?: string;
    type?: PortalAccount.TypeEnum;
    code?: string;
    portalAccountMembershipCollection?: Array<PortalAccountMembership>;
}
export namespace PortalAccount {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum
    };
    export type TypeEnum = 'HNODE' | 'SYSTEM_SUPPORT' | 'AUTHENTICATION_SERVICE_AGENT';
    export const TypeEnum = {
        Hnode: 'HNODE' as TypeEnum,
        SystemSupport: 'SYSTEM_SUPPORT' as TypeEnum,
        AuthenticationServiceAgent: 'AUTHENTICATION_SERVICE_AGENT' as TypeEnum
    };
}


