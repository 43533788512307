import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {FormBuilder, Validators} from "@angular/forms";
import {ConsentRegistrationControllerService} from "../../../../../../sdk/hclone-api-sdk";
import {HelperService} from "../../services/helper.service";
import {AlertType} from "../fingerprint-auth/variables";

@Component({
  selector: 'app-share-consent-dialog',
  templateUrl: './share-consent-dialog.component.html',
  styleUrls: ['./share-consent-dialog.component.css']
})
export class ShareConsentDialogComponent implements OnInit {

  @Input()
  consentCode: string;

  @Output()
  success: EventEmitter<any> = new EventEmitter();

  form = this.fb.group({
    emailAddress: ['',
      Validators.compose([
        Validators.required,
        Validators.email
      ])],
    consentCode: ['',
      Validators.compose([
        Validators.required
      ])]
  })

  isSharing = false;

  constructor(private bsModalRef: BsModalRef,
              private fb: FormBuilder,
              private consentService: ConsentRegistrationControllerService,
              protected helperService: HelperService) {
  }

  ngOnInit(): void {
    this.updateFormValue();
  }

  updateFormValue() {
    this.form.controls.consentCode.setValue(this.consentCode)
  }

  close() {
    this.bsModalRef.hide();
  }

  shareConsentSlip(): void {
    if (this.form.invalid) {
      this.helperService.getInvalidFormFields(this.form);
      return;
    }

    this.isSharing = true;

    const form = this.form.getRawValue();

    this.consentService.shareConsentSlip({
      consentCode: form.consentCode,
      email: form.emailAddress,
    }).subscribe({
      next: (data) => {
        this.isSharing = false;
        this.success.emit()
      },
      error: (error) => {
        this.showAlertMessage(this.helperService.getError(error), AlertType.error);
        this.isSharing = false;
      }
    });
  }

  /////////////////////
  //////ALERT
  /////////////////////
  private alertMessage: { msg: string; type: AlertType } = {
    msg: '',
    type: AlertType.primary
  }
  showAlertMessageTrigger = false;

  showAlertMessage(msg: any, type: AlertType): void {
    this.alertMessage.msg = msg;
    this.alertMessage.type = type;
    this.showAlertMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showAlertMessageTrigger = false;
    }, 10000);
  }

  getAlertMessage(): string {
    return this.alertMessage.msg;
  }

  getMessageType(): AlertType {
    return this.alertMessage.type;
  }

  protected readonly AlertType = AlertType;

  /////////////////////
  //////END ALERT
  /////////////////////
}
