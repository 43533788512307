import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {FingerprintPluginHandler} from "../fingerprint-auth/handlers/fingerprint-plugin-handler";
import {FingerprintScanService} from "../fingerprint-auth/services/fingerprint-scan.service";
import {FingerprintScanConfigService} from "../fingerprint-auth/services/fingerprint-scan-config.service";
import {AlertType, FINGERPRINT_PLUGIN_HANDLER} from "../fingerprint-auth/variables";
import {
  FingerPrintControllerService, FingerPrintDataDto,
  FingerprintVerificationErrorResponse, ProcessFingerPrintRequestDto,
  RandomFingerGenerationPojo
} from "../../../../../../sdk/provider-api-sdk";
import {FingerprintConfig} from "../../../extranet/fingerprint-auth/services/fingerprint-scan-config.service";
import {
  DeviceConnectionResponse,
  DeviceControllerService,
  FingerData,
  FingerprintControllerService
} from "../../../../../../sdk/hvasdevice-sdk";
import DeviceTypeEnum = DeviceConnectionResponse.DeviceTypeEnum;
import {environment} from "../../../../../environments/environment";
import {AuditTrailControllerService, AuditTrailDto} from "../../../../../../sdk/hclone-api-sdk";
import {HelperService} from "../../services/helper.service";
import {Constant} from "../../../../models/constant/Constant";

@Component({
  selector: 'app-customer-biometric-verification',
  templateUrl: './customer-biometric-verification.component.html',
  styleUrls: ['./customer-biometric-verification.component.css'],
  providers: [
    {
      provide: FINGERPRINT_PLUGIN_HANDLER,
      useClass: CustomerBiometricVerificationComponent
    }
  ]

})
export class CustomerBiometricVerificationComponent implements OnInit, AfterViewInit, FingerprintPluginHandler {

  @Output()
  back: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  loginEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  retryEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  biometricData: EventEmitter<any> = new EventEmitter();

  title = 'Biometric Verification';

  config: RandomFingerGenerationPojo

  //use in place of config.numberOfPrintsToScan to override what is returned
  numberOfPrintsToScan = 1;

  fingerprintScanResults: any[] = [];

  trialAttempt = 0;

  trialAttemptLeft = 3;

  updated = false;

  isFingerConnected = false;

  isPluginDetected = false;

  loadingConfig = false;

  loginButtonDisabled$: Subject<boolean> = new Subject<boolean>();

  bypassButtonShown$: Subject<boolean> = new Subject<boolean>();

  constructor(private fingerprintScanConfigService: FingerprintScanConfigService,
              private fingerprintScanService: FingerprintScanService,
              private providerAuthService: FingerPrintControllerService,
              private deviceController: DeviceControllerService,
              private deviceService: FingerprintControllerService,
              private loggerService: AuditTrailControllerService,
              protected helperService: HelperService) {
  }

  ngOnInit(): void {
    this.fetchConfig();
  }

  startScan(p: { finger: string; }): Observable<any> {

    return this.deviceService.startAcquisition({
      finger: p.finger as FingerData.FingerTypeEnum,
      scannerType: 'OTHER_SUPPORTED'
    })
  }

  stopScan(): Observable<any> {
    return null;
  }

  getScanResult(p: { sessionId: string; }): Observable<any> {
    return this.deviceService.getCaptureResponse({
      fasProviderId: environment?.fasProviderId,
      sessionId: p.sessionId
    });

  }

  sendAcknowledgementSuccess(p: { sessionId: string; }): Observable<any> {
    return this.deviceService.acknowledgeCaptureResponse({sessionId: p.sessionId});
  }

  sendAcknowledgementFailure(p: { sessionId: string; }): Observable<any> {
    return this.deviceService.acknowledgeCaptureResponse({sessionId: p.sessionId});
  }

  getFingerCaptureEvent($event: any) {
    if (!$event) return;
    this.addFingerPrintResult($event)
  }

  addFingerPrintResult(fingerData: FingerData): void {

    if (this.fingerprintScanResults?.length < this.numberOfPrintsToScan) {
      this.fingerprintScanResults.push(fingerData);
    }

    if (this.fingerprintScanResults?.length == this.numberOfPrintsToScan) {
      this.showLoginButton();

    } else {
      this.fetchConfig();
    }
  }

  // login() {
  //   this.loginEvent.emit();
  // }


  shuffle(): void {
    if (this.updated && !this.loadingConfig && this.fingerprintScanResults.length == 0)
      this.fetchConfig();
  }

  retry(): void {
    this.retryEvent.emit();
  }

  bypass() {

  }

  /**
   * Get Disable observable of Login button
   */
  get loginButtonDisabled(): Observable<boolean> {
    return this.loginButtonDisabled$.asObservable();
  }

  goBack() {
    this.back.emit();
  }

  //call biometric login
  login(): void {

    this.loginButtonDisabled$.next(true);
    const payload = this.getAuthPayload();
    this.providerAuthService
      .processFingerPrintData({processFingerPrintRequestDto: payload})
      .subscribe({
        next: (response) => {
          const success = response.fingerprintVerificationResponse?.isVerified;
          this.loginButtonDisabled$.next(false);
          if (success) {
            this.loginSuccess()
          } else {
            this.loginFailure()
            const errorResponse = response?.fingerprintVerificationErrorResponse;
            const isReplay = errorResponse != undefined
              && errorResponse?.attemptedAttackTypeConstant == FingerprintVerificationErrorResponse.AttemptedAttackTypeConstantEnum.Replay
            this.loggerService.logLoginAction({
              auditTrailDto: {
                authenticationMethodConstant: "FINGERPRINT",
                eventType: AuditTrailDto.EventTypeEnum.BiometricLogin,
                resourceUri: window.location.href,
                eventStatus: "FAILED",
                description: isReplay ? response?.message : "Login attempt failed for fingerprint authentication",
                attemptedAttack: isReplay
              }
            }).subscribe();
          }
        },
        error: (error: unknown) => {
          this.loginButtonDisabled$.next(false);
          this.loggerService.logLoginAction({
            auditTrailDto: {
              authenticationMethodConstant: "FINGERPRINT",
              resourceUri: window.location.href,
              eventType: AuditTrailDto.EventTypeEnum.BiometricLogin,
              eventStatus: "FAILED",
              description: "Login attempt failed for fingerprint authentication"
            }
          }).subscribe();
          this.fingerprintScanService.showAlertMessage.emit({
            msg: this.helperService.getError(error),
            type: AlertType.error
          });
        }
      })
  }

  loginSuccess(): void {
    this.loginEvent.emit()
  }

  loginFailure(): void {
    this.fingerprintScanService.clearCounter();
    this.fingerprintScanResults = [];
    this.trialAttempt++;
    this.trialAttemptLeft--;
    this.fingerprintScanService.trialAttempt = this.trialAttempt;
    this.fingerprintScanService.trialAttemptLeft = this.trialAttemptLeft;

    const trials = (this.trialAttemptLeft + this.trialAttempt);

    if (this.trialAttempt == (trials || 1)) {
      this.fingerprintScanService.showAlertMessage.emit({
        msg: 'Fingerprint and NIN do not match. Please check your NIN or place your finger correctly.',
        type: AlertType.error,
        persist: true
      });
      this.loginButtonDisabled$.next(true);
      this.showRetryButton()
      return;
    }

    this.showScanButton()

    this.fingerprintScanService.showAlertMessage.emit({
      msg: 'Fingerprint and NIN do not match. Please check your NIN or place your finger correctly.',
      type: AlertType.error
    });
    this.fetchConfig();
  }


  getAuthPayload(): ProcessFingerPrintRequestDto {

    return {
      fingerPrintDataDtoList: this.fingerprintScanResults
        .map((val): FingerPrintDataDto => {
          return {
            payloadCipher: {
              cipheredPayloadBase64: val?.encryptedData?.cipheredPayloadBase64,
              cipheredAESKeyBase64: val?.encryptedData?.cipheredAESKeyBase64
            },
            biometricSubtype: val?.fingerType
          }
        }),
      deviceId: this.fingerprintScanResults[0]?.deviceId

    };
  }

  fetchConfig(): void {
    this.loadingConfig = true;
    this.providerAuthService
      .generateRandomFingerSelectionForAcquisition()
      .subscribe({
        next: (response: RandomFingerGenerationPojo) => {
          this.loadingConfig = false;
          this.loadFingerprintScanConfig(response);
          this.config = response;

          if (this.trialAttemptLeft == 0) {
            this.showLoginButton();
            this.loginButtonDisabled$.next(true);

          } else {
            this.setFingerToScan(response.fingerToScan);
          }
        },
        error: (error: unknown) => {
          this.loadingConfig = false;
          // this.showAlertMessage(this.getError(error), AlertType.error)
          setTimeout(() => {
              this.fetchConfig();
            },
            500)
        }
      })
  }


  showLoginButton(): void {
    this.fingerprintScanService.setAction = 'LOGIN';
  }

  showScanButton(): void {
    this.fingerprintScanService.setAction = 'SCAN';
  }

  showRetryButton(): void {
    this.fingerprintScanService.setAction = 'RETRY';
  }

  setFingerToScan(finger: number): void {
    this.fingerprintScanService.fingerprintToScan = finger;

    if (this.fingerprintScanResults?.length > 0) {
      this.fingerprintScanService.startScan();
    }
  }

  loadFingerprintScanConfig(configPojo: RandomFingerGenerationPojo): void {
    const config: FingerprintConfig = {
      fingerToScan: configPojo?.fingerToScan,
      nfiQuality: configPojo?.nfiQuality,
      numberOfPrintsToScan: configPojo?.numberOfPrintsToScan,
      numberOfTrials: configPojo?.numberOfTrials,
      numberOfTrialsLeft: configPojo?.numberOfTrialsLeft,
    }

    this.fingerprintScanConfigService
      .loadConfig(config);

    if (!this.updated) {
      this.trialAttempt = 0; //config?.numberOfTrials; // override number of trials returned
      this.trialAttemptLeft = 1;// config?.numberOfTrialsLeft; // override number of trial attempts left

      this.fingerprintScanService.trialAttempt = this.trialAttempt;
      this.fingerprintScanService.trialAttemptLeft = this.trialAttemptLeft;
      this.updated = true;
    }

  }

  getConnectedDevices() {
    this.deviceController.getAllConnectedDevices({scannerType: 'OTHER_SUPPORTED'}).subscribe((res) => {
      let gps, signature, fingerprint = false
      res.forEach(v => {
        switch (v.deviceType) {
          case DeviceTypeEnum.FINGERPRINT_SCANNER:
            fingerprint = v.connected;
            break;
        }
      });
      this.isFingerConnected = fingerprint;
      this.isPluginDetected = true;
      this.fingerprintScanService.fpConnectionStatus$.next(this.isFingerConnected);
      this.fingerprintScanService.pluginDetectionStatus$.next(this.isPluginDetected);
    }, error => {
      this.isFingerConnected = false;
      this.isPluginDetected = false;
      this.fingerprintScanService.fpConnectionStatus$.next(this.isFingerConnected);
      this.fingerprintScanService.pluginDetectionStatus$.next(this.isPluginDetected);
    });
  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      this.getConnectedDevices();

      // Run again after every x seconds interval
      setInterval(() => this.getConnectedDevices(), 20000);
    }, 1000)

  }
}
