import { ViewportScroller } from '@angular/common';
import { Component,HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-api-guide',
  templateUrl: './api-guide.component.html',
  styleUrls: ['./api-guide.component.css']
})
export class ApiGuideComponent implements OnInit {

  @HostListener('window:scroll', ['$event'])
  onScroll(e:any) {
    let siteFooterCood = document.querySelector('footer.site_footer')?.getBoundingClientRect();
    let sfcTop = siteFooterCood?.top ? siteFooterCood?.top : 0;

    // this.asidePosition = {
    //   top: sfcTop < window.innerHeight ? 210 - ( window.innerHeight - sfcTop) : 210,
    //   bottom: sfcTop < window.innerHeight ? sfcTop : 0,
    // }

    try {
      let sectionLinks = document.querySelectorAll('.nav-link');

      sectionLinks.forEach((e:any) => {
        if (this.isElementInViewport(document.getElementById(e.getAttribute('data-target-id') || ''))) {
          this.fragment = e;
          throw 'Break Exception'
        }
      })
    } catch (error) {
      // console.log(error);

    }
  }


  env:any = environment;


  sample_message:string = `[
    {
        "serviceName" : "Enrollment Service",
        "serviceCode" : "001",
        "serviceItem" : [
            {
                "name": "Enrolment - Data Capture",
                "code" : "001-001",
                "status": "active | inactive"
            },
            {
                "name": "Enrolment and NIN Issuance - Persons 16 yrs and above",
                "code" : "001-002",
                "status": "active | inactive"
            },
            ...
        ],
        "status" : "active | inactive"
    },
    ...
]
`
  comment_2:string = `[
    {
        "code": "NM0089",
        "jurisdiction", "local | diaspora_african | diaspora_non_african",
        "status": "active | inactive"
    },
]
`

  sample_message_2:string = `[
    {
      “customerName” : “Verify Technologies”
      “customerId” : “N438903”,
      “account” : [
          {
              "code": "NM0089",
              "jurisdiction", "local | diaspora_african | diaspora_non_african",
              "status": "active | inactive"
          },
          …
      ],
      “phoneNumber” : “08134899908”
      “email” : “admin@verifytech.com”
      “customerType” : “organisation”,
      “organisationType” : “corporate”,
      “registrationNumber” : “RC09293”,
      “sector” : “private”,
      “streetAddress” : “1 Gloucester Road, Wuse”,
      “addressLine2” : “”
      “postalCode” : “900201”,
      “townOrCity” : “Abuja”,
      “state” : “FCT”,
      “country” : “Nigeria”,
      “status” : “active”

    },
        …
]
`
sample_message_3:string = `[
  {
    “serviceItemName” : “Person Verification Service - Level Two”,
    “serviceItemCode” : “002-002”,
    “customerAccountCode” : "NM0089",
    “tpsServiceTransactionId” : “sf2425345tertet893”,
    “reasonForService” : “kyc”,
    “tpsSystemCode” : “mws”,
    “tpsTransactionTime” : “2022-10-09 14:34:32.345”,
    “tpsTransactionType” : “verify_id”,
    “nin” : “12009444787”,
    “ninToken” : “1738994400557389”
  }
]
`

sample_message_4:string = `[
  {
    “code” : “1001”,
    “message” : “Invalid service item code [045-930]”,
    “tpsServiceTransactionId” : “sf2425345tertet893”
  }
]
`
sample_message_5:string = `[
  {
    “customerAccountCode” : “NM0089”,
    “tpsServiceTransactionId” : “sf2425345tertet893”,
    “billingBlockchainReference” : “adf23442sdwfs454lklldgwwd4”,
    “billingFulfillmentTime” : “2022-10-09 14:34:32.345”,
  }
]
`

sample_message_6:string = `[
  {
    “customerId” : “234311321”,
    “customerAccountCode” : “NM0089”,
    “reason” : “Past due obligation”,
    “billingRequestId” : “adf23442sdwwer44lklldgwwd4”,
    “billingRequestTime” : “2022-10-09 14:34:32.345”,
    “billingRequestType” : “severe”,
}
]
`
sample_message_7:string = `[
  {
    “billingRequestId” : “adf23442sdwwer44lklldgwwd4”,
    “customerAccountCode” : “NM0089”,
    “tpsSystemCode” : “hclone”,
    “requestCompletionTime” : “2022-10-09 14:34:32.345”
  }
]
`

comment_3:string = `[
  {
    "name" : "Enrolment - Data Capture",
    "code" : "001-001",
    "status": "active | inactive"
  }
]
`

payload:string = `[
  {
    "biographicData": {
        "firstName": "John",
        "lastName": "Doo",
        "dateOfBirth": "1985-11-30",
        "gender": "M",
        "nationality": "FRA",
        "...": "..."
    },
    "biometricData": [
        {
            "biometricType": "FINGER",
            "biometricSubType": "RIGHT_INDEX",
            "instance": "string",
            "image": "c3RyaW5n",
            "imageRef": "http://imageserver.com/image?id=00003",
            "captureDate": "2019-05-21T12:00:00Z",
            "captureDevice": "string",
            "impressionType": "LIVE_SCAN_PLAIN",
            "width": 1,
            "height": 1,
            "bitdepth": 1,
            "mimeType": "string",
            "resolution": 1,
            "compression": "WSQ",
            "missing": [
                {
                    "biometricSubType": "RIGHT_INDEX",
                    "presence": "BANDAGED"
                }
            ],
            "metadata": "string",
            "comment": "string",
            "template": "c3RyaW5n",
            "templateRef": "http://dataserver.com/template?id=00014",
            "templateFormat": "string",
            "quality": 1,
            "qualityFormat": "string",
            "algorithm": "string",
            "vendor": "string"
        }
    ],
    "credentialData": [
        {
            "credentialNumber": "string",
            "personId": "string",
            "credentialType": "ID_CARD",
            "issuedDate": "2022-01-03T16:14:04.461348",
            "expiryDate": "2022-01-03T16:14:04.461348",
            "serialNumber": "string",
            "issuingAuthority": "string",
            "issuingPlace": "string",
            "others": {
                "...": "..."
            }
        }
    ],
    "contactData": {
        "email": "John.Doo@osia.com",
        "phone1": "555666777",
        "phone2": "555888999",
        "...": "..."
    }
  }
]
`




attribute_request: string = `POST \n/v1/attribute/{identifier}?identifierType={token}\n&transactionId={transactionId} \nHTTP/1.1`
attribute_result: string = `GET \nv1/verify/tasks/{taskId}?transactionId={transactionId} \nHTTP/1.1`
verify_request: string = `POST \n/v1/verify/{identifier}?\nidentifierType={token}&verificationProofRequired=\nFalse&transactionId=string \nHTTP/1.1`
verify_result: string = `GET \nv1/attribute/tasks/{taskId}?transactionId=\n{transactionId} \nHTTP/1.1`



verify_response_2:string = `[
  {
    "verificationCode": 1,
    "verificationMessage": "string",
    "verificationProof": "string"
  }
]
`

sample_message_face:string = `[
  {
    "biographicData": {
        "firstName": "John",
        "lastName": "Doo",
        "dateOfBirth": "1985-11-30",
        "gender": "M",
        "nationality": "FRA",
        "...": "..."
    },
    "biometricData": [
        {
            "biometricType": "FACE",
            "biometricSubType": "POTRAIT",
            "instance": "string",
            "image": "c3RyaW5n",
            "imageRef": "http://imageserver.com/image?id=00003",
            "captureDate": "2019-05-21T12:00:00Z",
            "captureDevice": "string",
            "impressionType": "LIVE_SCAN_PLAIN",
            "width": 1,
            "height": 1,
            "bitdepth": 1,
            "mimeType": "string",
            "resolution": 1,
            "compression": "PNG",
            "metadata": "string",
            "comment": "string",
            "template": "c3RyaW5n",
            "templateRef": "http://dataserver.com/template?id=00014",
            "templateFormat": "string",
            "quality": 1,
            "qualityFormat": "string",
            "algorithm": "string",
            "vendor": "string"
        }
    ],
    "credentialData": [
        {
            "credentialNumber": "string",
            "personId": "string",
            "credentialType": "ID_CARD",
            "issuedDate": "2022-01-03T16:14:04.461348",
            "expiryDate": "2022-01-03T16:14:04.461348",
            "serialNumber": "string",
            "issuingAuthority": "string",
            "issuingPlace": "string",
            "others": {
                "...": "..."
            }
        }
    ],
    "contactData": {
        "email": "John.Doo@osia.com",
        "phone1": "555666777",
        "phone2": "555888999",
        "...": "..."
    }
  }
]
`

sample_payload:string = `[
  {
    "biographicData": {
        "firstName": "John",
        "lastName": "Doo",
        "dateOfBirth": "1985-11-30",
        "gender": "M",
        "nationality": "FRA",
        "...": "..."
    },
    "biometricData": [
        {
            "biometricType": "FINGER",
            "biometricSubType": "RIGHT_INDEX",
            "instance": "string",
            "image": "c3RyaW5n",
            "imageRef": "http://imageserver.com/image?id=00003",
            "captureDate": "2019-05-21T12:00:00Z",
            "captureDevice": "string",
            "impressionType": "LIVE_SCAN_PLAIN",
            "width": 1,
            "height": 1,
            "bitdepth": 1,
            "mimeType": "string",
            "resolution": 1,
            "compression": "WSQ",
            "missing": [
                {
                    "biometricSubType": "RIGHT_INDEX",
                    "presence": "BANDAGED"
                }
            ],
            "metadata": "string",
            "comment": "string",
            "template": "c3RyaW5n",
            "templateRef": "http://dataserver.com/template?id=00014",
            "templateFormat": "string",
            "quality": 1,
            "qualityFormat": "string",
            "algorithm": "string",
            "vendor": "string"
        }
    ],
    "credentialData": [
        {
            "credentialNumber": "string",
            "personId": "string",
            "credentialType": "ID_CARD",
            "issuedDate": "2022-01-03T16:14:04.461348",
            "expiryDate": "2022-01-03T16:14:04.461348",
            "serialNumber": "string",
            "issuingAuthority": "string",
            "issuingPlace": "string",
            "others": {
                "...": "..."
            }
        }
    ],
    "contactData": {
        "email": "John.Doo@osia.com",
        "phone1": "555666777",
        "phone2": "555888999",
        "...": "..."
    }
  }
]
`
sample_payload_2:string = `      
{
  "outputBiographicData": [
      "title",
      "firstName",
      "lastName",
      "middleName",
      "otherName",
      "maidenName",
      "previousFirstName",
      "previousLastName",
      "previousMiddleName",
      "dateOfBirth",
      "gender",
      "birthCountry",
      "birthState",
      "birthLga",
      "maritalStatus",
      "height",
      "residenceStatus",
      "religion",
      "email",
      "phone1",
      "nativeSpokenLanguage",
      "otherSpokenLanguage",
      "readWriteLanguage",
      "educationLevel",
      "profession",
      "employmentStatus",
      "nationality",
      "originPlace",
      "originLga",
      "originState",
      "originCountry",
      "residenceAddressLine1",
      "residenceAddressLine2",
      "residenceTown",
      "residenceLga",
      "residenceState",
      "residenceCountry",
      "nextOfKinSurname",
      "nextOfKinFirstName",
      "nextOfKinMiddleName",
      "nextOfKinAddressLine1",
      "nextOfKinAddressLine2",
      "nextOfKinPostCode",
      "nextOfKinTown",
      "nextOfKinLga",
      "nextOfKinRelation",
      "nextOfKinCountry"
  ],
  "outputBiometricData": [
      {
          "biometricType": "FACE"
      }
  ],
  "outputContactData": [
      "email",
      "phone1"
  ]
}
`
payload_2:string = `
{
  "outputBiographicData": [
      "title",
      "firstName",
      "lastName",
      "middleName",
      "otherName",
      "maidenName",
      "previousFirstName",
      "previousLastName",
      "previousMiddleName",
      "dateOfBirth",
      "gender",
      "birthCountry",
      "birthState",
      "birthLga",
      "maritalStatus",
      "height",
      "residenceStatus",
      "religion",
      "email",
      "phone1",
      "nativeSpokenLanguage",
      "otherSpokenLanguage",
      "readWriteLanguage",
      "educationLevel",
      "profession",
      "employmentStatus",
      "nationality",
      "originPlace",
      "originLga",
      "originState",
      "originCountry",
      "residenceAddressLine1",
      "residenceAddressLine2",
      "residenceTown",
      "residenceLga",
      "residenceState",
      "residenceCountry",
      "nextOfKinSurname",
      "nextOfKinFirstName",
      "nextOfKinMiddleName",
      "nextOfKinAddressLine1",
      "nextOfKinAddressLine2",
      "nextOfKinPostCode",
      "nextOfKinTown",
      "nextOfKinLga",
      "nextOfKinRelation",
      "nextOfKinCountry"
  ],
  "outputBiometricData": [
      {
          "biometricType": "FACE"
      }
  ],
  "outputContactData": [
      "email",
      "phone1"
  ]
}
`

callback_response:string = `[
  {
    "biographicData": {
    "title": "string",
    "firstName": "string",
    "lastName": "string",
    "middleName": "string",
    "otherName": "string",
    "maidenName": "string",
    "previousFirstName": "string",
    "previousMiddleName": "string",
    "previousLastName": "string",
    "dateOfBirth": "string",
    "gender": "string",
    "birthCountry": "string",
    "birthState": "string",
    "birthLga": "string",
    "maritalStatus": "string",
    "height": "string",
    "residenceStatus": "string",
    "religion": "string",
    "nativeSpokenLanguage": "string",
    "otherSpokenLanguage": "string",
    "readWriteLanguage": "string",
    "educationLevel": "string",
    "profession": "string",
    "employmentStatus": "string",
    "nationality": "string",
    "residenceLga": "string",
    "residenceState": "string",
    "residenceCountry": "string",
    "originLga": "string",
    "originState": "string",
    "originPlace": "string"
    },
    "biometricData": [
    {}
    ],
    "credentialData": [
    {}
    ],
    "contactData": {
    "email": "string",
    "phone1": "string",
    "phone2": "string"
    }
  }
]
`


x_reason:string = `[
 {
      "name": "TAXATION-ENROLLMENT"
    },
    {
      "name": "TAXATION-ASSESSMENT"
    },
    {
      "name": "TAXATION-ENFORCEMENT"
    },
    {
      "name": "CORPORATE-AFFAIRS-DIRECTOR"
    },
    {
      "name": "CORPORATE-AFFAIRS-SHAREHOLDER"
    },
    {
      "name": "CORPORATE-AFFAIRS-TRUSTEE"
    },
    {
      "name": "MEDICAL"
    },
    {
      "name": "EDUCATION-EXAM"
    },
    {
      "name": "EDUCATION-ADMISSION"
    },
    {
      "name": "EDUCATION-PROMOTION"
    },
    {
      "name": "EMPLOYMENT-RECRUITMENT"
    },
    {
      "name": "EMPLOYMENT-ENROLLMENT"
    },
    {
      "name": "EMPLOYMENT-DISMISSAL"
    },
    {
      "name": "CREDIT-BACKGROUND-CHECK"
    },
    {
      "name": "FINANCIAL-PRODUCTS"
    },
    {
      "name": "INSURANCE"
    },
    {
      "name": "CLEARANCE-FOR-POLITICAL-OFFICE"
    },
    {
      "name": "LEGAL-COURTS"
    },
    {
      "name": "LEGAL-ENROLLMENT"
    },
    {
      "name": "CRIME"
    },
    {
      "name": "PRISON/INCARCERATION"
    },
    {
      "name": "PASSPORT-(IMMIGRATION)"
    },
    {
      "name": "PENSION-ENROLLMENT"
    },
    {
      "name": "SOCIO/CULTURAL-ENROLLMENT"
    },
    {
      "name": "ENTERTAINMENT"
    },
    {
      "name": "PHYSICAL-ACCESS"
    },
    {
      "name": "LOGICAL/VIRTUAL-ACCESS"
    },
    {
      "name": "TELECOMMUNICATION-SIM-REG"
    },
    {
      "name": "TELECOMMUNICATION-IOT-REG"
    },
    {
      "name": "AVIATION-TRANSPORT"
    },
    {
      "name": "ROAD-TRANSPORT"
    },
    {
      "name": "MARITIME-TRANSPORT"
    },
    {
      "name": "RAILWAY-TRANSPORT"
    },
    {
      "name": "LEA-CHECK"
    },
    {
      "name": "DEFENCE"
    },
    {
      "name": "NYSC-CHECK"
    },
    {
      "name": "EFCC-CHECK"
    },
    {
      "name": "BLACKLIST-POLITICAL"
    },
    {
      "name": "BLACKLIST-SOCIAL"
    },
    {
      "name": "BLACKLIST-FINANCIAL"
    },
    {
      "name": "BLACKLIST-LEGAL"
    },
    {
      "name": "BLACKLIST-TECHNOLOGICAL"
    },
    {
      "name": "BLACKLIST-ENVIRONMENTAL"
    },
    {
      "name": "BLACKLIST-SOVEREIGN"
    },
    {
      "name": "RELIGIOUS-CHECK"
    },
    {
      "name": "LAND-ASSET-REGISTRATION-PURPOSES"
    }
]
`

  verify_success_response:string = `[
  {
    "taskId": "123e4567-e89b-12d3-a456-426655440000",
    "others": {
        "...": "..."
    }
  }
]`


// verify_success_response_2:string = `[
//   {
//     "verificationCode": 1,
//     "verificationMessage": "string",
//     "verificationProof": "string"
//   }
// ]`


  bad_response: string = `[
  {
    "code": 1,
    "message": "string",
  }
]
`

sample_link_read:string = `https://hcloneuat.mtn.com.ng/api/v1/attribute/tasks/{taskId}?transactionId=
{transactionId}
`

read_person_post:string = `
https://hcloneuat.mtn.com.ng/api/v1/attribute/{identifier}?identifierType={token}&transactionId={transactionId} 
`

finger_post:string = `
https://hcloneuat.mtn.com.ng/api/v1/verify/{identifier}?identifierType={token}&verificationProofRequired=
False&transactionId={string}
`

face_post:string = `
https://hcloneuat.mtn.com.ng/api/v1/verify/{identifier}?identifierType={token}&verificationProofRequired=
False&transactionId=string
`

response_link:string = `
https://hcloneuat.mtn.com.ng/api/v1/verify/tasks/{taskId}?transactionId={transactionId}
`
attribute_success_response:string = `[
  {
    "verificationCode": 1,
    "verificationMessage": "string",
    "verificationProof": "string"
  }
]
`


read_attribute_success_response:string = `[  
{
  "biographicData": {
      "firstName": "John",
      "lastName": "Doo",
      "dateOfBirth": "1985-11-30",
      "gender": "M",
      "nationality": "FRA",
      "...": "..."
  },
  "biometricData": [
      {
          "biometricType": "FINGER",
          "biometricSubType": "RIGHT_INDEX",
          "instance": "string",
          "encounterId": "string",
          "image": "c3RyaW5n",
          "imageRef": "http://imageserver.com/image?id=00003",
          "captureDate": "2019-05-21T12:00:00Z",
          "captureDevice": "string",
          "impressionType": "LIVE_SCAN_PLAIN",
          "width": 1,
          "height": 1,
          "bitdepth": 1,
          "mimeType": "string",
          "resolution": 1,
          "compression": "WSQ",
          "missing": [
              {
                  "biometricSubType": "RIGHT_INDEX",
                  "presence": "BANDAGED"
              }
          ],
          "metadata": "string",
          "comment": "string",
          "template": "c3RyaW5n",
          "templateRef": "http://dataserver.com/template?id=00014",
          "templateFormat": "string",
          "quality": 1,
          "qualityFormat": "string",
          "algorithm": "string",
          "vendor": "string"
      }
  ],
  "credentialData": [
      {
          "credentialId": "string",
          "status": "NEW",
          "statusOther": "string",
          "credentialNumber": "string",
          "personId": "string",
          "credentialType": "ID_CARD",
          "issuedDate": "2022-01-03T16:14:04.461348",
          "expiryDate": "2022-01-03T16:14:04.461348",
          "serialNumber": "string",
          "issuingAuthority": "string",
          "issuingPlace": "string",
          "others": {
              "...": "..."
          }
      }
  ],
  "contactData": {
      "email": "John.Doo@osia.com",
      "phone1": "555666777",
      "phone2": "555888999",
      "...": "..."
  }
}
]
`


  verify_response:string = this.verify_success_response;

  attribute_response:string = this.attribute_success_response;

  read_attribute_response:string = this.read_attribute_success_response;
  isFace: boolean = true;
 

  public fragment: string | null;

  constructor(private route: ActivatedRoute, private scroller: ViewportScroller) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      this.setActive(fragment || '');
    });
    this.scroller.scrollToPosition([0,0]);
    this.isFace = true;
  }



  setActive(section:string){
    this.fragment = section;
    this.scroller.scrollToAnchor(section);
  }

  isElementInViewport(el:HTMLElement | null) {
    if (!el) {

      return false;
    }

    var rect = el.getBoundingClientRect();
    var ih = (window.innerHeight / 5);

    return (
      rect.top <= ih  &&
      ( rect.bottom - 2 <= (rect.height  + ih)  && rect.bottom > ih)
        // rect.top >= 0 ||
        // // rect.left >= 0 &&
        // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */
        // // &&  rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}


  switchVerifyRequestResponses(number: number) {
    switch (number) {
      case 202:
        this.verify_response = this.verify_success_response;
        break;
      default:
        this.verify_response = this.bad_response;
    }
  }


  switchAttributeRequestResponses(number: number) {
    switch (number) {
      case 200:
        this.attribute_response = this.attribute_success_response;
        break;
      default:
        this.attribute_response = this.bad_response;
    }
  }

  switchReadAttributeResponses(number: number) {
    switch (number) {
      case 200:
        this.read_attribute_response = this.read_attribute_success_response;
        break;
      default:
        this.read_attribute_response = this.bad_response;
    }
  }

  async copyCode(code: any) {
    try {
      await navigator.clipboard.writeText(code);
      alert('Code copied to clipboard.');
    } catch (err) {
      console.error('Failed to copy code: ', err);
    }
  }
 
}
