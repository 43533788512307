/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OutputBiographicData { 
    physicalStatus?: string;
    deathVerification?: string;
    reasonForIdentityStatusChange?: string;
    enrolledAt?: string;
    title?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    otherName?: string;
    maidenName?: string;
    previousFirstName?: string;
    previousLastName?: string;
    previousMiddleName?: string;
    dateOfBirth?: string;
    gender?: string;
    birthCountry?: string;
    birthState?: string;
    birthLga?: string;
    maritalStatus?: string;
    height?: string;
    residenceStatus?: string;
    religion?: string;
    nativeSpokenLanguage?: string;
    otherSpokenLanguage?: string;
    readWriteLanguage?: string;
    educationLevel?: string;
    profession?: string;
    employmentStatus?: string;
    nationality?: string;
    originPlace?: string;
    originLga?: string;
    originState?: string;
    residenceAddressLine1?: string;
    residenceAddressLine2?: string;
    residenceTown?: string;
    residenceLga?: string;
    residenceState?: string;
    residentialCountry?: string;
    originCountry?: string;
    nextOfKinSurname?: string;
    nextOfKinFirstName?: string;
    nextOfKinMiddleName?: string;
    nextOfKinAddressLine1?: string;
    nextOfKinAddressLine2?: string;
    nextOfKinPostCode?: string;
    nextOfKinTown?: string;
    nextOfKinLga?: string;
    nextOfKinRelation?: string;
    nextOfKinCountry?: string;
}

