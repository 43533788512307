/**
 * Jerry Service API
 * The Jerry RESTful service using springdoc and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FingerData } from './finger-data';


export interface FingerprintCaptureResponse { 
    statusMessage?: string;
    captureStarted?: boolean;
    captureComplete?: boolean;
    captureSuccessful?: boolean;
    sessionId?: string;
    sessionExpired?: boolean;
    nextAction?: FingerprintCaptureResponse.NextActionEnum;
    deviceId?: string;
    fingers?: Array<FingerData>;
}
export namespace FingerprintCaptureResponse {
    export type NextActionEnum = 'PLACE_FINGER' | 'REMOVE_FINGER' | 'RESTART_CAPTURE' | 'START_NEW_CAPTURE';
    export const NextActionEnum = {
        PLACE_FINGER: 'PLACE_FINGER' as NextActionEnum,
        REMOVE_FINGER: 'REMOVE_FINGER' as NextActionEnum,
        RESTART_CAPTURE: 'RESTART_CAPTURE' as NextActionEnum,
        START_NEW_CAPTURE: 'START_NEW_CAPTURE' as NextActionEnum
    };
}


