import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {KeycloakService} from "keycloak-angular";
import {Router} from "@angular/router";
import {AsyncSubject, from, mergeMap, Observable, of, Subject, tap} from "rxjs";
import {KeycloakLoginOptions} from "keycloak-js";
import {environment} from "../../../environments/environment";
import {
  AccountMembershipPojo,
  AuditTrailControllerService,
  CloneControllerService,
  CloneStatePojo,
  UserControllerService,
  UserPojo
} from "../../../../sdk/hclone-api-sdk";
import {UserSessionManager} from "./user-session-manager";
import {catchError} from "rxjs/operators";
import {Constant} from "../models/constants";
import PermissionsEnum = AccountMembershipPojo.PermissionsEnum;
import {PageManager} from "../misc/page-manager";
import {readableStreamLikeToAsyncGenerator} from "rxjs/internal/util/isReadableStreamLike";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private static user: Subject<UserPojo | null> = new Subject();
  public static _cloneState: CloneStatePojo;
  public static _user: UserPojo;
  private static cloneState: Subject<CloneStatePojo | null> = new Subject();
  private static ongoingFetch: Observable<any> | null;
  private static initialized: boolean;
  private static cloneStateInitialized: boolean;

  private static newUserToken: EventEmitter<string | null> = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
    private keycloak: KeycloakService,
    private router: Router,
    private cloneControllerService: CloneControllerService,
    private userSessionManager: UserSessionManager,
    private userControllerService : UserControllerService,
    private pageManager: PageManager,
    private loggerService: AuditTrailControllerService
  ) {
    AuthenticationService.user.subscribe((user: any) => {
      if (user === undefined) {
        return;
      }
      AuthenticationService.initialized = true;
      AuthenticationService._user = user;
    });
    AuthenticationService.cloneState.subscribe((cloneState: any) => {
      if (cloneState === undefined) {
        return;
      }
      AuthenticationService._cloneState = cloneState;
    });
  }

  public getLastProtectedUrl(): string | null {
    return null;
  }

  public logout(redirectUri?: string) {
    this.loggerService.logLogoutAction({ auditTrailDto: {} }).subscribe((res) => {
      this.clearStaleSession();
      return from(this.keycloak.logout(redirectUri)).pipe(
        tap((x) => AuthenticationService.user.next(undefined))
      );
    });
    return new Observable<void>();
  }


  public clearStaleSession(): void {
    const redirect = AuthenticationService._user;
    AuthenticationService.user.next(undefined);
    localStorage.clear();
    sessionStorage.clear();
    this.pageManager.clearAllData();
    if (redirect) {
      location.href = this.router.createUrlTree(['/']).toString();
    }
  }


  public login(loginOptions: KeycloakLoginOptions) {
    this.clearOtp();
    return this.keycloak.login(loginOptions);
  }

  public getLoginUrl(loginOptions: KeycloakLoginOptions) {
    return this.keycloak.getKeycloakInstance().createLoginUrl(loginOptions);
  }

  public requestPasswordReset(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseUrl}/password/forgot`, data);
  }

  public getUser() {
    return AuthenticationService.user;
  }

  public getCloneState() {
    return AuthenticationService.cloneState;
  }

  public forbidAccess() {
    this.router.navigate(['/forbidden']);
  }

  public fetchUser(): Observable<UserPojo> {
    if (AuthenticationService.initialized) {
      return of(AuthenticationService._user);
    }
    return this.fetch();
  }

  public resetPassword(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseUrl}/password/reset/${data.resetToken}`,
      {password: data.password},
      {responseType: 'text'});
  }

  // public changePassword(password: string): Observable<ApiResponse<string> | null> {
  //   const mapper = (response: HttpResponse<ApiResponse<string>>): ApiResponse<string> | null => {
  //     AuthenticationService.newUserToken.next(response.body && response.body.data);
  //     return response.body;
  //   };
  //   return this.httpClient.post<ApiResponse<string>>(`${environment.apiBaseUrl}/change-password`,
  //     {password},
  //     {observe: 'response'})
  //     .pipe(map(mapper));
  // }

  private fetch() {

    const wrapper = new AsyncSubject();
    AuthenticationService.ongoingFetch = wrapper;

    this.userControllerService.userDetails()
      .pipe(tap(u => {
        wrapper.next(u);
        wrapper.complete();
        AuthenticationService.user.next(u);
        this.userSessionManager.setCurrentUser(u);
        // @ts-ignore
        this.userSessionManager.setCurrentUserAccount(u.accounts![0]);
        AuthenticationService.ongoingFetch = null;
      }), catchError(err => {
        wrapper.error(err);
        wrapper.complete();
        AuthenticationService.user.next(null);
        return of(null);
      }))
      .pipe(mergeMap(value => this.cloneControllerService.getCloneState()))
      .subscribe((u: any) => {
        AuthenticationService.cloneState.next(u);
      }, (err: HttpErrorResponse) => {
        if (err.error && typeof err.error == 'object' && err.error.code) {
          AuthenticationService.cloneState.error(err.error);
          return;
        }
      });

    return AuthenticationService.ongoingFetch;
  }

  hasPermission(permissionName: string | PermissionsEnum) {
    return Array.from(this.permissions()).filter((it: string) => it === permissionName).length;
  }

  public clearOtp() {
    const otpConstants = Object.values(Constant.Auth).filter(value => value.startsWith('OTP'));
    otpConstants.forEach(otpConstant => {
      sessionStorage.removeItem(otpConstant);
    });
  }

  private permissions(): Set<AccountMembershipPojo.PermissionsEnum> | any[] {
    let account = this.userSessionManager.currentUserAccount$.value;
    if (!account) {
      return [];
    }
    return Array.from(account.permissions!) || [];
  }

  hasRole(role: string): boolean {
    if (this.userSessionManager.currentUserAccount$.getValue()?.roles == undefined || this.userSessionManager.currentUserAccount$.getValue()?.roles == null) {
      return false;
    }
    return Array.from(this.userSessionManager.currentUserAccount$.getValue()?.roles!).find(value => value == role) != null;
  }

  public hasAccountType(accountType: string) {
    return this.userSessionManager.currentUserAccount$.getValue().accountType === accountType;
  }

  hasAnyRole(roles: string[]): boolean {
    for (let role of roles) {
      if (this.hasRole(role)) {
        return true;
      }
    }
    return false;
  }

  public hasAnyPermission(permissions: string[]) {
    for (let permission of permissions) {
      if (this.hasPermission(permission)) {
        return true;
      }
    }
    return false;
  }
}
