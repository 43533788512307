import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-face-api-doc',
  templateUrl: './face-api-doc.component.html',
  styleUrls: ['./face-api-doc.component.css']
})
export class FaceApiDocComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
