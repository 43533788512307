/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContactData { 
    email?: string;
    phone1?: string;
    phone2?: string;
}

