import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AuditTrailControllerService } from './api/audit-trail-controller.service';
import { CloneControllerService } from './api/clone-controller.service';
import { CloneManagementControllerService } from './api/clone-management-controller.service';
import { CloneServicesSearchControllerService } from './api/clone-services-search-controller.service';
import { ConsentRegistrationControllerService } from './api/consent-registration-controller.service';
import { DashboardControllerService } from './api/dashboard-controller.service';
import { GalleryControllerService } from './api/gallery-controller.service';
import { OneTimePasswordControllerService } from './api/one-time-password-controller.service';
import { PersonService } from './api/person.service';
import { PortalAccountControllerService } from './api/portal-account-controller.service';
import { PortalAccountTypeRoleControllerService } from './api/portal-account-type-role-controller.service';
import { ReportControllerService } from './api/report-controller.service';
import { SettingControllerService } from './api/setting-controller.service';
import { TrackingIdSlipReportControllerService } from './api/tracking-id-slip-report-controller.service';
import { UserControllerService } from './api/user-controller.service';
import { UserManagementControllerService } from './api/user-management-controller.service';
import { VerificationReasonControllerService } from './api/verification-reason-controller.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
