/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccountMembershipPojo { 
    accountName?: string;
    accountCode?: string;
    accountId?: number;
    accountType?: AccountMembershipPojo.AccountTypeEnum;
    roles?: Set<string>;
    permissions?: Set<AccountMembershipPojo.PermissionsEnum>;
    otherData?: Array<object>;
}
export namespace AccountMembershipPojo {
    export type AccountTypeEnum = 'HNODE' | 'SYSTEM_SUPPORT' | 'AUTHENTICATION_SERVICE_AGENT';
    export const AccountTypeEnum = {
        Hnode: 'HNODE' as AccountTypeEnum,
        SystemSupport: 'SYSTEM_SUPPORT' as AccountTypeEnum,
        AuthenticationServiceAgent: 'AUTHENTICATION_SERVICE_AGENT' as AccountTypeEnum
    };
    export type PermissionsEnum = 'CREATE_USER' | 'UPDATE_USER' | 'VIEW_USER' | 'DEACTIVATE_USER' | 'REGENERATE_KEY' | 'VIEW_KEYS' | 'VIEW_SERVICES';
    export const PermissionsEnum = {
        CreateUser: 'CREATE_USER' as PermissionsEnum,
        UpdateUser: 'UPDATE_USER' as PermissionsEnum,
        ViewUser: 'VIEW_USER' as PermissionsEnum,
        DeactivateUser: 'DEACTIVATE_USER' as PermissionsEnum,
        RegenerateKey: 'REGENERATE_KEY' as PermissionsEnum,
        ViewKeys: 'VIEW_KEYS' as PermissionsEnum,
        ViewServices: 'VIEW_SERVICES' as PermissionsEnum
    };
}


