export * from './device-controller.service';
import { DeviceControllerService } from './device-controller.service';
export * from './encryption-controller.service';
import { EncryptionControllerService } from './encryption-controller.service';
export * from './fingerprint-controller.service';
import { FingerprintControllerService } from './fingerprint-controller.service';
export * from './gps-controller.service';
import { GpsControllerService } from './gps-controller.service';
export * from './license-controller.service';
import { LicenseControllerService } from './license-controller.service';
export const APIS = [DeviceControllerService, EncryptionControllerService, FingerprintControllerService, GpsControllerService, LicenseControllerService];
