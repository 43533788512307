<div
  *ngIf="showAlertMessageTrigger"
  [ngClass]="{ anim: showAlertMessageTrigger }"
  [class.alert-success]="getMessageType() === AlertType.success"
  [class.alert-danger]="getMessageType() === AlertType.error"
  [class.alert-primary]="getMessageType() === AlertType.primary"
  [class.alert-info]="getMessageType() === AlertType.info"
  class="alert mb-4 fs-14 text-center"
>
  {{ getAlertMessage() }}
</div>
<div style="display: flex; justify-content: center; margin: 10px 0;">
  <div class="step">
    <ng-container *ngFor="let tab of tabHeaders; index as i">
      <div class="mark-line cursor-pointer">
        <div [ngClass]="{active: stepCount >= i+1,
                       pulse:  i+1 == stepCount
                       }"
             class="status-mark">
          <i>
            <i class="iconify icon"
               [ngClass]="{ active: stepCount >= i+1 }"
               [attr.data-icon]="tabIcons[i]">
            </i>
          </i>
        </div>
        <div class="">
          <p
             [ngClass]="{ active: stepCount >= i+1, pointer: stepCount >= i+1 }">
            {{ tab }}
          </p>
        </div>
      </div>
      <div class="right">
        <div
          [ngClass]="{active: i+1 == stepCount,
                    line: i < tabHeaders.length - 1
                    }"
        ></div>
      </div>
    </ng-container>
  </div>
</div>

<app-customer-information-form *ngIf="stepCount == 1"
                               (customerForm$)="setCustomerInformation($event)"
                               [preloadedData]="customerForm"
></app-customer-information-form>
<app-customer-biometric-verification *ngIf="stepCount == 2" (biometricData)="setBiometricData($event)"
                                     (loginEvent)="loginEvent()" (retryEvent)="retry()"
                                     (back)="goToStep(stepCount - 1)"
></app-customer-biometric-verification>
<app-consent-generation-preview *ngIf="stepCount == 3" [consentCode]="consentCode" [customerName]="customerForm?.firstName"
                                (showAlertMessage)="showAlertMessage($event.msg, $event.type)"></app-consent-generation-preview>
