<div class=" mt-3" style="max-width: 2000px;">
    <div class="d-flex flex-wrap flex-md-nowrap">
      <div class="w-auto" style="min-width: 300px; min-height: 100vh;">
        <nav id="api-guide" class="navbar border-end navbar-light flex-column align-items-start p-3"
              style="
                          position: sticky;
                          border-color: #BACCE8 !important;
                          min-height: 100vh;
                          top: 0;
                      "
        >
          <nav class="nav flex-column">
            <a class="nav-link" [ngClass]="{'active': fragment == 'intro'}" (click)="setActive('intro')" data-target-id="intro">Introduction</a>
            <a class="nav-link" [ngClass]="{'active': fragment == 'getting-started'}" (click)="setActive('getting-started')" data-target-id="getting-started">Getting Started</a>
            <!-- <a class="nav-link" [ngClass]="{'active': fragment == 'obtaining-access'}" (click)="setActive('obtaining-access')" data-target-id="obtaining-access">Obtaining Access</a> -->
            <a class="nav-link" [ngClass]="{'active': fragment == 'authentication'}" (click)="setActive('authentication')" data-target-id="authentication">Authentication</a>
            <a class="nav-link" [ngClass]="{'active': fragment == 'error-handling'}" (click)="setActive('error-handling')" data-target-id="error-handling">Errors</a>
            <a class="nav-link" [ngClass]="{'active': fragment == 'x-reason'}" (click)="setActive('x-reason')" data-target-id="x-reason">X-Request Reason</a>
            <a class="nav-link" [ngClass]="{'active': fragment == 'person'}" (click)="setActive('person')" data-target-id="person">Person</a>
              <nav class="nav flex-column">
                <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'read-person'}" (click)="setActive('read-person')" data-target-id="read-person">Read a variable set of a person’s attributes</a>
                <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'verify-person'}" (click)="setActive('verify-person')" data-target-id="verify-person">Verify a set of attributes of a person.</a>
                <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'read-old-results'}" (click)="setActive('read-old-results')" data-target-id="read-old-results">Read the result of a previously sent attributes request</a>
                <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'read-result'}" (click)="setActive('read-result')" data-target-id="read-result">
                  Read the result of a previously sent verify request</a>
              </nav>
            <a class="nav-link" [ngClass]="{'active': fragment == 'use_case'}" (click)="setActive('use_case')" data-target-id="use_case">HNode Use Cases</a>
            <nav class="nav flex-column">
              <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'verify_person'}" (click)="setActive('verify_person')" data-target-id="verify_person">Verify Person Use Case</a>
              <a class="nav-link ms-3 my-1" [ngClass]="{'active': fragment == 'verify_person_attributes'}" (click)="setActive('verify_person_attributes')" data-target-id="verify_person_attributes">Read Person Attributes Use Case</a>
            </nav>
          </nav>
        </nav>
      </div>
  
      <div class="w-100 pe-4" style="overflow-x: hidden;">
          <div class="ms-4 py-3 border-bottom  " style="border-color:#BACCE8 !important; " id="intro">
            <div class=" pe-4">
              <h3  class="mb-3">Introduction</h3>
              <div class="mb-3">The Integration Partner HA-NVS Node is the unit of distribution for the verification services which are geographically dispersed across authorized Integration Partners' data centers.</div>
              <div class="mb-3">This documentation provides necessary and sufficient information to sucessfully integrate a component to the ID Ecosystem. </div>
              <div class="mb-3">For help or enquiry, contact the NIMC Software Team Lead on <a href="mailto:software@nimc.gov.ng">software@nimc.gov.ng</a>.</div>
            </div>
          </div>
          <div class="border-bottom ms-2 mb-3" style="border-color:#BACCE8 !important; " id="getting-started">
            <div class="ms-2 py-4">
              <div class=" pe-4">
                <h3 class="mb-3">Getting Started</h3>
                <div class="mb-3">This API is documented in <strong>OpenAPI format</strong> provided by the HA-NVS team.</div>
                <div class="mb-3">To get started, you need to be obtain your client credentials from the HA-NVS team. These credentials are your <u>clientId</u> and <u>client secret</u>. The credentials will be used to authenticate your request using the OAuth flow described below.</div>
              </div>
            </div>
          </div>
          <div class="border-bottom ms-2  mb-3" style="border-color:#BACCE8 !important; " id="authentication">
          <div class="row py-4 ps-2">
            <div class="col-md-7 pe-4">
              <h3  class="mb-3">Authentication</h3>
              <div class="mb-3">HNode API offers one form of authentication:</div>
              <div class="mb-3">
                <ul>
                  <li>OAuth2 (Client credentials flow)</li>
                </ul>
              </div>
              <div class="mb-3">OAuth2 is an open protocol to allow secure authorization in a simple and standard method from web, mobile and desktop applications. Continue reading for more details.</div>
              <div class="mb-3">All requests to the API must carry the below headers to be properly authenticated.</div>
              <div class="mb-3">
                <ul>
                  <li>Authorization (Bearer token)</li>
                </ul>
              </div>
              <div class="mb-3">To get your Bearer token, send a post request of your clientId and client secret to the token endpoint. The response will contain your access token. Send this as your bearer token authorization header on all requests. Please note that according to OAuth specification and for security reasons, token life spans are short-lived.</div>
              <div class="mb-3">This means you will need to check if the token is expired first before making a call to the verification endpoints. If the token is expired, call the refresh token endpoint to refresh the token. All the available urls can be gotten from the Discovery URL mentioned below.</div>
  
              <div class="fs-20 mb-3 mt-4"><strong>Discovery Url</strong></div>
  
              <div class="mb-3">According to OIDC standard, the discovery url is documented <a href="{{env.keycloakConfig.url}}">here</a>. You can find all configurations you need here including the token url mentioned below.</div>
  
              <div class="fs-20 mb-3 mt-4"><strong>Token Url</strong></div>
  
              <div class="mb-3 text-link"><a href="">{{env?.apiDocConfig?.tokenEndpoint}}</a></div>
  
              <div class="fs-20 mb-3 mt-4"><strong>Flow</strong></div>
  
              <div class="mb-3">Client Credentials flow </div>
  
              <div class="fs-20 mb-3 mt-4"><strong>Scopes</strong></div>
              <div class="mb-3">
                <div class="mb-2">
                  <span class="px-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important;">id.verify</span> Verify_ID
                </div>
  
                <div class="">
                  <span class="px-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important;">id.read</span> Read Attributes
                </div>
              </div>
              <div class="mb-3">
                <ol>
                  <li class="mb-2">
                    Your app authenticates with the HNode Authorization Server using its Client ID and Client Secret ( /auth/realms/hclone/protocol/openid-connect/token endpoint)..
                  </li>
  
                  <li class="mb-2">
                    The HNode Authorization Server validates the Client ID and Client Secret.
                  </li>
  
                  <li class="mb-2">
                    The HNode Authorization Server responds with an Access Token.
                  </li>
  
                  <li class="mb-2">
                    Your application can use the Access Token to call an API on behalf of itself.
                  </li>
  
                </ol>
              </div>
            </div>
            <div class="col-md-5 ps-5">
  
              <div class="fs-16 mb-3 "><strong>Sample access token request</strong></div>
  
              <!-- <div class="mb-3">Find below a sample request to get your access token</div> -->
  
              <div class="mb-3"><u>Request</u></div>
  
              <div class="bg-dark text-secondary p-3 mb-4 fs-14" style="overflow-x: scroll;">
                <p class="m-0 ">curl --request POST \</p>
                <p class="m-0 ">  --url <span style="color: #99EFA2">$ {{env.keycloakConfig.url}}/realms/{{env.keycloakConfig.realm}}/protocol/openid-connect/token'</span> \</p>
                <p class="m-0">  --header <span style="color: #99EFA2">'content-type: application/x-www-form-urlencoded'</span> \</p>
                <p class="m-0">  --data <span class="text-warning">grant_type=</span>client_credentials \</p>
                <p class="m-0">  --data <span class="text-warning">client_id=</span>YOUR_CLIENT_ID \</p>
                <p class="m-0">  --data <span class="text-warning">client_secret=</span>YOUR_CLIENT_SECRET \</p>
                <p class="m-0">  --data <span class="text-warning">scope=</span>id.read,id.verify</p>
              </div>
  
  
              <div class="mb-3"><u>Response</u></div>
  
              <div class="bg-dark text-white p-3 mb-3 fs-14">
                [
                <p class="m-0">"access_token" <span class="text-warning">:</span><span style="color: #99EFA2"> "YOUR_ACCESS_TOKEN"</span></p>
                <p class="m-0">"expires_in" <span class="text-warning">:</span><span style="color: #99EFA2">300</span></p>
                <p class="m-0">"refresh_expires_in" <span class="text-warning">:</span><span style="color: #99EFA2">1800</span></p>
                <p class="m-0">"refresh_token" <span class="text-warning">:</span><span style="color: #99EFA2"> "YOUR_REFRESH_TOKEN"</span></p>
                <p class="m-0">"token_type" <span class="text-warning">:</span><span style="color: #99EFA2"> "bearer"</span></p>
                <p class="m-0">"not-before-policy" <span class="text-warning">:</span><span style="color: #99EFA2"> "1599472100"</span></p>
                <p class="m-0">"session_state" <span class="text-warning">:</span><span style="color: #99EFA2"> "417d00ac-5d99-4ca8-8b5d-385b564f7b56"</span></p>
                <p class="m-0">"scope" <span class="text-warning">:</span><span style="color: #99EFA2"> "id.read,id.verify"</span></p>
                ]
              </div>
  
            </div>
          </div>
          </div>
          <div class="ms-2 py-3 border-bottom  " style="border-color:#BACCE8 !important; " id="error-handling">
            <div class="ps-2">
              <h3  class="mb-3">Errors</h3>
              <div class="mb-3">Error handling is something no one wants to talk about however, we take it very seriously. The HNode system has a robust and transparent error handling system. This means that whenever something goes wrong for whatever reason, we let you know what happened and why. When something goes wrong, you will get an HTTP status code 500 and the body will be a json object of Find below a list of our error responses.</div>
              <div class="mb-3">
                <ul>
                  <li class="mb-1">
                    <span class="px-2  border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important;">1000-1099</span>  Error relating to bad requests. The message will say exactly what went wrong.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="border-bottom ms-4 mb-3" style="border-color:#BACCE8 !important; " id="x-reason" >
            <h3 class="mt-5 ">X-REQUEST-REASON </h3>
            <div class="mb-3 ">X-REQUEST-REASON header must be one of the following json object provided below</div>
            <div class="bg-dark col-md-4 text-white p-3 mb-4 fs-14" style=" max-height: 450px; overflow-x: hidden; overflow-y: scroll">
              <pre><code>{{x_reason}}</code></pre>
            </div>
          </div>
          <div class="border-bottom ms-2 mb-3 ps-2" style="border-color:#BACCE8 !important; " id="person">
            <h3 class=" pt-2 pb-3 mt-4">Person</h3>
            <div class="row border-bottom mb-4"  style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <h4 id="read-person" class="mb-3 ">Read a variable set of a person’s attributes</h4>
                <div class="mb-3">
                  Query for person using an identifier. Identifier can either UIN or TOKEN.
                </div>
                <div class="fs-20 mb-3 mt-4"><strong>Scope</strong></div>
                <div class="mb-3">
                    <div class="mb-2">
                      <span class="px-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important;">id.read</span> Read Attributes
                    </div>
  
  
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="accordion mb-3" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header text-white" id="headingOne" >
                      <button class="accordion-button text-white" type="button" style="background-color: #3C4257;" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        URL Suffix
  
                        <span class="iconify icon down" data-icon="ph:caret-down-bold"></span>
                        <span class="iconify icon up" data-icon="ph:caret-up-bold"></span>
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body fs-14">
                        <pre><code>{{attribute_request}}</code></pre>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rounded shadow-sm mb-4 " >
                  <div class="py-2 px-3 " style="background-color: #3C4257;">
                    <div class="fw-bold mb-3 mt-2 text-white">
                      REQUEST SAMPLES
                    </div>
                    <div class="mb-3">
                      <button class="btn btn-light">Payload</button>
                    </div>
                  </div>
                  <div class="p-3 text-white bg-dark" style=" max-height: 700px; overflow-x: hidden; overflow-y: scroll">
                    <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                      <div>Content type</div>
                      <div>application/json</div>
                    </div>
                    <div class="text-end"><button (click)="copyCode(payload_2)" class="btn-unset-all">Copy</button></div>
                    <div class="bg-dark text-white p-3 mb-4 fs-14">
                      <pre><code>{{payload_2}}</code></pre>
                    </div>
                  </div>
                </div>
                <div class="rounded shadow-sm mb-4 " >
                  <div class="py-2 px-3 " style="background-color: #3C4257;">
                    <div class="fw-bold mb-3 mt-2 text-white">
                      RESPONSE SAMPLES
                    </div>
                    <div class="row gy-4">
                      <div class="col-md-3">
                        <button class="btn btn-light text-success" (click)="switchVerifyRequestResponses(202)">202</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchVerifyRequestResponses(400)">400</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchVerifyRequestResponses(500)">500</button>
                      </div>
                    </div>
                  </div>
                  <div class="p-3 text-white bg-dark" style=" max-height: 300px; overflow-x: hidden; overflow-y: scroll">
                    <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                      <div>Content type</div>
                      <div>application/json</div>
                    </div>
                    <div class="text-end"><button (click)="copyCode(verify_response)" class="btn-unset-all">Copy</button></div>
                    <div class="bg-dark text-white p-3 mb-4 fs-14">
                      <pre><code>{{verify_response}}</code></pre>
                    </div>
                  </div>
                </div>
  
  
              </div>
            </div>
            <div class="row border-bottom mb-4"  style="border-color:#BACCE8 !important;">
              <div class="col-md-7">
                <h4 id="verify-person" class="mb-3 ">Verify a set of attributes of a person</h4>
                <div class="mb-3 ">Verify an Identity based on an identity identifier (UIN, token…) and a set of Identity Attributes. Verification is strictly matching all provided identity attributes to compute the global Boolean matching result.</div>
  
                <div class="mb-3 fw-bold">Currently, we do not support verification proof but support for proof will roll out in next versions.</div>
  
                <div class="fs-20 mb-3 mt-4"><strong>Scope</strong></div>
  
                <div class="mb-3">
                    <div class="">
                      <span class="px-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important;">id.verify</span> Verify_ID
                    </div>
                </div>
  
                <div class="fs-20 mt-5 mb-3"><strong>Biometrics</strong></div>
                <div class="d-flex justify-content-center mb-3">
                  <ul class="pills nav-tabs d-flex col-lg-auto nav-pills nav" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button (click)="isFace=true" [ngClass]="{'active': isFace}" class="nav-link rounded-pill px-xl-4" type="button">
                            <span>Face</span>
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button (click)="isFace=false" [ngClass]="{'active': !isFace}" class="nav-link rounded-pill ps-xl-4" type="button">
                            <span>FingerPrint</span>
                        </button>
                    </li>
                  </ul>
                </div>
  
                <app-face-api-doc *ngIf="isFace"></app-face-api-doc>
                <app-fingerprint-api-doc *ngIf="!isFace"></app-fingerprint-api-doc>
  
              </div>
              <div class="col-md-5 ps-5">
                <div class="accordion mb-3" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header text-white" id="headingOne" >
                      <button class="accordion-button text-white" type="button" style="background-color: #3C4257;" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        URL Suffix
  
                        <span class="iconify icon down" data-icon="ph:caret-down-bold"></span>
                        <span class="iconify icon up" data-icon="ph:caret-up-bold"></span>
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body fs-14">
                        <pre><code>{{verify_request}}</code></pre>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rounded shadow-sm mb-4 " >
                  <div class="py-2 px-3 " style="background-color: #3C4257;">
                    <div class="fw-bold mb-3 mt-2 text-white">
                      REQUEST SAMPLES
                    </div>
                    <div class="mb-3">
                      <button class="btn btn-light">Payload</button>
                    </div>
                  </div>
                  <div class="p-3 text-white bg-dark" style=" max-height: 700px; overflow-x: hidden; overflow-y: scroll">
                    <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                      <div>Content type</div>
                      <div>application/json</div>
                    </div>
                    <div class="text-end"><button (click)="copyCode(payload)" class="btn-unset-all">Copy</button></div>
                    <div class="bg-dark text-white p-3 mb-4 fs-14">
                      <pre><code>{{payload}}</code></pre>
                    </div>
                  </div>
                </div>
                <div class="rounded shadow-sm mb-4 " >
                  <div class="py-2 px-3 " style="background-color: #3C4257;">
                    <div class="fw-bold mb-3 mt-2 text-white">
                      RESPONSE SAMPLES
                    </div>
                    <div class="row gy-4">
                      <div class="col-md-3">
                        <button class="btn btn-light text-success" (click)="switchVerifyRequestResponses(202)">202</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchVerifyRequestResponses(400)">400</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchVerifyRequestResponses(500)">500</button>
                      </div>
                    </div>
                  </div>
                  <div class="p-3 text-white bg-dark" style=" max-height: 700px; overflow-x: hidden; overflow-y: scroll">
                    <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                      <div>Content type</div>
                      <div>application/json</div>
                    </div>
                    <div class="text-end"><button (click)="copyCode(verify_response)" class="btn-unset-all">Copy</button></div>
                    <div class="bg-dark text-white p-3 mb-4 fs-14">
                      <pre><code>{{verify_response}}</code></pre>
                    </div>
                  </div>
                </div>
  
                <!-- <div class="rounded shadow-sm mb-4 " >
                  <div class="py-2 px-3 " style="background-color: #3C4257;">
                    <div class="fw-bold mb-3 mt-2 text-white">
                      CALLBACK PAYLOAD SAMPLES
                    </div>
                  </div>
                  <div class="p-3  text-white bg-dark" >
                    <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                      <div>Content type</div>
                      <div>application/json</div>
                    </div>
                    <div class="text-end mb-2"><button class="btn-unset-all">Copy</button></div>
                    <div class="bg-dark text-white p-3 mb-4 fs-14" style=" max-height: 350px; overflow-x: hidden; overflow-y: scroll">
                      <pre><code>{{callback_response}}</code></pre>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="row border-bottom mb-4" style="border-color:#BACCE8 !important;"  >
              <div class="col-md-7">
                <h4 id="read-old-results" class="mb-3 ">Read the result of a previously sent attributes request</h4>
                <div class="mb-3">
                  Query for task result using taskId. This was returned when the original call was made.
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="accordion mb-3" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header text-white" id="headingOne" >
                      <button class="accordion-button text-white" type="button" style="background-color: #3C4257;" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        URL Suffix
  
                        <span class="iconify icon down" data-icon="ph:caret-down-bold"></span>
                        <span class="iconify icon up" data-icon="ph:caret-up-bold"></span>
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body fs-14">
                        <pre><code>{{attribute_result}}</code></pre>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rounded shadow-sm mb-4 " >
                  <div class="py-2 px-3 " style="background-color: #3C4257;">
                    <div class="fw-bold mb-3 mt-2 text-white">
                      RESPONSE SAMPLES
                    </div>
                    <div class="row gy-4">
                      <div class="col-md-3">
                        <button class="btn btn-light text-success" (click)="switchReadAttributeResponses(200)">200</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchReadAttributeResponses(400)">400</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchReadAttributeResponses(500)">500</button>
                      </div>
  
                    </div>
                  </div>
                  <div class="p-3 text-white bg-dark" style=" max-height: 400px; overflow-x: hidden; overflow-y: scroll">
                    <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                      <div>Content type</div>
                      <div>application/json</div>
                    </div>
                    <div class="text-end"><button (click)="copyCode(read_attribute_response)" class="btn-unset-all">Copy</button></div>
                    <div class="bg-dark text-white p-3 mb-4 fs-14">
                      <pre><code>{{read_attribute_response}}</code></pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row  mb-4"  >
              <div class="col-md-7">
                <h4 id="read-result" class="mb-3 ">Read the result of a previously sent verify requests</h4>
                <div class="mb-3">
                  Query for task result using taskId. This was returned when the original call was made.
                </div>
              </div>
              <div class="col-md-5 ps-5">
                <div class="accordion mb-3" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header text-white" id="headingOne" >
                      <button class="accordion-button text-white" type="button" style="background-color: #3C4257;" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        URL Suffix
  
                        <span class="iconify icon down" data-icon="ph:caret-down-bold"></span>
                        <span class="iconify icon up" data-icon="ph:caret-up-bold"></span>
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body fs-14">
                        <pre><code>{{verify_result}}</code></pre>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="rounded shadow-sm mb-4 " >
                  <div class="py-2 px-3 " style="background-color: #3C4257;">
                    <div class="fw-bold mb-3 mt-2 text-white">
                      RESPONSE SAMPLES
                    </div>
                    <div class="row gy-4">
                      <div class="col-md-3">
                        <button class="btn btn-light text-success" (click)="switchAttributeRequestResponses(200)">200</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchAttributeRequestResponses(400)">400</button>
                      </div>
                      <div class="col-md-3">
                        <button class="btn btn-dark text-danger" (click)="switchAttributeRequestResponses(500)">500</button>
                      </div>
  
                    </div>
                  </div>
                  <div class="p-3 text-white bg-dark" style=" max-height: 700px; overflow-x: hidden; overflow-y: scroll">
                    <div class="p-2 mb-2 fs-12  rounded" style="background-color: #28353A;">
                      <div>Content type</div>
                      <div>application/json</div>
                    </div>
                    <div class="text-end"><button (click)="copyCode(attribute_response)" class="btn-unset-all">Copy</button></div>
                    <div class="bg-dark text-white p-3 mb-4 fs-14">
                      <pre><code>{{attribute_response}}</code></pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" ms-2 mb-3 ps-2" id="use_case">
            <h3 class=" pt-2 pb-3 mt-4">HNode Use Cases</h3>
            <div>
              <h4 id="verify_person" class="mb-3 ">Verify Person Use Case</h4>
              <div class="ps-3">
                <h5 id="verify_finger" class="mt-4 text-header mb-3 fw-bold fs-20">FingerPrint</h5>
                  <div class="mb-3 ">Verify an Identity based on fingerprint data. To get the response use the Read the result of a previously sent verify request.</div>
                  <div class="mb-3 "><strong>Note:</strong> Currently, the verification proof option is not supported and should always be false.</div>
                  <div class="mb-3"> <strong>Sample Request URL:</strong></div>
                  <div class="mb-3">POST {{finger_post}}</div>
                  <div class="row border-bottom mt-4 mb-5 pb-3 " style="border-color:#BACCE8 !important;">
                    <div class="col-md-7">
                      <div class="table-responsive">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Field Name</th>
                              <th scope="col">Field Type</th>
                              <th scope="col">Restrictions</th>
                              <th scope="col">Format Validation</th>
                              <th scope="col">Required / Optional / Conditional</th>
                              <th scope="col">Comments</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>biometricType</td>
                              <td>String</td>
                              <td>FINGER</td>
                              <td>Not Blank</td>
                              <td>Required</td>
                              <td>-</td>
                            </tr>
  
                            <tr>
                              <td>biometricSubType</td>
                              <td>String</td>
                              <td>RIGHT_THUMB, RIGHT_INDEX, RIGHT_MIDDLE, RIGHT_RING, RIGHT_LITTLE, LEFT_THUMB, LEFT_INDEX, LEFT_MIDDLE, LEFT_RING,
                                LEFT_LITTLE
                                </td>
                              <td>Not Blank</td>
                              <td>At least one of the restrictions.</td>
                              <td>-</td>
                            </tr>
  
                            <tr>
                              <td>image</td>
                              <td>String</td>
                              <td>WSQ BASE64-encoded image</td>
                              <td>-</td>
                              <td>Conditional</td>
                              <td>-</td>
                            </tr>
  
                            <tr>
                              <td>compression</td>
                              <td>String</td>
                              <td>WSQ</td>
                              <td>Not Blank</td>
                              <td>Required</td>
                              <td>-</td>
                            </tr>
  
                            <tr>
                              <td>quality</td>
                              <td>String</td>
                              <td>Quality, as a number, of the biometric</td>
                              <td>Not Blank</td>
                              <td>Required</td>
                              <td>-</td>
                            </tr>
  
                            <tr>
                              <td>qualityformat</td>
                              <td>String</td>
                              <td>ISO_19794, NFIQ, or NFIQ2</td>
                              <td>Not Blank</td>
                              <td>Required</td>
                              <td>-</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-5 ps-5">
                      <div class="fs-16 mb-3 "><strong>Sample Message</strong></div>
                      <div class="bg-dark text-white p-3 mb-4 fs-14">
                        <pre><code>{{sample_payload}}</code></pre>
                        </div>
                    </div>
                  </div>
  
              </div>
  
              <div class="ps-3" >
                <h5 id="verify_face" class="mt-4 text-header mb-3 fw-bold fs-20">Face</h5>
                  <div class="mb-3 ">Verify an Identity based on face biometrics data. This verification is done when face data needs to be verified with a response given as a success or error response. To get the response use the <strong>Read the result of a previously sent verify request</strong></div>
                  <div class="mb-3 "><strong>Note:</strong> Currently, we do not support verification proof but support for proof will roll out in the next versions.</div>
                  <div class="mb-3"> <strong>Sample Request URL:</strong></div>
                  <div class="mb-3">POST {{face_post}}</div>
                  <div class="row border-bottom mt-4 mb-5 pb-3 " style="border-color:#BACCE8 !important;">
                    <div class="col-md-7">
                      <div class="table-responsive">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">Field Name</th>
                              <th scope="col">Field Type</th>
                              <th scope="col">Restrictions</th>
                              <th scope="col">Format Validation</th>
                              <th scope="col">Required / Optional / Conditional</th>
                              <th scope="col">Comments</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>biometricType</td>
                              <td>String</td>
                              <td>FACE</td>
                              <td>Not Blank</td>
                              <td>Required</td>
                              <td>-</td>
                            </tr>
  
                            <tr>
                              <td>biometricSubType</td>
                              <td>String</td>
                              <td>PORTRAIT
                                </td>
                              <td>Not Blank</td>
                              <td>Required</td>
                              <td>-</td>
                            </tr>
  
                            <tr>
                              <td>image</td>
                              <td>String</td>
                              <td>BASE64-encoded image</td>
                              <td>Not Blank</td>
                              <td>Conditional</td>
                              <td>-</td>
                            </tr>
  
                            <tr>
                              <td>compression</td>
                              <td>String</td>
                              <td>PNG, JPEG</td>
                              <td>Not Blank</td>
                              <td>Required</td>
                              <td>-</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-5 ps-5">
                      <div class="fs-16 mb-3 "><strong>Sample Message</strong></div>
                      <div class="bg-dark text-white p-3 mb-4 fs-14">
                        <pre><code>{{sample_message_face}}</code></pre>
                        </div>
                    </div>
                  </div>
  
              </div>
  
              <div class="ps-3 border-bottom mb-3"  style="border-color:#BACCE8 !important;">
                <h5 id="read_result_one" class="mt-4 text-header mb-3 fw-bold fs-20">Read the result of a previously sent verify request</h5>
                  <div class="mb-3 ">Query for task result using taskId. This was returned when the original call was made.</div>
                  <div class="mb-3"> <strong>Sample Request URL:</strong> ---------</div>
              </div>
            </div>
  
            <div>
              <h4 id="verify_person_attributes" class="mb-3 mt-5 ">Read Person Attributes Use Case</h4>
              <div class="mb-3">
                Query for the person using an identifier. The identifier can be either UIN or TOKEN.
              </div>
              <div class="ps-3">
                  <div class="row border-bottom mt-4 mb-5 pb-3 " style="border-color:#BACCE8 !important;">
                    <div class="col-md-7">
                      <h5 id="request" class="mt-4 text-header mb-3 fw-bold fs-20">Request</h5>
                      <div class="fs-20 mb-3 mt-4"><strong>Scope</strong></div>
                      <div class="mb-3"><span class="px-2 border fs-14" style="background-color: #eef0f5; border-color:#DADDE2 !important;">id.read</span>Read Attributes</div>
                      <div class="mb-3">POST {{read_person_post}}</div>
                    </div>
                    <div class="col-md-5 ps-5">
                      <div class="fs-16 mb-3 "><strong>Sample Message</strong></div>
                      <div class="bg-dark text-white p-3 mb-4 fs-14">
                        <pre><code>{{sample_payload_2}}</code></pre>
                        </div>
                    </div>
                  </div>
              </div>
  
              <div class="ps-3">
                <div class="row  mt-4 mb-5 pb-3 " style="border-color:#BACCE8 !important;">
                  <div class="col-md-7">
                    <h5 id="request" class="mt-4 text-header mb-3 fw-bold fs-20">Read the result of a previously sent attributes request</h5>
                    <div class="mb-3">Query for task result using taskId. This was returned when the original call was made.</div>
                    <div class="mb-3"><u>Reponse URL</u></div>
                    <div class="mb-3">{{response_link}}</div>
                  </div>
                  <div class="col-md-5 ps-5">
                    <div class="fs-16 mb-3 "><strong>Response</strong></div>
                    <div class="bg-dark text-white p-3 mb-4 fs-14">
                      <pre><code>{{bad_response}}</code></pre>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  