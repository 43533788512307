import {Inject, Injectable, Optional} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Location} from '@angular/common';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParameterCodec} from "@angular/common/http";
import * as moment from "moment/moment";
import {Observable} from "rxjs";
import {BASE_PATH, Configuration} from "../../../../../sdk/hclone-api-sdk";
import {CustomHttpParameterCodec} from "../../../../../sdk/hclone-api-sdk/encoder";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  public configuration = new Configuration();
  protected basePath = 'http://localhost:3281';
  public defaultHeaders = new HttpHeaders();
  public encoder: HttpParameterCodec;

  constructor(private location: Location,
              protected httpClient: HttpClient,
              @Optional() @Inject(BASE_PATH) basePath: string,
              @Optional() configuration: Configuration) {

    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  getInvalidFormFields(form: FormGroup): any[] {
    const invalidControls = [];

    for (const controlName in form.controls) {
      const control = form.controls[controlName];
      control.markAsTouched();
      if (control.disabled) continue;
      if (control.invalid) {
        invalidControls.push({
          name: controlName,
          errors: control.errors
        });
      }
    }
    return invalidControls;
  }

  cutText(name: string, chars: number): string {
    if (!name) {
      ('');
    }
    return name?.length > chars ? name.substr(0, chars) + '...' : name;
  }

  back(): void {
    this.location.back();
  }

  getFullName(data: any, cut = false, length = 15): string {
    console.log(data);
    const fullName = data?.firstName + ' ' + data?.otherNames + ' ' + data?.lastName;
    if (cut) {
      return this.cutText(fullName, length);
    }
    return fullName;
  }

  preventNumberInput(event: KeyboardEvent): void {
    const charCode = typeof event.which == 'undefined' ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);
    if (event?.code?.includes('Numpad')) {
      event?.preventDefault();
    } else if (/\d/.test(charStr)) {
      event?.preventDefault();
    }
  }

  getTodayDate(): string {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    return `${year}-${month}-${day}`;
  }

  public removeModalDialogClass(): void {
    const elements = document.getElementsByClassName('modal-dialog');

    if (elements.length > 0) {
      elements[0].classList.remove('modal-dialog');
    }
  }

  public hideOffCanvasClass(): void {
    const elements = document.getElementsByClassName('offcanvas');

    if (elements.length > 0) {
      elements[0].classList.remove('show');
    }
  }

  showOffCanvasClass(): void {
    const elements = document.getElementsByClassName('offcanvas');

    if (elements.length > 0) {
      elements[0].classList.add('show');
      console.log(elements);
    }
  }

  getError(error: any): string {
    if (
      error instanceof HttpErrorResponse &&
      error.error &&
      typeof error.error == 'object' &&
      error.error.message
    ) {
      /*            if (error.error.message.indexOf(':') > -1) {
                      return error.error.message.split(':')[1]
                  }*/
      return error.error.message;
    }
    return 'Something went wrong. Please try again later.';
  }

  getDayDiffFromDateTillNow(date: string | undefined): number {
    const issuanceDate = moment(date, 'YYYY-MM-DD HH:mm:ss');
    const currentDate = moment();
    return currentDate.diff(issuanceDate, 'days');
  }


  noWhitespaceValidator(control: FormControl): unknown {
    let isWhitespace: boolean;
    if (control.value == '') {
      isWhitespace = false;
    } else {
      isWhitespace = (control.value || '').trim().length === 0;
    }
    const isValid = !isWhitespace;
    return isValid ? null : {whitespace: true};
  }

  getFileUrl(fileId: number, download = true): string {
    return download
      ? `${environment.apiBaseUrl}/files/${fileId}`
      : `${environment.apiBaseUrl}/files/${fileId}/open`;
  }

  gotoFragment(fragment: string): void {
    const currentUrl = this.location.path();
    const newUrl = `${currentUrl}#${fragment}`;
    this.location.go(newUrl);
  }

  toTitleCase(controlName: string): string {
    // Split camel case words with space
    let result = controlName.replace(/([A-Z])/g, ' $1');

    // Convert the first letter of each word to uppercase and the rest to lowercase
    result = result.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

    return result;
  }

}


