/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BiometricData } from './biometric-data';
import { CredentialData } from './credential-data';
import { ContactData } from './contact-data';
import { BiographicData } from './biographic-data';


export interface VerifyIDRequest { 
    biographicData?: BiographicData;
    biometricData?: Array<BiometricData>;
    credentialData?: Array<CredentialData>;
    contactData?: ContactData;
}

