/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VerificationRequestSearchResponsePojo { 
    id?: number;
    requestSource?: string;
    identifierType?: VerificationRequestSearchResponsePojo.IdentifierTypeEnum;
    createdAt?: string;
    cloneTransactionId?: string;
    responseReceiptTime?: string;
    verificationRequestType?: VerificationRequestSearchResponsePojo.VerificationRequestTypeEnum;
    transactionPhaseType?: VerificationRequestSearchResponsePojo.TransactionPhaseTypeEnum;
    sourceIP?: string;
    sourceAddress?: string;
    requestBody?: string;
    idmsVerificationTransactionRef?: string;
    transactionReconciliationTime?: string;
    reasonForRequest?: string;
    verificationLevel?: VerificationRequestSearchResponsePojo.VerificationLevelEnum;
}
export namespace VerificationRequestSearchResponsePojo {
    export type IdentifierTypeEnum = 'UIN' | 'TOKEN';
    export const IdentifierTypeEnum = {
        Uin: 'UIN' as IdentifierTypeEnum,
        Token: 'TOKEN' as IdentifierTypeEnum
    };
    export type VerificationRequestTypeEnum = 'READ_ATTRIBUTE' | 'VERIFY_ID';
    export const VerificationRequestTypeEnum = {
        ReadAttribute: 'READ_ATTRIBUTE' as VerificationRequestTypeEnum,
        VerifyId: 'VERIFY_ID' as VerificationRequestTypeEnum
    };
    export type TransactionPhaseTypeEnum = 'QUEUED' | 'PROCESSED' | 'ERROR';
    export const TransactionPhaseTypeEnum = {
        Queued: 'QUEUED' as TransactionPhaseTypeEnum,
        Processed: 'PROCESSED' as TransactionPhaseTypeEnum,
        Error: 'ERROR' as TransactionPhaseTypeEnum
    };
    export type VerificationLevelEnum = 'LEVEL_ONE' | 'LEVEL_TWO' | 'LEVEL_THREE' | 'LEVEL_FOUR' | 'LEVEL_FIVE';
    export const VerificationLevelEnum = {
        One: 'LEVEL_ONE' as VerificationLevelEnum,
        Two: 'LEVEL_TWO' as VerificationLevelEnum,
        Three: 'LEVEL_THREE' as VerificationLevelEnum,
        Four: 'LEVEL_FOUR' as VerificationLevelEnum,
        Five: 'LEVEL_FIVE' as VerificationLevelEnum
    };
}


