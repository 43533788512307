import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";
import {CloneStatePojo, UserPojo} from "../../../../../sdk/hclone-api-sdk";
import {KeycloakService} from "keycloak-angular";
import {Constant} from "../../../shared/models/constants";

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.css']
})
export class SiteHeaderComponent implements OnInit {
  apiGuide: boolean = false;
  @Input()
  user?: UserPojo;
  @Input()
  cloneState?: CloneStatePojo;
  routeData: any;
  loginUrl: string;

  constructor(private router: Router, route: ActivatedRoute,
              private authenticationService: AuthenticationService
  ) {
    router.events.forEach(e => {
      if (e instanceof NavigationEnd) {
        this.routeData = route?.root?.firstChild?.snapshot?.data;
      }
    })
  }

  ngOnInit(): void {
    this.loginUrl = this.authenticationService.getLoginUrl({

      redirectUri: window.location.origin + '/dashboard',
      prompt: 'login'

    });
  }

  login() {
    // this.authenticationService.logout(this.loginUrl).subscribe(value => {
    //
    // });
    // await this.authenticationService.login();
  }

  async dashboard() {
    this.router.navigateByUrl('/dashboard')
  }

  logout() {
    this.authenticationService.logout(`${window.location.origin}`).subscribe()
  }

  goToDashboard() {
    window.location.href = "/dashboard";
  }

  setHeaderClass() {
    let headerClass = '';

    if (this.routeData?.fixedHeader) {
      headerClass += ' header-fixed fixed-on-scroll'
    }
    if (!(this.routeData?.isExtranet)) {
      headerClass += ' intranet-nav'
    }

    return headerClass;
  }


  get formatRoles (): string {
    var roles = this.user?.accounts![0]?.roles;

    if (roles != null) {
      let myArray = Array.from(roles)
      return myArray.join(", ")
    }
    return " ";
  }

  setApiGuideActive() {
    this.apiGuide = true;
  }

  resetApiGuideActive() {
    this.apiGuide = false;
  }

}
