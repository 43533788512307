<div>
  <div style="display: flex; flex-direction: column; align-items: center;">
    <div class="connector" [ngClass]="fpConnected? 'active':'inactive'">
      <i *ngIf="fpConnected">
        <i class="iconify fs-2" data-icon="majesticons:scan-fingerprint" style="color: green"></i>
      </i>
      <i *ngIf="!fpConnected && pluginDetected">
        <i class="iconify fs-2" data-icon="material-symbols-light:fingerprint-off-sharp" style="color: rgba(255,0,0,0.49)"></i>
      </i>

      <i *ngIf="!fpConnected && !pluginDetected">
        <i class="iconify fs-2" data-icon="material-symbols-light:power-plug-off-outline"
           style="color: rgba(255,0,0,0.49)"></i>
      </i>
    </div>
    <div *ngIf="fpConnected" class="text-success mt-3 fs-12 fw-semibold">
      Biometric Scanner Connected
    </div>
    <div *ngIf="!fpConnected && pluginDetected" class="text-danger mt-3 fs-12 fw-semibold">
      Fingerprint Scanner Not Connected
    </div>

    <div *ngIf="!fpConnected && !pluginDetected" class="text-danger mt-3 fs-13 fw-semibold text-center">
      No Device Plugin is detected. Start Plugin and try again.
    </div>

    <div class="card rounded-2 border-0 scan-background-card mt-3">
      <div class="card-body">
        <p class="fw-bold text-center" style="color: rgba(0, 0, 0, 0.80)">
          Instructions
        </p>
        <ul style="font-size: 12px; margin-top:1em;">
          <li> Ensure your finger is clean and dry</li>
          <li> Place the correct finger as seen in the image</li>
          <li> Avoid moving your finger while capture to ensure an accurate result</li>
        </ul>
      </div>
    </div>




  </div>
</div>
