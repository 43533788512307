<form [formGroup]="form">
  <div class="modal-header">
    <h5 class="modal-title">Share via Email</h5>
    <button type="button" class="btn-close" (click)="close()" aria-label="Close"></button>
  </div>

  <div class="modal-body">
    <div
      *ngIf="showAlertMessageTrigger"
      [ngClass]="{ anim: showAlertMessageTrigger }"
      [class.alert-success]="getMessageType() === AlertType.success"
      [class.alert-danger]="getMessageType() === AlertType.error"
      [class.alert-primary]="getMessageType() === AlertType.primary"
      [class.alert-info]="getMessageType() === AlertType.info"
      class="alert mb-4 fs-14 text-center"
    >
      {{ getAlertMessage() }}
    </div>

    <div class="form-group my-4">
      <label for="emailAddress" class="required">Email Address</label>
      <input id="emailAddress" type="text" class="form-control" placeholder="Enter Email Address"
             formControlName="emailAddress">
      <error-message
        [controlName]="'emailAddress'"
        [singularError]="true"
        [form]="form"
      ></error-message>
    </div>

  </div>

  <div class="modal-footer">
    <button [disabled]="isSharing" (click)="shareConsentSlip()" type="button" class="btn btn-sm btn-primary">
      <i class="iconify icon" data-icon="material-symbols-light:share-outline"></i>
      Share</button>
  </div>
</form>
