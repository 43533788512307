import {Component, OnInit} from '@angular/core';
import {Subject} from "rxjs";

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent implements OnInit {

 user: any;
 stop$ = new Subject();


  constructor() {
  }

  ngOnInit(): void {

  }

  takeMeBack() {
    window.location.href = '/dashboard';
  }

  ngOnDestroy(): void {
    // this.stop$.next();
    // this.stop$.complete();
  }
}
