/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AsyncResultSchema } from '../model/models';
import { OSIAErrorSchema } from '../model/models';
import { PersonResultSchema } from '../model/models';
import { ReadAttributeRequest } from '../model/models';
import { VerifyIDRequest } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface ReadAttributesRequestParams {
    /** Person identifier. Object of type string */
    identifier: string;
    /** The client specified id of the transaction. Object of type string. */
    transactionId: string;
    /** The reason for conducting this verification. Object of type string. */
    xREQUESTREASON: string;
    /** Type of identifier */
    identifierType: string;
    readAttributeRequest: ReadAttributeRequest;
    /** The callback address, where the result will be sent when available. Object of type string/uri. */
    callback?: string;
    /** The source system conducting this verification. Object of type string. */
    xSOURCESYSTEM?: string;
}

export interface ReadAttributesResultRequestParams {
    /** taskID to get result for. Object of type string */
    taskId: string;
    /** The client specified id of the transaction. Object of type string. */
    transactionId: string;
}

export interface ReadVerifyResultRequestParams {
    /** taskID to get result for. Object of type string */
    taskId: string;
    /** The client specified id of the transaction. Object of type string. */
    transactionId: string;
}

export interface VerifyIdentityRequestParams {
    /** Person identifier. Object of type string */
    identifier: string;
    /** The type of identifier (e.g., \&quot;uin\&quot;, \&quot;vnin\&quot;, \&quot;token\&quot;, \&quot;credentialNumber\&quot;). Default is \&quot;uin\&quot;. Object of type string. */
    identifierType: string;
    /** The client specified id of the transaction. Object of type string. */
    transactionId: string;
    /** The reason for conducting this verification. Object of type string. */
    xREQUESTREASON: string;
    verifyIDRequest: VerifyIDRequest;
    verificationProofRequired?: boolean;
    /** The callback address, where the result will be sent when available */
    callback?: string;
    xSOURCESYSTEM?: string;
}


@Injectable({
  providedIn: 'root'
})
export class PersonService {

    protected basePath = 'http://hnode.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Read a variable set of a person’s attributes
     * Query for person  using an identifier. Could be uin or token or other. 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAttributes(requestParameters: ReadAttributesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<PersonResultSchema>;
    public readAttributes(requestParameters: ReadAttributesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<HttpResponse<PersonResultSchema>>;
    public readAttributes(requestParameters: ReadAttributesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<HttpEvent<PersonResultSchema>>;
    public readAttributes(requestParameters: ReadAttributesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<any> {
        const identifier = requestParameters.identifier;
        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling readAttributes.');
        }
        const transactionId = requestParameters.transactionId;
        if (transactionId === null || transactionId === undefined) {
            throw new Error('Required parameter transactionId was null or undefined when calling readAttributes.');
        }
        const xREQUESTREASON = requestParameters.xREQUESTREASON;
        if (xREQUESTREASON === null || xREQUESTREASON === undefined) {
            throw new Error('Required parameter xREQUESTREASON was null or undefined when calling readAttributes.');
        }
        const identifierType = requestParameters.identifierType;
        if (identifierType === null || identifierType === undefined) {
            throw new Error('Required parameter identifierType was null or undefined when calling readAttributes.');
        }
        const readAttributeRequest = requestParameters.readAttributeRequest;
        if (readAttributeRequest === null || readAttributeRequest === undefined) {
            throw new Error('Required parameter readAttributeRequest was null or undefined when calling readAttributes.');
        }
        const callback = requestParameters.callback;
        const xSOURCESYSTEM = requestParameters.xSOURCESYSTEM;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (transactionId !== undefined && transactionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>transactionId, 'transactionId');
        }
        if (callback !== undefined && callback !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>callback, 'callback');
        }
        if (identifierType !== undefined && identifierType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifierType, 'identifierType');
        }

        let headers = this.defaultHeaders;
        if (xREQUESTREASON !== undefined && xREQUESTREASON !== null) {
            headers = headers.set('X-REQUEST-REASON', String(xREQUESTREASON));
        }
        if (xSOURCESYSTEM !== undefined && xSOURCESYSTEM !== null) {
            headers = headers.set('X-SOURCE-SYSTEM', String(xSOURCESYSTEM));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<PersonResultSchema>(`${this.configuration.basePath}/v1/attributes/${encodeURIComponent(String(identifier))}`,
            readAttributeRequest,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read the result of a previously sent attributes request
     * Query for task using a taskId. This was returned when the original call was made. 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readAttributesResult(requestParameters: ReadAttributesResultRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<PersonResultSchema>;
    public readAttributesResult(requestParameters: ReadAttributesResultRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<HttpResponse<PersonResultSchema>>;
    public readAttributesResult(requestParameters: ReadAttributesResultRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<HttpEvent<PersonResultSchema>>;
    public readAttributesResult(requestParameters: ReadAttributesResultRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<any> {
        const taskId = requestParameters.taskId;
        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling readAttributesResult.');
        }
        const transactionId = requestParameters.transactionId;
        if (transactionId === null || transactionId === undefined) {
            throw new Error('Required parameter transactionId was null or undefined when calling readAttributesResult.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (transactionId !== undefined && transactionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>transactionId, 'transactionId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PersonResultSchema>(`${this.configuration.basePath}/v1/attributes/tasks/${encodeURIComponent(String(taskId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read the result of a previously sent verify request
     * Query for task using a taskId. This was returned when the original call was made. 
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readVerifyResult(requestParameters: ReadVerifyResultRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<PersonResultSchema>;
    public readVerifyResult(requestParameters: ReadVerifyResultRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<HttpResponse<PersonResultSchema>>;
    public readVerifyResult(requestParameters: ReadVerifyResultRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<HttpEvent<PersonResultSchema>>;
    public readVerifyResult(requestParameters: ReadVerifyResultRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<any> {
        const taskId = requestParameters.taskId;
        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling readVerifyResult.');
        }
        const transactionId = requestParameters.transactionId;
        if (transactionId === null || transactionId === undefined) {
            throw new Error('Required parameter transactionId was null or undefined when calling readVerifyResult.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (transactionId !== undefined && transactionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>transactionId, 'transactionId');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PersonResultSchema>(`${this.configuration.basePath}/v1/verify/tasks/${encodeURIComponent(String(taskId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verify a set of attributes of a person.
     * Verify an Identity based on an identity identifier (UIN, token…) and a set of Identity Attributes. Verification is strictly matching all provided identity attributes to compute the global Boolean matching result.
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyIdentity(requestParameters: VerifyIdentityRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<PersonResultSchema>;
    public verifyIdentity(requestParameters: VerifyIdentityRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<HttpResponse<PersonResultSchema>>;
    public verifyIdentity(requestParameters: VerifyIdentityRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<HttpEvent<PersonResultSchema>>;
    public verifyIdentity(requestParameters: VerifyIdentityRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*' | 'application/json'}): Observable<any> {
        const identifier = requestParameters.identifier;
        if (identifier === null || identifier === undefined) {
            throw new Error('Required parameter identifier was null or undefined when calling verifyIdentity.');
        }
        const identifierType = requestParameters.identifierType;
        if (identifierType === null || identifierType === undefined) {
            throw new Error('Required parameter identifierType was null or undefined when calling verifyIdentity.');
        }
        const transactionId = requestParameters.transactionId;
        if (transactionId === null || transactionId === undefined) {
            throw new Error('Required parameter transactionId was null or undefined when calling verifyIdentity.');
        }
        const xREQUESTREASON = requestParameters.xREQUESTREASON;
        if (xREQUESTREASON === null || xREQUESTREASON === undefined) {
            throw new Error('Required parameter xREQUESTREASON was null or undefined when calling verifyIdentity.');
        }
        const verifyIDRequest = requestParameters.verifyIDRequest;
        if (verifyIDRequest === null || verifyIDRequest === undefined) {
            throw new Error('Required parameter verifyIDRequest was null or undefined when calling verifyIdentity.');
        }
        const verificationProofRequired = requestParameters.verificationProofRequired;
        const callback = requestParameters.callback;
        const xSOURCESYSTEM = requestParameters.xSOURCESYSTEM;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (identifierType !== undefined && identifierType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifierType, 'identifierType');
        }
        if (transactionId !== undefined && transactionId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>transactionId, 'transactionId');
        }
        if (verificationProofRequired !== undefined && verificationProofRequired !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>verificationProofRequired, 'verificationProofRequired');
        }
        if (callback !== undefined && callback !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>callback, 'callback');
        }

        let headers = this.defaultHeaders;
        if (xREQUESTREASON !== undefined && xREQUESTREASON !== null) {
            headers = headers.set('X-REQUEST-REASON', String(xREQUESTREASON));
        }
        if (xSOURCESYSTEM !== undefined && xSOURCESYSTEM !== null) {
            headers = headers.set('X-SOURCE-SYSTEM', String(xSOURCESYSTEM));
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<PersonResultSchema>(`${this.configuration.basePath}/v1/verify/${encodeURIComponent(String(identifier))}`,
            verifyIDRequest,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
