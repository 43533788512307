import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";

@Component({
  selector: 'app-intranet-side-header',
  templateUrl: './intranet-side-header.component.html',
  styleUrls: ['./intranet-side-header.component.css']
})
export class IntranetSideHeaderComponent implements OnInit {

  user: any;
  routeData: any;

  constructor(private router: Router,
              route: ActivatedRoute,
              private authenticationService: AuthenticationService) {
    router.events.forEach(e => {
      if (e instanceof NavigationEnd) {
        this.routeData = route?.root?.firstChild?.firstChild?.snapshot?.data || route?.root?.firstChild?.snapshot?.data;
      }
    })
  }

  ngOnInit(): void {

  }

  async logout() {
    // await this.keycloak.getKeycloakInstance().logout({})
  }

  canManageAccess() {
    return this.authenticationService.hasPermission('CREATE_USER');
  }

  canViewService () {
    return this.authenticationService.hasPermission('VIEW_SERVICES');
  }

  canManageClone () {
    return this.authenticationService.hasRole("CLONE_MANAGER");
  }

  // canViewAudit() {
  //   return this.authenticationService.hasRole('ADMIN');
  // }

  canViewUser() {
    return this.authenticationService.hasPermission("VIEW_USER")
  }

  isAdmin() {
    return this.authenticationService.hasRole('ADMIN');
  }
}
