/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccountMembership } from './portal-account-membership';


export interface PortalUser { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: PortalUser.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    firstName?: string;
    lastName?: string;
    otherNames?: string;
    email?: string;
    phoneNumber?: string;
    userId?: string;
    generatedPassword?: string;
    username?: string;
    dateOfBirth?: string;
    displayName?: string;
    gender?: PortalUser.GenderEnum;
    setupComplete?: boolean;
    emailVerified?: boolean;
    preferredName?: string;
    nationalIdentificationNumber?: string;
    lastLoginTime?: string;
    authenticationMethod?: PortalUser.AuthenticationMethodEnum;
    portalAccountMemberships?: Array<PortalAccountMembership>;
}
export namespace PortalUser {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'UNSPECIFIED' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Unspecified: 'UNSPECIFIED' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
    export type AuthenticationMethodEnum = 'PASSWORD' | 'FINGERPRINT';
    export const AuthenticationMethodEnum = {
        Password: 'PASSWORD' as AuthenticationMethodEnum,
        Fingerprint: 'FINGERPRINT' as AuthenticationMethodEnum
    };
}


