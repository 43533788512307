<div class="table-responsive" style= "max-height: 375px; overflow-y: scroll" >
    <table class="table table-hover" >
      <thead>
        <tr>
          <th scope="col">Attribute</th>
          <th scope="col">Type</th>
          <th scope="col">Description</th>
          <th scope="col">Required</th>
        </tr>
      </thead>
      <tbody >
        <tr>
          <td>biometricType</td>
          <td>string</td>
          <td>FINGER</td>
          <td>Yes</td>
        </tr>

        <tr>
          <td>biometricSubType</td>
          <td>string</td>
          <td>RIGHT_THUMB, RIGHT_INDEX, RIGHT_MIDDLE, RIGHT_RING, RIGHT_LITTLE, LEFT_THUMB, LEFT_INDEX, LEFT_MIDDLE, LEFT_RING, LEFT_LITTLE, PLAIN_RIGHT_FOUR_FINGERS, PLAIN_LEFT_FOUR_FINGERS, PLAIN_THUMBS, UNKNOWN_PALM, RIGHT_FULL_PALM, RIGHT_WRITERS_PALM, LEFT_FULL_PALM, LEFT_WRITERS_PALM, RIGHT_LOWER_PALM, RIGHT_UPPER_PALM, LEFT_LOWER_PALM, LEFT_UPPER_PALM,RIGHT_OTHER, LEFT_OTHER, RIGHT_INTERDIGITAL, RIGHT_THENAR, LEFT_INTERDIGITAL, LEFT_THENAR, LEFT_HYPOTHENAR, RIGHT_INDEX_AND_MIDDLE, RIGHT_MIDDLE_AND_RING, RIGHT_RING_AND_LITTLE, LEFT_INDEX_AND_MIDDLE, LEFT_MIDDLE_AND_RING, LEFT_RING_AND_LITTLE, RIGHT_INDEX_AND_LEFT_INDEX, RIGHT_INDEX_AND_MIDDLE_AND_RING, RIGHT_MIDDLE_AND_RING_AND_LITTLE, LEFT_INDEX_AND_MIDDLE_AND_RING</td>
          <td>Any of the description is required</td>
        </tr>

        <tr>
          <td>Image</td>
          <td>string</td>
          <td>WSQ BASE64-encoded image</td>
          <td>Yes</td>
        </tr>

        <tr>
            <td>Compression</td>
            <td>string</td>
            <td>WSQ</td>
            <td>Yes</td>
        </tr>

        <tr>
          <td>quality</td>
          <td>integer/int64</td>
          <td>Quality, as a number, of the biometric</td>
          <td>-</td>
      </tr>

      <tr>
        <td>qualityformat</td>
        <td>string</td>
        <td>ISO_19794, NFIQ, or NFIQ2</td>
        <td>-</td>
      </tr>
      </tbody>
    </table>
  </div>