/**
 * Jerry Service API
 * The Jerry RESTful service using springdoc and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FingerStartAcquisitionResponse { 
    statusMessage?: string;
    captureComplete?: boolean;
    captureStarted?: boolean;
    captureSuccessful?: boolean;
    sessionId?: string;
    enrollmentOngoing?: boolean;
    nextAction?: FingerStartAcquisitionResponse.NextActionEnum;
    finger?: FingerStartAcquisitionResponse.FingerEnum;
}
export namespace FingerStartAcquisitionResponse {
    export type NextActionEnum = 'PLACE_FINGER' | 'REMOVE_FINGER' | 'RESTART_CAPTURE' | 'START_NEW_CAPTURE';
    export const NextActionEnum = {
        PLACE_FINGER: 'PLACE_FINGER' as NextActionEnum,
        REMOVE_FINGER: 'REMOVE_FINGER' as NextActionEnum,
        RESTART_CAPTURE: 'RESTART_CAPTURE' as NextActionEnum,
        START_NEW_CAPTURE: 'START_NEW_CAPTURE' as NextActionEnum
    };
    export type FingerEnum = 'RIGHT_THUMB' | 'RIGHT_INDEX' | 'RIGHT_MIDDLE' | 'RIGHT_RING' | 'RIGHT_LITTLE' | 'LEFT_THUMB' | 'LEFT_INDEX' | 'LEFT_MIDDLE' | 'LEFT_RING' | 'LEFT_LITTLE' | 'LEFT_HAND' | 'RIGHT_HAND' | 'THUMBS';
    export const FingerEnum = {
        RIGHT_THUMB: 'RIGHT_THUMB' as FingerEnum,
        RIGHT_INDEX: 'RIGHT_INDEX' as FingerEnum,
        RIGHT_MIDDLE: 'RIGHT_MIDDLE' as FingerEnum,
        RIGHT_RING: 'RIGHT_RING' as FingerEnum,
        RIGHT_LITTLE: 'RIGHT_LITTLE' as FingerEnum,
        LEFT_THUMB: 'LEFT_THUMB' as FingerEnum,
        LEFT_INDEX: 'LEFT_INDEX' as FingerEnum,
        LEFT_MIDDLE: 'LEFT_MIDDLE' as FingerEnum,
        LEFT_RING: 'LEFT_RING' as FingerEnum,
        LEFT_LITTLE: 'LEFT_LITTLE' as FingerEnum,
        LEFT_HAND: 'LEFT_HAND' as FingerEnum,
        RIGHT_HAND: 'RIGHT_HAND' as FingerEnum,
        THUMBS: 'THUMBS' as FingerEnum
    };
}


