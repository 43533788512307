import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthenticationService} from "../authentication/authentication-service.service";
import {UserSessionManager} from "../authentication/user-session-manager";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ActivatedCloneGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authenticationService.getCloneState()
      .pipe(map(value => {
        return !!value;
      }), catchError(err => {
        this.router.navigateByUrl('/license-upload')
        return of(false);
      }));
  }

}
