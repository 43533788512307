// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: '/api',
  reportBaseUrl: '/report-api',
  brandName: 'HNODE',
  keycloakConfig: {
    clientId: 'hnode-frontend',
    realm: 'hnode',
    url: 'https://hnode.byteproducts.com/auth'
  },
  sessionTimeout: 1440,
  defaultCountry: 'NGA',
  apiDocConfig: {
    tokenEndpoint: "https://hnode.byteproducts.com/auth/realms/hclone/protocol/openid-connect/token",
    discoveryUrlSuffix: '/.well-known/openid-configuration',
  },
  dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
  pluginDownloadUrl: 'https://drive.google.com/uc?export=download&id=1OLRtloBl3XcmCaw6eAjzvqpvFbtl3Swa',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
