import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FingerprintScanService} from "./services/fingerprint-scan.service";
import {FingerprintScanConfigService} from "./services/fingerprint-scan-config.service";
import {Observable} from "rxjs";
import {AlertType, FINGERPRINT_AUTH_CONFIG} from "./variables";

@Component({
  selector: 'app-fingerprint-auth',
  templateUrl: './fingerprint-auth.component.html',
  styleUrls: ['./fingerprint-auth.component.css']
})
export class FingerprintAuthComponent implements OnInit {

  @Output()
  fingerprintEvent: EventEmitter<any> = new EventEmitter();

  @Output()
  loginEvent: EventEmitter<any> = new EventEmitter();

  @Output()
  retryEvent: EventEmitter<any> = new EventEmitter();

  @Output()
  bypassEvent: EventEmitter<any> = new EventEmitter();

  @Output()
  shuffleEvent: EventEmitter<any> = new EventEmitter();

  @Input()
  loginButtonDisabled$: Observable<boolean> = new Observable<boolean>();

  @Input()
  bypassButtonShown$: Observable<boolean> = new Observable<boolean>();

  _disableLoginButton = false;

  _showBypassButton = false;

  showLoginButton = false;

  showRetryButton = false;

  constructor(private fingerprintScanService: FingerprintScanService,
              public fingerprintScanConfigService: FingerprintScanConfigService,
              @Inject(FINGERPRINT_AUTH_CONFIG) public config: FINGERPRINT_AUTH_CONFIG) {
  }

  ngOnInit(): void {
    this.initListeners();
  }

  initListeners(): void {
    this.fingerprintScanService.actionAsObservable
      .subscribe(action => {

        if (action) {
          this.showLoginButton = action == 'LOGIN';
          this.showRetryButton = action == 'RETRY'
        }
      })

    this.loginButtonDisabled$?.subscribe(x => this._disableLoginButton = x)
    this.bypassButtonShown$?.subscribe(x => this._showBypassButton = x)

    this.fingerprintScanService.showAlertMessage.subscribe(alert => this.showAlertMessage(alert.msg, alert.type, alert.persist))
  }

  getFingerCaptureEvent($event: any) {
    this.fingerprintEvent.emit($event);
  }

  login(): void {
    this.loginEvent.emit();
  }

  retry(): void {
    this.retryEvent.emit();
  }

  shuffle(): void {

    // if (!this.showLoginButton) {
      this.shuffleEvent.emit()
    // }
  }

  bypass(): void {
    this.bypassEvent.emit();
  }


  private alertMessage: { msg: string; type: AlertType } = {
    msg: '',
    type: AlertType.primary
  }
  showAlertMessageTrigger = false;

  showAlertMessage(msg: any, type: AlertType, persist = false): void {
    this.alertMessage.msg = msg;
    this.alertMessage.type = type;
    this.showAlertMessageTrigger = true;
    window.scroll(0, 0);

    if (!persist) {
      setTimeout(() => {
        this.showAlertMessageTrigger = false;
      }, 10000);
    }
  }

  getAlertMessage(): string {
    return this.alertMessage.msg;
  }

  getMessageType(): AlertType {
    return this.alertMessage.type;
  }

  protected readonly AlertType = AlertType;
}

