/**
 * Jerry Auth Provider Service API
 * The Jerry Auth Provider RESTFUL service using springdoc and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: devops@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RandomFingerGenerationPojo { 
    fingerToScan?: number;
    numberOfPrintsToScan?: number;
    numberOfTrials?: number;
    numberOfTrialsLeft?: number;
    nfiQuality?: number;
}

