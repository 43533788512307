<div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Attribute</th>
          <th scope="col">Type</th>
          <th scope="col">Description</th>
          <th scope="col">Required</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>biometricType</td>
          <td>string</td>
          <td>FACE</td>
          <td>Yes</td>
        </tr>

        <tr>
          <td>biometricSubType</td>
          <td>String</td>
          <td>PORTRAIT</td>
          <td>Yes</td>
        </tr>

        <tr>
          <td>Image</td>
          <td>string</td>
          <td>BASE64-encoded image</td>
          <td>Yes</td>
        </tr>

        <tr>
            <td>Compression</td>
            <td>string</td>
            <td>PNG, JPEG</td>
            <td>Yes</td>
        </tr>
      </tbody>
    </table>
  </div>