<div class=" mb-4 d-flex align-items-center">
  {{title}}
  <div class="progress w-100 me-2">
    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
         [ngClass]="progressColor"
          [attr.aria-valuenow]="_progressPercentage" aria-valuemin="0"
         aria-valuemax="100" [style.width]="_progressPercentage + '%'"></div>
  </div>
  <div class="fs-14">{{_progressPercentage.toFixed(2)}}%</div>
</div>
