<div class="card customer-info-card border-0">
  <div class="card-body">
    <div class="text-center">
      <p class="text-center fw-bold fs-18">
        Consent Generation Preview
      </p>
    </div>

    <div class="card-body rounded-2 scan-background-card">
      <p>
        <span (click)="downloadConsentSlip()" style="color: #1551AA;" class="pointer">Download</span> the slip or <span
        (click)="shareConsentModal()" style="color: #1551AA;" class="pointer">share via Email</span>
        the consent code before leaving this page.
        Once you navigate away, this information will no longer be available.
      </p>
      <div class="d-flex gap-2 justify-content-end">
        <button [disabled]="isSharing" class="btn btn-sm btn-outline-secondary" (click)="shareConsentModal()">
          <i class="iconify icon" data-icon="material-symbols-light:share-outline"></i>
          Share
        </button>

        <button [disabled]="isDownloading" class="btn btn-sm btn-primary" (click)="downloadConsentSlip()">
          <i class="iconify icon" data-icon="vscode-icons:file-type-pdf2"></i>
          Download
        </button>
      </div>

        <div class="mt-3">
            <pdf-viewer
                    [style.width]="'100%'"
                    [style.height]="'800px'"
                    [src]="getUrl"
                    [render-text]="true"
                    [original-size]="false"
                    (page-rendered)="showLoader()"
                    [fit-to-page]="true"
                    [autoresize]="true"
            ></pdf-viewer>

        </div>

    </div>

  </div>
</div>
