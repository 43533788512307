import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, PatternValidator, Validators} from "@angular/forms";
import {HelperService} from "../../services/helper.service";
import {CustomValidators} from "../../../../shared/custom-validators";
import {FormHelper} from "../../../../models/etc/form-helper";
import {CountryISO, PhoneNumberFormat, SearchCountryField} from "ngx-intl-tel-input";
import {ConsentRegistrationDto} from "../../../../../../sdk/hclone-api-sdk";
import moment from "moment/moment";

@Component({
  selector: 'app-customer-information-form',
  templateUrl: './customer-information-form.component.html',
  styleUrls: ['./customer-information-form.component.css']
})
export class CustomerInformationFormComponent implements OnInit {

  form = this.fb.group({
    firstName: [
      '',
      Validators.compose([
        Validators.required,
        CustomValidators.notBlank,
        Validators.minLength(0),
        Validators.maxLength(40),
        Validators.pattern(FormHelper.NAME_REGEX),
        this.checkForConsecutiveLetters('firstName'),
        this.checkForConsecutiveDots('firstName'),
      ])
    ],
    surname: [
      '',
      Validators.compose([
        Validators.required,
        CustomValidators.notBlank,
        Validators.minLength(0),
        Validators.maxLength(40),
        Validators.pattern(FormHelper.NAME_REGEX),
        this.checkForConsecutiveLetters('surname'),
        this.checkForConsecutiveDots('surname'),
      ])
    ],
    nin: [
      '',
      Validators.compose([
        Validators.required,
        Validators.required,
        Validators.minLength(11),
        Validators.maxLength(11),
        Validators.pattern(/^[0-9]*$/),
        this.cannotStartWithZero('nin'),
      ])
    ],
    dateOfBirth: [
      '',
      Validators.compose([
        Validators.required,
      ])
    ],
    phoneNumber: [
      '',
      Validators.compose([])
    ],
    emailAddress: [
      '',
      Validators.compose([
        Validators.email
      ])
    ],
  })

  loading = false;

  //phone
  selectedCountryISO: any = CountryISO['Nigeria'];
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  PhoneNumberFormat = PhoneNumberFormat;

  today: Date = new Date();
  minAge: Date = new Date();
  maxAge: Date = new Date();

  @Input()
  preloadedData: ConsentRegistrationDto;

  @Output()
  customerForm$: EventEmitter<ConsentRegistrationDto> = new EventEmitter<ConsentRegistrationDto>();

  constructor(private fb: FormBuilder,
              protected helperService: HelperService) {
  }

  ngOnInit(): void {
    this.setAgeRange();

    this.updateFormData();
  }

  updateFormData(): void {
    if (this.preloadedData != null) {
      this.form.patchValue({
        firstName: this.preloadedData?.firstName,
        surname: this.preloadedData?.lastName,
        nin: this.preloadedData?.nin,
        phoneNumber: this.preloadedData?.phoneNumber,
        emailAddress: this.preloadedData?.email,
        dateOfBirth: this.preloadedData?.dateOfBirth
      });
    }
  }

  setAgeRange(): void {
    this.minAge.setFullYear(this.today.getFullYear())
    this.maxAge.setFullYear(this.today.getFullYear() - 120)
  }

  registerCustomerConsent(): void {
    if (this.form.invalid) {
      this.helperService.getInvalidFormFields(this.form);
      return;
    }

    const form = this.form.getRawValue();

    //getDto
    //call api
  }

  proceed(): void {

    if (this.form.invalid) {
      this.helperService.getInvalidFormFields(this.form);
      return;
    }

    const form = this.form.getRawValue();
    console.log('number ', form.phoneNumber)

    const customerForm: ConsentRegistrationDto = {
      verificationTransactionRef: "VER001",
      customerAccountCode: "NM001",
      dateOfBirth: moment(form.dateOfBirth).format('YYYY-MM-DD'),
      email: form.emailAddress,
      firstName: form.firstName,
      lastName: form.surname,
      nin: form.nin,
      phoneNumber: (form.phoneNumber as any)?.e164Number
    }

    this.customerForm$.emit(customerForm);

    this.loading = true;

    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }

  protected checkForConsecutiveLetters(controlName: string) {
    const self = this;
    return (control: AbstractControl) => {
      const value = this.form?.get(controlName)?.value;
      if (!value) {
        return null;
      }
      // check for consecutive letters
      if (value.match(/([a-zA-Z])\1{3,}/)) {
        return {consecutiveLetters: true};
      }
      return null;
    }
  }


  protected checkForConsecutiveDots(controlName: string) {
    const self = this;
    return (control: AbstractControl) => {
      const value = this.form?.get(controlName)?.value;
      if (!value) {
        return null;
      }
      // check for consecutive dots
      if (value.match(/(\.)\1{4,}/)) {
        return {consecutiveDots: true};
      }
      return null;
    };
  }

  protected cannotStartWithZero(controlName: string) {
    const self = this;
    return (control: AbstractControl) => {
      if (!self.form) {
        return null;
      }
      const value = this.form?.get(controlName)?.value;
      if (!value) {
        return null;
      }
      return value.startsWith('0') ? {cannotStartWithZero: true} : null;
    };
  }

}
