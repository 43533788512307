/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OutputCredentialData } from './output-credential-data';
import { OutputBiometricData } from './output-biometric-data';


export interface ReadAttributeRequest { 
    /**
     * The names of the attributes to return. At least one must be provided
     */
    outputBiographicData?: Array<ReadAttributeRequest.OutputBiographicDataEnum>;
    outputBiometricData?: Array<OutputBiometricData>;
    outputCredentialData?: Array<OutputCredentialData>;
    /**
     * The names of the attributes to return.
     */
    outputContactData?: Array<string>;
}
export namespace ReadAttributeRequest {
    export type OutputBiographicDataEnum = 'title' | 'firstName' | 'lastName' | 'middleName' | 'otherName' | 'maidenName' | 'previousFirstName' | 'previousLastName' | 'previousMiddleName' | 'dateOfBirth' | 'gender' | 'birthCountry' | 'birthState' | 'birthLga' | 'maritalStatus' | 'height' | 'residenceStatus' | 'religion' | 'email' | 'phone1' | 'nativeSpokenLanguage' | 'otherSpokenLanguage' | 'readWriteLanguage' | 'educationLevel' | 'profession' | 'employmentStatus' | 'nationality' | 'originPlace' | 'originLga' | 'originState' | 'originCountry' | 'residenceAddressLine1' | 'residenceAddressLine2' | 'residenceTown' | 'residenceLga' | 'residenceState' | 'residenceCountry' | 'nextOfKinSurname' | 'nextOfKinFirstName' | 'nextOfKinMiddleName' | 'nextOfKinAddressLine1' | 'nextOfKinAddressLine2' | 'nextOfKinPostCode' | 'nextOfKinTown' | 'nextOfKinLga' | 'nextOfKinRelation' | 'nextOfKinCountry';
    export const OutputBiographicDataEnum = {
        Title: 'title' as OutputBiographicDataEnum,
        FirstName: 'firstName' as OutputBiographicDataEnum,
        LastName: 'lastName' as OutputBiographicDataEnum,
        MiddleName: 'middleName' as OutputBiographicDataEnum,
        OtherName: 'otherName' as OutputBiographicDataEnum,
        MaidenName: 'maidenName' as OutputBiographicDataEnum,
        PreviousFirstName: 'previousFirstName' as OutputBiographicDataEnum,
        PreviousLastName: 'previousLastName' as OutputBiographicDataEnum,
        PreviousMiddleName: 'previousMiddleName' as OutputBiographicDataEnum,
        DateOfBirth: 'dateOfBirth' as OutputBiographicDataEnum,
        Gender: 'gender' as OutputBiographicDataEnum,
        BirthCountry: 'birthCountry' as OutputBiographicDataEnum,
        BirthState: 'birthState' as OutputBiographicDataEnum,
        BirthLga: 'birthLga' as OutputBiographicDataEnum,
        MaritalStatus: 'maritalStatus' as OutputBiographicDataEnum,
        Height: 'height' as OutputBiographicDataEnum,
        ResidenceStatus: 'residenceStatus' as OutputBiographicDataEnum,
        Religion: 'religion' as OutputBiographicDataEnum,
        Email: 'email' as OutputBiographicDataEnum,
        Phone1: 'phone1' as OutputBiographicDataEnum,
        NativeSpokenLanguage: 'nativeSpokenLanguage' as OutputBiographicDataEnum,
        OtherSpokenLanguage: 'otherSpokenLanguage' as OutputBiographicDataEnum,
        ReadWriteLanguage: 'readWriteLanguage' as OutputBiographicDataEnum,
        EducationLevel: 'educationLevel' as OutputBiographicDataEnum,
        Profession: 'profession' as OutputBiographicDataEnum,
        EmploymentStatus: 'employmentStatus' as OutputBiographicDataEnum,
        Nationality: 'nationality' as OutputBiographicDataEnum,
        OriginPlace: 'originPlace' as OutputBiographicDataEnum,
        OriginLga: 'originLga' as OutputBiographicDataEnum,
        OriginState: 'originState' as OutputBiographicDataEnum,
        OriginCountry: 'originCountry' as OutputBiographicDataEnum,
        ResidenceAddressLine1: 'residenceAddressLine1' as OutputBiographicDataEnum,
        ResidenceAddressLine2: 'residenceAddressLine2' as OutputBiographicDataEnum,
        ResidenceTown: 'residenceTown' as OutputBiographicDataEnum,
        ResidenceLga: 'residenceLga' as OutputBiographicDataEnum,
        ResidenceState: 'residenceState' as OutputBiographicDataEnum,
        ResidenceCountry: 'residenceCountry' as OutputBiographicDataEnum,
        NextOfKinSurname: 'nextOfKinSurname' as OutputBiographicDataEnum,
        NextOfKinFirstName: 'nextOfKinFirstName' as OutputBiographicDataEnum,
        NextOfKinMiddleName: 'nextOfKinMiddleName' as OutputBiographicDataEnum,
        NextOfKinAddressLine1: 'nextOfKinAddressLine1' as OutputBiographicDataEnum,
        NextOfKinAddressLine2: 'nextOfKinAddressLine2' as OutputBiographicDataEnum,
        NextOfKinPostCode: 'nextOfKinPostCode' as OutputBiographicDataEnum,
        NextOfKinTown: 'nextOfKinTown' as OutputBiographicDataEnum,
        NextOfKinLga: 'nextOfKinLga' as OutputBiographicDataEnum,
        NextOfKinRelation: 'nextOfKinRelation' as OutputBiographicDataEnum,
        NextOfKinCountry: 'nextOfKinCountry' as OutputBiographicDataEnum
    };
}


