/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MembershipPojo } from './membership-pojo';


export interface PortalUserSearchResponsePojo { 
    email?: string;
    phoneNumber?: string;
    displayName?: string;
    status?: PortalUserSearchResponsePojo.StatusEnum;
    id?: number;
    memberships?: Array<MembershipPojo>;
    createdAt?: string;
    nin?: string;
    userId?: string;
}
export namespace PortalUserSearchResponsePojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum
    };
}


