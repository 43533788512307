import {Component, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {ConsentRegistrationControllerService, ConsentRegistrationDto} from "../../../../../sdk/hclone-api-sdk";
import {HelperService} from "../services/helper.service";
import {AlertType} from './fingerprint-auth/variables';

@Component({
  selector: 'app-consent-code-generation',
  templateUrl: './consent-code-generation.component.html',
  styleUrls: ['./consent-code-generation.component.scss'],
})
export class ConsentCodeGenerationComponent implements OnInit {

  tabHeaders: string[] = ['Customer Information', 'Biometric Verification', 'Preview']

  tabIcons: string[] = [
    'material-symbols-light:id-card-outline',
    'healthicons:fingerprint-outline',
    'ooui:text-summary-rtl'
  ];

  stepCount = 1;

  customerForm: ConsentRegistrationDto;

  biometricData: any;

  consentCode: string;

  constructor(private fb: FormBuilder,
              private consentService: ConsentRegistrationControllerService,
              protected helperService: HelperService) {
  }

  ngOnInit(): void {
  }

  generateConsent(): void {
    const payload = {...this.customerForm}
    this.consentService.generateConsentRegistrationCode({consentRegistrationDto: payload})
      .subscribe({
        next: (response) => {
          this.consentCode = response?.consentRegistrationCode;
          this.proceed(this.stepCount + 1);
        },
        error: (error) => {
          this.showAlertMessage(this.helperService.getError(error), AlertType.error);
        }
      })
  }

  goToStep(number: number) {
    this.stepCount = number;
  }

  proceed(step: number) {
    this.stepCount = step;
  }

  setCustomerInformation($event: any) {
    this.customerForm = $event;
    this.proceed(this.stepCount + 1)
  }

  setBiometricData($event: any) {
    this.biometricData = $event;
  }

  loginEvent(): void {
    this.generateConsent();
  }

  retry(): void {
    this.goToStep(1)
  }

  /////////////////////
  //////ALERT
  /////////////////////
  private alertMessage: { msg: string; type: AlertType } = {
    msg: '',
    type: AlertType.primary
  }
  showAlertMessageTrigger = false;

  showAlertMessage(msg: any, type: AlertType): void {
    this.alertMessage.msg = msg;
    this.alertMessage.type = type;
    this.showAlertMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showAlertMessageTrigger = false;
    }, 10000);
  }

  getAlertMessage(): string {
    return this.alertMessage.msg;
  }

  getMessageType(): AlertType {
    return this.alertMessage.type;
  }

  protected readonly AlertType = AlertType;

  /////////////////////
  //////END ALERT
  /////////////////////
}
