import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConsentRegistrationControllerService} from "../../../../../../sdk/hclone-api-sdk";
import {environment} from "../../../../../environments/environment";
import {BsModalService} from "ngx-bootstrap/modal";
import {ShareConsentDialogComponent} from "../share-consent-dialog/share-consent-dialog.component";
import {AlertType} from "../fingerprint-auth/variables";
import {HelperService} from "../../services/helper.service";

@Component({
    selector: 'app-consent-generation-preview',
    templateUrl: './consent-generation-preview.component.html',
    styleUrls: ['./consent-generation-preview.component.css']
})
export class ConsentGenerationPreviewComponent implements OnInit {

    isSharing = false;

    isDownloading = false;

    @Input()
    consentCode: string;

    @Input()
    customerName: String

    @Output()
    showAlertMessage: EventEmitter<{ msg: string; type: AlertType }> = new EventEmitter<any>();

    constructor(private consentService: ConsentRegistrationControllerService,
                private bsModalService: BsModalService,
                protected helperService: HelperService) {
    }

    ngOnInit(): void {
    }

    shareConsentModal() {
        const ref = this.bsModalService.show(ShareConsentDialogComponent, {
            initialState: {
                consentCode: this.consentCode
            },
            keyboard: false,
            backdrop: 'static',
            class: 'modal-dialog-centered modal-md'
        })

        ref.content.success.subscribe(consent => {
            ref.hide();
            this.showAlertMessage.emit({msg: 'Your CRC has been shared via email', type: AlertType.success});
        })
    }

    downloadConsentSlip(): void {
        this.isDownloading = true;

        this.consentService.downloadConsentSlip({consentCode: this.consentCode}).subscribe({
            next: (data) => {
                const blobUrl = window.URL.createObjectURL(data);
                const a = document.createElement('a');
                a.href = blobUrl;
                a.target = '_blank';
                a.download = `consent_${this.customerName || ''}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(a);
                this.isDownloading = false;
            },
            error: (error: unknown) => {
                this.isDownloading = false;
                console.log('err download ', error);
                this.showAlertMessage.emit({msg: this.helperService.getError(error), type: AlertType.error});
            }
        })
    }

    get getUrl(): string {
        return `${environment.apiBaseUrl}/consent-registrations/download-consent-slip?consentCode=${this.consentCode}`;
    }

    showLoader(): void {
        // this.hideLoader = true;
    }
}
