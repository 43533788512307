<!-- <app-site-header *ngIf="routeData?.isExtranet??true"></app-site-header> -->
 <div class="fill">
   <app-site-header [cloneState]="cloneState" [user]="user"></app-site-header>
   <app-intranet-side-header *ngIf="!routeData?.['isExtranet']"></app-intranet-side-header>
   <div [ngClass]="{'pb-5 mb-5': !routeData?.['isExtranet']}">
     <router-outlet></router-outlet>
   </div>
 </div>
<app-site-footer *ngIf="!routeData?.['hideFooter']"></app-site-footer>



