/**
 * HClone API
 * The HClone RESTful service
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: developer@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccountMemberRole } from './portal-account-member-role';
import { PortalUser } from './portal-user';


export interface PortalAccountTypeRole { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    status?: PortalAccountTypeRole.StatusEnum;
    dateDeactivated?: string;
    deactivatedBy?: PortalUser;
    name?: string;
    displayName?: string;
    accountType?: PortalAccountTypeRole.AccountTypeEnum;
    portalAccountMemberRoles?: Array<PortalAccountMemberRole>;
}
export namespace PortalAccountTypeRole {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum
    };
    export type AccountTypeEnum = 'HNODE' | 'SYSTEM_SUPPORT';
    export const AccountTypeEnum = {
        Hnode: 'HNODE' as AccountTypeEnum,
        SystemSupport: 'SYSTEM_SUPPORT' as AccountTypeEnum
    };
}


